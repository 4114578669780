import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import pdfMake from "pdfmake/build/pdfmake";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PDFTextField } from 'pdf-lib';
import * as XLSX from 'xlsx';

// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
  FontAwesome: {
    normal: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf"
  }
};

const allowedCharacters = [
  {'base':'A', 'letters':'\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'},
  {'base':'AA','letters':'\uA732'},
  {'base':'AE','letters':'\u00C6\u01FC\u01E2'},
  {'base':'AO','letters':'\uA734'},
  {'base':'AU','letters':'\uA736'},
  {'base':'AV','letters':'\uA738\uA73A'},
  {'base':'AY','letters':'\uA73C'},
  {'base':'B', 'letters':'\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'},
  // ... (rest of the characters)
  {'base':'y','letters':'\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'},
  {'base':'z','letters':'\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'}
];

export function getCompanyLogo(driverCar): string {

  switch (driverCar) {
    case 'Standard':
      return '../assets/images/vehicles/standardcab.png';

    case 'Premium':
      return '../assets/images/vehicles/premiumcab.png';

    case 'SUV':
      return '../assets/images/vehicles/suv.png';
    case 'WAV':
      return '../assets/images/vehicles/wav.png';
    case 'Stretcher':
      return '../assets/images/vehicles/stretcher.png';
    default:
      return '../assets/images/vehicles/fleet-img.png';
  }
}

export function getBrokerImage(brokerName): string {
  switch (brokerName) {
    case 'united':
      return '../assets/images/brokers/SouthETrans.png';
    case 'lcp':
      return '../assets/images/brokers/lcp-logo.png';
    case 'first':
      return '../assets/images/brokers/mtm-logo.jpg';
    case 'logisticscare':
      return '../assets/images/brokers/logisticare.jpg';
    case 'medtrans':
      return '../assets/images/brokers/medtrans-logo.png';
    case 'medicaid':
      return '../assets/images/brokers/medicaid.png';
    case 'amera':
      return '../assets/images/brokers/amera.png';
    case 'veyo':
      return '../assets/images/brokers/veyo-logo.png';
    case 'vapremier':
      return '../assets/images/brokers/vapremier.png';
    case 'mas':
      return '../assets/images/brokers/mas.png';
    case 'epic':
      return '../assets/images/brokers/epic.png';
    case 'onecall':
      return '../assets/images/brokers/onecall.png';
    case 'americanLogistics':
      return '../assets/img/americanLogistics.png';
    case 'access2Ride':
      return '../assets/img/access2Ride.png';
    case 'welltrans':
      return '../assets/img/welltrans.png';
    case 'modivcare':
      return '../assets/img/modivcare.png.png';
    case 'callthecar':
      return '../assets/img/callthecar.png';
    case 'tncarrier':
      return '../assets/img/tncarrier.png';
    case 'saferide':
      return '../assets/img/saferide.png';
    case 'abcinsurance':
      return '../assets/img/abcinsurance.png';
    case 'ride2md':
      return '../assets/img/ride2md.png';

    case 'iehp':
      return '../assets/img/iehp.png';

    case 'caloptima':
      return '../assets/img/caloptima.png';

      case 'valleymetro':
      return '../assets/img/valley-metro.png';

    default:
      return '../assets/images/brokers/MixandMatch.png';
  }
}

export const getBrokersList = {
  'default': 'Default',
  'amera': 'Amera',
  'abcinsurance': 'Abc Insurance',
  'americanLogistics': 'American Logistics',
  'arn': 'Angel Ride Network',
  'access2Ride': 'Access2Care',
  'callthecar': 'Call The Car',
  'caloptima': 'CalOptima',
  'epic': 'EPIC',
  'iehp': 'IEHP',
  'lcp': 'LCP',
  'logisticscare': 'Logisticare',
  'medex': 'Medex',
  'first': 'MTM',
  'mas': 'MAS',
  'medicaid': 'Medicaid',
  'modivcare': 'Modivcare',
  'medtrans': 'NMN',
  'onecall': 'One Call',
  'ride2md': 'Ride2MD',
  'united': 'Southeastrans',
  'saferide': 'Safe Ride',
  'tncarrier': 'Tennessee Carriers',
  'welltrans': 'Welltrans',
  'vapremier': 'Va Premier',
  'veyo': 'Veyo',
  'providearide': 'Provide A Ride',
  'wellmed': 'WellMed',
  'factSd': 'FACT SD',
  'valleymetro': 'Valley Metro',
  'carecar':'CareCar'
};


export const PAYOR = {
  other: "Other",
  medicaid: "Medicaid",
  medicare: "Medicare",
  medical: "MediCal",
  optum: "Optum",
  privatePay: "Private Pay",
  billFacility: "Bill Facility",
  tricare: "Tricare",
  champva: "CHAMPVA",
  fecaBlackLung: "FECA Black Lung",
};


export const requiredOdometers = [
  { id: 0, text: 'Onscene/Completed' },
  { id: 5, text: 'Onway/Onscene/Completed/Cancelled' },
  { id: 4, text: 'Onscene/Completed/Cancelled' },
  { id: 3, text: 'Onway/Onscene/Completed' }
];

export const getBillingBrokers = [
  {
    name: 'callthecar',
    title: 'CallTheCar',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/callthecar.png'
  },
  {
    name: 'ride2md',
    title: 'Ride2MD',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/ride2md.png'
  },
  {
    name: 'tncarrier',
    title: 'Tennessee Carriers',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/tncarrier.png'
  },
  {
    name: 'saferide',
    title: 'SafeRide',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/saferide.png'
  },
  {
    name: 'logisticscare',
    title: 'Logisticare',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/logo-2.jpg'
  },
  {
    name: 'welltrans',
    title: 'Welltrans',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/welltrans.png'
  },
  {
    name: 'medtrans',
    title: 'NMN',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/medtrans-logo.png'
  },
  {
    name: 'united',
    title: 'SouthEastrans',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/SouthETrans.png'
  },
  {
    name: 'veyo',
    title: 'Veyo',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/veyo-logo.png'
  },
  {
    name: 'first',
    title: 'MTM',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/mtm-logo.jpg'
  },
  {
    name: 'epic',
    title: 'EPIC',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/epic.png'
  },
  {
    name: 'lcp',
    title: 'LCP',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/lcp-logo.png'
  },
  {
    name: 'mas',
    title: 'MAS',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/mas.png'
  },
  {
    name: 'medicaid',
    title: 'Medicaid',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/medicaid.png'
  },
  {
    name: 'amera',
    title: 'Amera',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/amerasol.png'
  },
  {
    name: 'vapremier',
    title: 'Va Premier',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/vapremier.png'
  },
  {
    name: 'onecall',
    title: 'OneCall',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/onecall.png'
  },
  {
    name: 'americanLogistics',
    title: 'American Logistics',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/americanLogistics.png'
  },
  {
    name: 'access2Ride',
    title: 'Access2Care',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/access2Ride.png'
  },
  {
    name: 'modivcare',
    title: 'Modivcare',
    logoUrl: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/modivcare.png'
  },
  {
    name: 'iehp',
    title: 'IEHP',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/iehp.png'
  },
  {
    name: 'caloptima',
    title: 'CalOptima',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/caloptima.png'
  },
  {
    name: 'providearide',
    title: 'Provide A Ride',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/providearide.png'
  },
  {
    name: 'wellmed',
    title: 'Well Med',
    logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/wellmed.png'
  },
  {
    name: 'factSd',
    title: 'FACT SD',
    logoUrl: 'assets/img/factSd.png'
  },
  {
    name: 'valleymetro',
    title: 'Valley Metro',
    logoUrl: 'assets/img/valley-metro.png'
  },
  {
    name: 'carecar',
    title: 'CareCar',
    logoUrl: 'assets/img/carecar.png'
  }
];

export const getstandingOrderDays = [
  { label: "Mon", day: 1, checked: false, driver: null },
  { label: "Tue", day: 2, checked: false, driver: null },
  { label: "Wed", day: 3, checked: false, driver: null },
  { label: "Thu", day: 4, checked: false, driver: null },
  { label: "Fri", day: 5, checked: false, driver: null },
  { label: "Sat", day: 6, checked: false, driver: null },
  { label: "Sun", day: 0, checked: false, driver: null }
]

export const getstandingOrderDaysAsObject = () => {
  const object = _.object(_.pluck(getstandingOrderDays, 'day'), _.pluck(getstandingOrderDays, 'label'))
  return object;
}




export function loadStaticMap(lat, lng) {
  const mapKey = 'AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo';
  // let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
  //   + lat + ',' + lng + '&zoom=14&size=130x150&maptype=satellite&markers=color:red|' + lat
  //   + ',' + lng + '&key=' + mapKey;

  let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
    + lat + ',' + lng + '&zoom=18&size=500x500&maptype=satellite&markers=color:red|' + lat
    + ',' + lng + '&key=' + mapKey;

  return url;
}

export function filterJobs(list, currentTime) {
  list.forEach((trip) => {
    var time = timeCalculation(trip, currentTime);
    if (trip.status === 'inprogress') {
      trip.filter = 'active';
    } else if ((time < 30) || trip.status === 'declined' || trip.status === 'bumped' || trip.status === 'cancelled') {
      trip.filter = 'alarming';
    } else if (time <= 120 && time >= 30) {
      trip.filter = 'upcoming';
    } else if (time > 120) {
      trip.filter = 'schedule';
    }
    trip.scheduleDate = moment(trip.scheduleTime).format('MM/DD/YYYY');
  });
  // return groupListByDate(list);
  return list;
}

export function timeCalculation(trip, currentTime) {

  const end = moment(trip.scheduleTime);
  const startTime = moment(currentTime);
  const duration = moment.duration(end.diff(startTime));
  return duration.asMinutes();
}

export function groupListByDate(list) {
  return _.groupBy(list, (list) => {
    return list.scheduleDate
  });
}

// export  function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
//     var emailRegex = /^[a-zA-Z0-9]+.{0,1}[a-zA-Z0-9]+@[a-z]{3,}\.[a-z]{2,}$/;
//     const email: string = control.value;
//     if (email === null || email === "" || emailRegex.test(email) === true) {
//       return null;
//     }
//     else if (emailRegex.test(email) === false) {
//       return { "domain": true };
//     }
//   }

export const states = [
  { value: "AK", name: "Alaska" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export function getUserRole(role) {
  let userRole;
  if (Array.isArray(role)) {
    userRole = role && role.length ? role[0] : '';
  } else {
    userRole = role;
  }

  return userRole;
}

export function mapStyles() {
  return [
    {
      featureType: "administrative",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#84afa3",
        },
        {
          lightness: 52,
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -17,
        },
        {
          gamma: 0.36,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#3f518c",
        },
      ],
    },
  ];
}


export function getDistanceFromLatLon(lat1, lon1, lat2, lon2) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1);  // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km
  d *= 0.621371; // Distance in miles
  return d;
}

export function getTimeAndDistance(lat1, lon1, lat2, lon2, speed) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = R * c; // Distance in km
  let timeInHours = distance / speed; // Time in hours
  let timeInMinutes = timeInHours * 60; // Time in minutes

  // Convert distance to miles
  distance *= 0.621371;

  return { distance, timeInHours, timeInMinutes: timeInMinutes <=5 ? 5 :  timeInMinutes + 5};
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export function exportToCsv(filename, rows) {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null || row[j] === undefined || row[j] === "" ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      };
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

  var link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
}

export function exportToExcel(filename, sheets) {
  // Create a new workbook
  var wb = XLSX.utils.book_new();

  // Iterate through the sheets object to add sheets to the workbook
  for (var sheetName in sheets) {
    if (sheets.hasOwnProperty(sheetName)) {
      var ws_data = sheets[sheetName];
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    }
  }

  // Write the workbook to a file
  var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  // Create a Blob from the binary data
  var buf = new ArrayBuffer(wbout.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < wbout.length; ++i) {
    view[i] = wbout.charCodeAt(i) & 0xFF;
  }
  var blob = new Blob([buf], { type: 'application/octet-stream' });

  // Create a link element and trigger a download
  var link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


}



export function exportToCsvWithColor(filename, rows) {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null || row[j] === undefined || row[j] === "" ? 'n/a' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';

      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  // Create a Blob with CSV content
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

  // Create a download link and trigger the download
  var link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}





export function generatePdf(documentDefinition, action = "download", filename = "black-honda") {
  switch (action) {
    case "open":
      pdfMake.createPdf(documentDefinition).open();
      break;
    case "print":
      pdfMake.createPdf(documentDefinition).print();
      break;
    case "download":
      pdfMake.createPdf(documentDefinition).download(`${filename}-DOT-Checklist.pdf`);
      break;

    default:
      pdfMake.createPdf(documentDefinition).open();
      break;
  }

}

export function convertBase64ToFile(base64String, fileName) {
  console.log({ convertBase64ToFile: base64String })
  var arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime })
}

export async function urlToBase64(url) {
  var res = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  var blob = await res.blob();

  const result = await new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  });

  return result;
}

export async function urlToArrayBuffer(url) {
  console.log(url);
  var res = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  var blob = await res.blob();
  const result = await new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsArrayBuffer(blob);
  });

  return result;
}

export async function removeNonAllowedCharactersFromPDF(form) {
  const fields = form.getFields();
  for (const fieldName in fields) {
    const field = fields[fieldName];
    if(!(field instanceof PDFTextField)) continue
    const textField = form.getTextField(field.getName())
    const fieldValue = textField.getText();
    if(!fieldValue) continue
      const stringValue = String(fieldValue);
      const replaced = stringValue.replace(/[^\x00-\x7F]/g, "");;
    textField.setText(replaced);
  }
}


export async function getImageAsEmbedNew(pdfDoc, url, imageBytes) {
  let image = null;
  console.log(url);
  if (url && url.length > 0) {
    try {
      // const nameArray = url.split('.');
      // const imageExtension = nameArray[nameArray.length - 1].toLowerCase();

      // if (imageExtension === "png") {
      //   image = await pdfDoc.embedPng(imageBytes);
      // } else

      // if (imageExtension === "jpg" || imageExtension === "jpeg") {
      image = await pdfDoc.embedJpg(imageBytes);
      // } else {
      // image = await pdfDoc.embedPng(imageBytes);
      // }
    } catch (error) {
      console.error(error);
      image = null;
      if (!image) {
        try {
          image = await pdfDoc.embedPng(imageBytes);
        } catch (error) {
          console.error(error);
          image = null;
        }
      }
    }
    return image;
  }
}


  export function getAddressDetails(locationData) {
    let address: any = {};

    locationData.address_components.forEach(component => {
      if (component.types.includes("route")) {
        address.street = component.long_name;
      } else if (component.types.includes("locality")) {
        address.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        address.state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        address.zip = component.long_name;
      }
    });

    return address;
  }

  export function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  export function isDecimal(number) {
    return Number.isInteger(number) ? false : true;
  }

  export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        if (control.value && control.value.trim() === '') {
          resolve({ 'whitespace': true });
        } else {
          resolve(null);
        }
      });
    };
  }

  export function getRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result
  }

  export function removeSpecificfromPDF(form, fieldsToRemove) {
    // const fieldsToRemove = ["field1", "field2", "field3"];
    for (const fieldName of fieldsToRemove) {
      const field = form.getFields().find((x) => x.getName() === fieldName);
      if (field) {
        form.removeField(field);
      }
    }
  }

export function cleanUpSpaces(value) {
  return value.toLowerCase().replace(/\s+/g, '');
};

export function getBorderColor(vehicle: any) {
  if (!vehicle.fulfillment_indicators) return;
  const predictorResult = vehicle.fulfillment_indicators.availability_predictor.predictor_result || 'UNKNOWN';

  // Map predictor results to colors
  const colorMap: { [key: string]: string } = {
    'GREEN': '3px solid green',
    'YELLOW': '3px solid yellow',
    'RED': '3px solid red',
    'UNKNOWN': '3px solid black'
  };

  // Apply border color to both the container and the image
  return { border: colorMap[predictorResult] || '3px solid black' };
}

export function capitalizeArrayToString(selectedArray) {
  const str = selectedArray.toString().trim();

  // If there's no comma, convert to uppercase
  if (!str.includes(',')) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // Otherwise, capitalize each word after a comma
  return str
    .split(',')
    .map(word => word.trim()) // Trim spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(', ');
}





