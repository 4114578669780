import { Component, OnDestroy, OnInit } from '@angular/core';
import { FarmoutService } from 'src/app/service/farmout-service/farmout.service';
import * as moment from 'moment-timezone';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DriversService } from 'src/app/component/drivers/drivers-service/drivers-service';

@Component({
  selector: 'app-farmout-receipt',
  templateUrl: './farmout-receipt.component.html',
  styleUrls: ['./farmout-receipt.component.css']
})
export class FarmoutReceiptComponent implements OnInit , OnDestroy {
  receipts:any = [];
  receiptModalObj:any;
  user:any;
  timeZone:any;
  todayDate:any;
  stats:any;
  preDrivers:any;
  

  constructor(
    private driverService : DriversService,
    private sharedDataService : SharedDataService,
    private farmoutService : FarmoutService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getFarmoutReceipt(){
    const queryParams = {
      date: this.todayDate,
      // company:'5acff96d370e23460cf0f1be'
      company: this.user && this.user.company ? this.user.company._id: this.user._id,
    }
    this.farmoutService.getReciptData(queryParams).subscribe((data:any)=>{
      if(data){
       this.receipts = data
      }
    })
  }

  getReceiptStats(){
    const queryParams = {
      date: this.todayDate,
      // company:'5acff96d370e23460cf0f1be'
      company: this.user && this.user.company ? this.user.company._id: this.user._id,
    }
    this.farmoutService.getReceiptStats(queryParams).subscribe((data:any)=>{
      if(data){
       this.stats = data
      }
    })
  }

    getDriverList() {
      this.driverService.getDrivers(this.user._id, 'active')
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          (data) => {
            if (data) {
              this.preDrivers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
            }
          }
        );
    }

  openReceiptModal(receipt:any){
    this.receiptModalObj = receipt
  }

   getUser() {
      this.sharedDataService
        .getUser()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.timeZone = data.timeZone;
            this.user = data;
            this.todayDate = moment.tz(this.timeZone).startOf('day').utc().toISOString();
            this.getDriverList();
            this.getReceiptStats();
            this.getFarmoutReceipt();
          }
        });
    }

    onSearchDateChange(event) {
      this.stats = null
      this.todayDate = event.toISOString();
      this.getReceiptStats();
      this.getFarmoutReceipt();
    }

    ngOnDestroy(): void {
    }
}
