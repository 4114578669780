import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { StaffMainComponent } from './component/staffs/staff-main/staff-main.component';
import { StaffsCreateComponent } from './component/staffs/staffs-create/staffs-create.component';
// import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { DispatchDashboardComponent } from './component/dispatch-center/dashboard/dashboard.component';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
import { DriverListComponent } from './component/drivers/driver-list/driver-list.component';
import { CreateDriverComponent } from './component/drivers/create-driver/create-driver.component';
// import { DriverProfileDashboardComponent } from './component/drivers/driver-profile-dashboard/driver-profile-dashboard.component';
import { CorporateuserComponent } from './component/corporate-user/corporate-user-list/corporate-user.component';
import { CorporateAddUserComponent } from './component/corporate-user/corporate-add-user/corporate-add-user.component';
import { FleetListComponent } from './component/fleet/fleet-list/fleet-list.component';
import { CreateFleetComponent } from './component/fleet/create-fleet/create-fleet.component';
import { DriverDistributionsComponent } from './component/driver-distributions/driver-distributions-list/driver-distributions.component';
// tslint:disable-next-line: max-line-length
import { DriverDistributionsCreateComponent } from './component/driver-distributions/driver-distributions-create/driver-distributions-create.component';

import { InviteSmsComponent } from './component/Invite/invite-sms/invite-sms.component';

import { DriverAlertComponent } from './component/driver-alert/driver-alert/driver-alert.component';
import { HelpingMaterialListComponent } from './component/helping-material/helping-material-list/helping-material-list.component';
import { ParseTripsComponent } from './component/planner/parse-trips/parse-trips.component';
import { DashboardComponent } from './component/assigned-trips/dashboard/dashboard.component';
import { UnassignedComponent } from './component/assigned-trips/unassigned/unassigned.component';
import { VerifiedTripLogsComponent } from './component/assigned-trips/verified-trip-logs/verified-trip-logs.component';
import { NewTripComponent } from './component/assigned-trips/new-trip/new-trip.component';
import { NemtStatisticsComponent } from './component/kpis/nemt-statistics/nemt-statistics.component';
import { NemtReportComponent } from './component/kpis/nemt-report/nemt-report.component';
import { DynamicFareListComponent } from './component/dynamic-fare/dynamic-fare-list/dynamic-fare-list.component';
import { DynamicFareAddComponent } from './component/dynamic-fare/dynamic-fare-add/dynamic-fare-add.component';
import { EarningsComponent } from './component/reports/earnings/earnings.component';
import { AdminNemtReportComponent } from './component/superAdmin/admin-nemt-report/admin-nemt-report.component';
import { TripDetailsComponent } from './component/dispatch-center//trip-details/trip-details.component';
import { DriverBreakdownComponent } from './component/kpis/driver-breakdown/driver-breakdown.component';
import { DashboardTripsDetailsComponent } from './component/assigned-trips/dashboard-trips-details/dashboard-trips-details.component';
import { NotificationDetailsComponent } from './notification/notification-details/notification-details.component';
// import { AccidnetsListComponent } from './component/accidents/accidnets-list/accidnets-list.component'
// import { AddAccidentComponent } from './component/accidents/add-accident/add-accident.component';
// import { AccidentDetailInTailwindFormComponent } from './component/accidents/accident-detail-in-tailwind-form/accident-detail-in-tailwind-form.component';
import { HelpingMaterialPdfComponent } from './component/helping-material/helping-material-pdf/helping-material-pdf.component';
import { LogsComponent } from './component/offered-trips/logs/logs.component';
import { MemberListComponent } from './component/members/member-list/member-list.component';
import { CreateMemberComponent } from './component/members/create-member/create-member.component';
import { DriverLoadComponent } from './component/assigned-trips/driver-load/driver-load.component';
import { OutstandingComponent } from './component/assigned-trips/outstanding/outstanding.component';
import { DriverInvoicesComponent } from './component/drivers/driver-invoices/driver-invoices.component';
import { StandingOrdersComponent } from './component/assigned-trips/standing-orders/standing-orders.component';
import { DirtRoadComponent } from './component/assigned-trips/dirt-road/dirt-road.component';
import { DriverTimelinesComponent } from './component/drivers/driver-timelines/driver-timelines.component';
import { TripDetailModelComponent } from './component/assigned-trips/trip-detail-model/trip-detail-model.component';
import { ZDatesComponent } from './component/z-dates/z-dates.component';
import { OfferedComponent } from './component/assigned-trips/offered/offered.component';
import { BrokerSettingComponent } from './component/planner/broker-setting/broker-setting.component';
import { BrokerSettingListComponent } from './component/planner/broker-setting-list/broker-setting-list.component';
import { AuthTokenComponent } from './component/auth-token/auth-token.component';
import { CompaniesListComponent } from './component/companies/companies-list/companies-list.component';
import { CreateCompanyComponent } from './component/companies/create-company/create-company.component';
import { CreatQuarterComponent } from './component/superAdmin/quarters/creat-quarter/creat-quarter.component';
import { QuarterListComponent } from './component/superAdmin/quarters/quarter-list/quarter-list.component';
import { InvoiceSettingListComponent } from './component/superAdmin/invoice-settings/invoice-setting-list/invoice-setting-list.component';
import { CorporateAddonListComponent } from './component/corporate-addons/corporate-addon-list/corporate-addon-list.component';
import { AddCorporateAddonComponent } from './component/corporate-addons/add-corporate-addon/add-corporate-addon.component';
import { NemtProTipsComponent } from './component/helping-material/nemt-pro-tips/nemt-pro-tips/nemt-pro-tips.component';
import { StatisticsDetailsComponent } from './component/kpis/nemt-statistics-details/statistics-details/statistics-details.component';
import { ManifestComponent } from './component/assigned-trips/manifest/manifest.component';
import { AdjustmentTripComponent } from './component/assigned-trips/adjustment-trip/adjustment-trip.component';
import { StandingOrdersV2Component } from './component/assigned-trips/standing-orders-v2/standing-orders-v2.component';
import { NemtSuperProTipsComponent } from './component/superAdmin/nemt-super-tips/nemt-super-pro-tips/nemt-super-pro-tips.component';
import { CreateTipsComponent } from './component/superAdmin/create-pro-tips/create-tips/create-tips.component';
import { AdjustmentComponent } from './component/dispatch-center/adjustment/adjustment.component';
import { GanttChartComponent } from './component/assigned-trips/gantt-chart/gantt-chart.component';
import { VehicleInspectionComponent } from './component/reports/inspection/vehicle-inspection/vehicle-inspection.component';
import { AddVehicleInspectionComponent } from './component/reports/inspection/add-vehicle-inspection/add-vehicle-inspection.component';
import { UserRoles } from './component/shared/layout/menu-service/menu.service';
import { UnAuthorizationGuard } from './service/non-auth-guard/unauthorization-guard.service';
import { DailyVehicleInspectionComponent } from './component/reports/inspection/daily-vehicle-inspection/daily-vehicle-inspection/daily-vehicle-inspection.component';
import { TrfsTemplatesComponent } from './component/assigned-trips/trfs-templates/trfs-templates.component';
import { CtcClaimedTripsComponent } from './component/assigned-trips/offered/ctc-claimed-trips/ctc-claimed-trips.component';
import { AddCmsCodeComponent } from './component/cms-1500/add-cms-code/add-cms-code.component';
import { ListCmsCodesComponent } from './component/cms-1500/list-cms-codes/list-cms-codes.component';
import { ManualLineTripComponent } from './component/assigned-trips/manual-line-trip/manual-line-trip.component';
import { AccidentalReportListComponent } from './component/accidental-report/accidental-report-list/accidental-report-list.component';
import { DriverExpensesComponent } from './component/maintenances/driver-expenses/driver-expenses.component';
import { VehicleExpensesComponent } from './component/maintenances/vehicle-expenses/vehicle-expenses.component';
import { MaintenanceStatsComponent } from './component/maintenances/maintenance-stats/maintenance-stats.component';
import { DriverLoadV2Component } from './component/assigned-trips/driver-load-v2/driver-load-v2.component';
import { DriverScoreComponent } from './component/reports/driver-score/driver-score.component';
import { DriverGrievanceComponent } from './component/driver-grievance/driver-grievance/driver-grievance.component';
import { DriverLoadWrapperComponent } from './component/assigned-trips/driver-load/driver-load-wrapper/driver-load-wrapper.component';
import { RoutingWrapperComponent } from './component/assigned-trips/outstanding/routing-wrapper/routing-wrapper.component';
import { InspectionWrapperComponent } from './component/reports/inspection/inspection-wrapper/inspection-wrapper.component';
import { OperationsV2Component } from './component/assigned-trips/operations-v2/operations-v2.component';
import { DriverTimelinesV2Component } from './component/drivers/driver-timelines-V2/driver-timelines-v2/driver-timelines-v2.component';
import { AddTripPurposesComponent } from './component/trip-purposes/trip-purposes-add/trip-purposes-add.component';
import { TripPurposesListComponent } from './component/trip-purposes/trip-purposes-list/trip-purposes-list.component';
import { DriverAmendmentsComponent } from './component/drivers/driver-amendments/driver-amendments/driver-amendments.component';
import { AddLevelOfServiceComponent } from './component/level-of-service/add-level-of-service/add-level-of-service.component';
import { LevelOfServiceComponent } from './component/level-of-service/level-of-service-list/level-of-service-list.component';
import { AddDriverAmendmentsComponent } from './component/drivers/driver-amendments/add-driver-amendments/add-driver-amendments.component';
import { AdminEarningReportComponent } from './component/superAdmin/admin-earning-report/admin-earning-report.component';
import { ServiceAreasAddComponent } from './component/service-areas/service-areas-add/service-areas-add.component';
import { ServiceAreasListingComponent } from './component/service-areas/service-areas-listing/service-areas-listing.component';
import { PrimaryDiagnosisListComponent } from './component/primary-diagnosis/primary-diagnosis-list/primary-diagnosis-list.component';
import { AddPrimaryDiagnosisComponent } from './component/primary-diagnosis/add-primary-diagnosis/add-primary-diagnosis.component';
import { AddModifierCodeComponent } from './component/modifier-codes/add-modifier-code/add-modifier-code.component';
import { ModifierCodeListComponent } from './component/modifier-codes/modifier-code-list/modifier-code-list.component';
import { UberTripsComponent } from './component/assigned-trips/farmout/uber-trips/uber-trips.component';
import { FarmoutTripsComponent } from './component/superAdmin/farmout-trips/farmout-trips.component';
import { FarmoutWrapperComponent } from './component/assigned-trips/farmout/farmout-wrapper/farmout-wrapper.component';


const routes: Routes = [
  {
    path: 'login', component: SignInComponent, canActivate: [UnAuthorizationGuard]
  },
  // {
  //   path: 'forgotpassword', component: ForgotPasswordComponent, canActivate: [UnAuthorizationGuard]
  // },

  // Dispatch Center
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard', component: DispatchDashboardComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },

  { path: 'auth-token/:token', component: AuthTokenComponent },
  // Operations
  {
    path: 'operations', component: DashboardComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'operations-v2', component: OperationsV2Component, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'operations/parsetrips', component: ParseTripsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },

  {
    path: 'operations/broker-configuration', component: BrokerSettingListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'operations/broker-configuration/add', component: BrokerSettingComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'operations/broker-configuration/:id/edit', component: BrokerSettingComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },



  // Trips
  {
    path: 'trips', component: UnassignedComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'trips/new', component: NewTripComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'trips/:id/edit', component: NewTripComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'trips/:id/adjust', component: AdjustmentComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'trips/drivers-load', component: DriverLoadWrapperComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },

  { path: 'trips/standing-orders-old', component: StandingOrdersComponent, canActivate: [AuthorizationGuard],data: {
  role: [ UserRoles.FULLACCESS]
    }},
  {
    path: 'trips/standing-orders', component: StandingOrdersV2Component, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'farmout', component: FarmoutWrapperComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },
  {
    path: 'trips/trip-sheet', component: GanttChartComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'trips/offered', component: OfferedComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'trips/offered/tripslogs', component: LogsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },

  {
    path: 'trips/offered/ctc-claimed-trips', component: CtcClaimedTripsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },


  {
    path: 'trips/grouping', component: RoutingWrapperComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },

  {
    path: 'trips/dirt-road', component: DirtRoadComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.CALL_CENTER_MNGR]
    }
  },

  // Manifest
  {
    path: 'manifest', component: ManifestComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT, UserRoles.CALL_CENTER_REP, UserRoles.DISPATCHER]
    }
  },


  // Credentialli
  { path: 'credentialing', redirectTo: 'credentialing/drivers', pathMatch: 'full' },
  {
    path: 'invites', component: InviteSmsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  // Credentails - Drivers
  {
    path: 'credentialing/drivers', component: DriverListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'credentialing/drivers/add', component: CreateDriverComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'credentialing/drivers/:id/edit', component: CreateDriverComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  // { path: 'credentialing/drivers/:id/profile', component: DriverProfileDashboardComponent, canActivate: [AuthorizationGuard],data: {
  // role: [ UserRoles.FULLACCESS]
  //     } },

  // Credentails - Fleet
  {
    path: 'credentialing/fleet', component: FleetListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'credentialing/fleet/add', component: CreateFleetComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'credentialing/fleet/:id/edit', component: CreateFleetComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },

  // Credentails - Staff
  {
    path: 'credentialing/staff', component: StaffMainComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/staff/add', component: StaffsCreateComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/staff/:id/edit', component: StaffsCreateComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },

  // Credentails - Members
  // {
  //   path: 'credentialing/members', component: MemberListComponent, canActivate: [AuthorizationGuard], data: {
  //     role: [ UserRoles.FULLACCESS]
  //     }
  // },
  // {
  //   path: 'credentialing/members/add', component: CreateMemberComponent, canActivate: [AuthorizationGuard], data: {
  //     role: [ UserRoles.FULLACCESS]
  //     }
  // },
  // {
  //   path: 'credentialing/members/:id/edit', component: CreateMemberComponent, canActivate: [AuthorizationGuard], data: {
  //     role: [ UserRoles.FULLACCESS]
  //     }
  // },

  // Credentails - Corporate Users
  {
    path: 'credentialing/corporates', component: CorporateuserComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/corporates/add', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/corporates/:id/edit', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },

  // Credentails - Level Of Service
  {
    path: 'credentialing/service-areas', component: ServiceAreasListingComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/service-areas/add', component: ServiceAreasAddComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },
  {
    path: 'credentialing/service-areas/:id/edit', component: ServiceAreasAddComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    }
  },

  // Credentails - Companies
  {
    path: 'credentialing/companies', component: CompaniesListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'credentialing/companies/add', component: CreateCompanyComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'credentialing/companies/:id/edit', component: CreateCompanyComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },

  //Credentials - Super Amin
  { path: 'credentialing/quarterpanels', component: QuarterListComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.ADMIN]
  } },
  { path: 'credentialing/quarterpanels/add', component: CreatQuarterComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.ADMIN]
  } },
  { path: 'credentialing/quarterpanels/:id/edit', component: CreatQuarterComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.ADMIN]
  }
  },

  { path: 'credentialing/levelofservice/:id/edit', component: AddLevelOfServiceComponent, canActivate: [AuthorizationGuard],data: {
    role: [UserRoles.ADMIN, UserRoles.FULLACCESS]
  } },
  { path: 'credentialing/levelofservice/add', component: AddLevelOfServiceComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.ADMIN, UserRoles.FULLACCESS]
  }},
  { path: 'credentialing/levelofservice', component: LevelOfServiceComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.ADMIN, UserRoles.FULLACCESS]
  } },

  //TimeSheet
  { path: 'timelines', redirectTo: 'timelines/dashboard', pathMatch: 'full' },
  {
    path: 'timelines/time-sheet', component: DriverTimelinesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'timelines/dashboard', component: DriverTimelinesV2Component, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'timelines/amendments', component: DriverAmendmentsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },
  {
    path: 'timelines/amendments/add', component: AddDriverAmendmentsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CREDENTIAL]
    }
  },

  // Fares
  {
    path: 'fares', component: DynamicFareListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'fares/add', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'fares/:id/edit', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'fares/distributions', component: DriverDistributionsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'fares/distributions/add', component: DriverDistributionsCreateComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'fares/distributions/:id/edit', component: DriverDistributionsCreateComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },

   // Add TripPurposes
   {
    path: 'fares/trip-purposes', component: TripPurposesListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'fares/trip-purposes/add', component: AddTripPurposesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'fares/trip-purposes/:id/edit', component: AddTripPurposesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },

  // Corprate Addons
  {
    path: 'fares/corporateaddon', component: CorporateAddonListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'fares/corporateaddon/add', component: AddCorporateAddonComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'fares/corporateaddon/:id/edit', component: AddCorporateAddonComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },


  //CMS 1500 Codes
  {
    path: 'fares/cmscodes/add', component: AddCmsCodeComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },

  {
    path: 'fares/cmscodes/:id/edit', component: AddCmsCodeComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },

  {
    path: 'fares/cmscodes', component: ListCmsCodesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    }
  },

 // Reports
 { path: 'maintenances', redirectTo: 'maintenances/driver-expense', pathMatch: 'full' },
 {
   path: 'maintenances/driver-expense', component: DriverExpensesComponent, canActivate: [AuthorizationGuard], data: {
     role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
   }
 },
 {
  path: 'maintenances/vehicle-expense', component: VehicleExpensesComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  }
},
{
  path: 'maintenances/maintenance-stats', component: MaintenanceStatsComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  }
},


  // Reports
  { path: 'reports', redirectTo: 'reports/earnings', pathMatch: 'full' },
  {
    path: 'reports/earnings', component: EarningsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'reports/nemtreport', component: NemtReportComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },
  {
    path: 'reports/statistics', component: NemtStatisticsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'reports/statisticsdetails', component: StatisticsDetailsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.ACCOUNTANT]
    }
  },
  // {
  //   path: 'reports/breakdown', component: DriverBreakdownComponent, canActivate: [AuthorizationGuard], data: {
  //     role: [ UserRoles.FULLACCESS]
  //     }
  // },
  {
    path: 'reports/driver-invoice', component: DriverInvoicesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },
  { path: 'reports/vehicle-inspection', component: InspectionWrapperComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },
  { path: 'reports/vehicle-inspection/add', component: AddVehicleInspectionComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },
  { path: 'reports/vehicle-inspection/:id/edit', component: AddVehicleInspectionComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },

  // { path: 'reports/vehicle-inspection/daily', component: DailyVehicleInspectionComponent, canActivate: [AuthorizationGuard], data: {
  //   role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  // } },


  { path: 'reports/accidental-report', component: AccidentalReportListComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },

  { path: 'reports/drivergrievance', component: DriverGrievanceComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },


  { path: 'reports/accidental-report', component: AccidentalReportListComponent, canActivate: [AuthorizationGuard], data: {
    role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
  } },

  {
    path: 'reports/driver-score', component: DriverScoreComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },


  // For Super Admin
  {
    path: 'reports/superadmin/nemtreport', component: AdminNemtReportComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'reports/superadmin/earning', component: AdminEarningReportComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },

  {
    path: 'reports/superadmin/farmout', component: FarmoutTripsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },

  {
    path: 'quarterpanels', component: QuarterListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'quarterpanels/add', component: CreatQuarterComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'quarterpanels/:id/edit', component: CreatQuarterComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'providerinvoicesettings', component: InvoiceSettingListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'nemtprotips', component: NemtSuperProTipsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'nemtprotips/add', component: CreateTipsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },
  {
    path: 'nemtprotips/:id/edit', component: CreateTipsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.ADMIN]
    }
  },

  //Billing
  { path: 'billing', redirectTo: 'billing/adjusted-trips', pathMatch: 'full' },
  {
    path: 'billing/adjusted-trips', component: VerifiedTripLogsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'billing/trfs-templates', component: TrfsTemplatesComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'billing/adjustments', component: AdjustmentTripComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'billing/primary-diagnosis', component: PrimaryDiagnosisListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    }
  },
  {
    path: 'billing/primary-diagnosis/:id/edit', component: AddPrimaryDiagnosisComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'billing/primary-diagnosis/add', component: AddPrimaryDiagnosisComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },
  {
    path: 'billing/modifier-codes/:id/edit', component: AddModifierCodeComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'billing/modifier-codes/add', component: AddModifierCodeComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'billing/modifier-codes', component: ModifierCodeListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  // Support
  { path: 'support', component: ZDatesComponent, canActivate: [AuthorizationGuard],data: {
  role: [ UserRoles.FULLACCESS]
      } },

  {
    path: 'driveralerts', component: DriverAlertComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },

  {
    path: 'support/queries', component: DriverAlertComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS]
    }
  },



  // Profile
  {
    path: 'profile', component: EditUserProfileComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.ACCOUNTANT, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    }
  },

  // Notifications
  {
    path: 'notifications', component: NotificationDetailsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    }
  },

  // Helping Material
  { path: 'manual', redirectTo: 'manual/helpingmaterial', pathMatch: 'full' },
  {
    path: 'manual/helpingmaterial', component: HelpingMaterialListComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER]
    }
  },
  {
    path: 'manual/helpingmaterial/pdf/:id', component: HelpingMaterialPdfComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER]
    }
  },
  {
    path: 'manual/nemtprotips', component: NemtProTipsComponent, canActivate: [AuthorizationGuard], data: {
      role: [UserRoles.FULLACCESS, UserRoles.DISPATCHER]
    }
  },
  // Not Found
  { path: '**', component: ZDatesComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
