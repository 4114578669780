import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farmout-wrapper',
  templateUrl: './farmout-wrapper.component.html',
  styleUrls: ['./farmout-wrapper.component.css']
})
export class FarmoutWrapperComponent implements OnInit {
  subMenu = 'V1';
  constructor() { }

  ngOnInit() {
  }

  onSelectSubMenu(menu) {
    this.subMenu = menu;
  }
  ngOnDestroy(): void {

  }
}
