import { Injectable } from '@angular/core';
import { cleanUpSpaces, exportToCsv, exportToCsvWithColor, exportToExcel, getBrokersList, isDecimal } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import { ExcelService } from '../excel-service/excel.service';
import { CamelCasePipe } from 'src/app/pipe/camelcase.filter/camelcase.filter.pipe';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(
    private excelService: ExcelService,
    private capitalize: CamelCasePipe
  ) { }

  generatePassengerReport(data) {
    const { trips, corporate, driver, vehicle, timezone } = data;
    const myTrips = JSON.parse(JSON.stringify(trips));
    myTrips.sort((a, b) => a.priorityClient.displayName.localeCompare(b.priorityClient.displayName)); // ASC

    const table = [[
      "Id",
      "Passenger's First Name",
      "Passenger's Last Name",
      "Passenger DOB",
      "Schedule Time",
      "Appointment Time",
      "Gender",
      "Weight",
      "Passenger's Phone #",
      "Passenger's 2-nd Phone#",
      "Passenger's Address",
      "Passenger's 2-nd Address",
      "Active (Y/N)",
      "Email	On Hold (Y/N)",
      "Need Attendant (Y/N)",
      "Emergency Name",
      "Emergency Name", "Emergency Phone",
      "Payer",
      "Payer Name",
      "Payer Address",
      "Payer Suit #",
      "Payer Email",
      "Payer Fax #",
      "Payer Contact Person",
      "Payer Contact Person's Phone Number",
      "Payer Phone Ext #",
      "Active Payer (Y/N)",
      "Payer Invoice Periodicity"
    ]];
    let privatePay = myTrips.filter(trip => {
      if (corporate) {
        if (trip.jobType !== 'cooperate') {
          return false;
        }
        if (trip.cooperate && trip.cooperate._id.toString() !== corporate) {
          return false;
        }
      }
      if (driver) {
        if (trip.driver && trip.driver._id.toString() !== driver) {
          return false;
        }
      }
      if (vehicle) {
        if (trip.tripRequirement !== vehicle) {
          return false;
        }
      }
      return true;
    })
    for (const trip of privatePay) {

      // if (this.selectedCooperate) {
      //   if (trip.jobType !== 'cooperate') {
      //     continue;
      //   }
      //   if (trip.cooperate && trip.cooperate._id.toString() !== this.selectedCooperate){
      //     continue;
      //   }
      // }
      let forTrip;
      if (trip.jobType !== 'cooperate') {
        forTrip = trip.companyType === "" ? "Cab Dispatch" : getBrokersList[trip.companyType];
      }

      if (trip.jobType === 'cooperate') {
        forTrip = trip.cooperate ? trip.cooperate.displayName : "N/A"
      }

      let appointmentTime;
      if (trip.appointmentTime) {
        appointmentTime = moment(trip.appointmentTime).tz(timezone).format('MM/DD/YYYY HHmm');
      }
      let scheduleTime;
      if (trip.scheduleTime) {
        scheduleTime = moment(trip.scheduleTime).tz(timezone).format('MM/DD/YYYY HHmm');
      }
      let name = trip.priorityClient.displayName.split(' ');
      const t = [
        trip.tripId,
        name[0], //"Passenger's First Name",
        name[1] ? name[1] : 'N/A', //"Passenger's Last Name",
        trip.dob, //"Passenger DOB",
        scheduleTime,
        appointmentTime,
        "---", //"Gender",
        trip.weight, //"Weight",
        trip.priorityClient.cellPhone, //"Passenger's Phone #",
        trip.priorityClient.contactNumber, //"Passenger's 2-nd Phone#",
        trip.jobOriginAddress, //"Passenger's Address",
        "---", //"Passenger's 2-nd Address",
        "---", //"Active (Y/N)",
        trip.priorityClient.email, //"Email	On Hold (Y/N)",
        "---", //"Need Attendant (Y/N)",
        "---", //"Emergency Name",
        "---", //"Emergency Name",
        "---", //"Emergency Phone",
        forTrip, //"Payer",
        "---", //"Payer Name",
        "---", //"Payer Address",
        "---", //"Payer Suit #",
        "---", //"Payer Email",
        "---", //"Payer Fax #",
        "---", //"Payer Contact Person",
        "---", //"Payer Contact Person's Phone Number",
        "---", //"Payer Phone Ext #",
        "TRUE", //"Active Payer (Y/N)",
        "Everyday", //"Payer Invoice Periodicity"
      ]
      table.push(t);
    }
    exportToCsv(`rns-report-${moment().format("MM-DD-YYYY")}.csv`, table);
  }

  generateDriverReport(data) {
    const { date, trips, corporate, driver, vehicle, timezone } = data;
    const myTrips = JSON.parse(JSON.stringify(trips));

    const table = [
      [
        "Driver",
        "Account Code",
        "Auth Num",
        "Schedule Time",
        "Appointment Time",
        "Will Call",
        "LOA",
        "Passenger's Phone #",
        "Passenger's First Name",
        "Passenger's Last Name",
        "Pickup Name",
        "PU Phone #",
        "PU Address",
        "Dropoff Name",
        "Dropoff Phone #",
        "Dropoff Address",
        "Rider Comments",
      ]];

    let privatePay = myTrips.filter(trip => {
      if (corporate) {
        if (trip.jobType !== 'cooperate') {
          return false;
        }
        if (trip.cooperate && trip.cooperate._id.toString() !== corporate) {
          return false;
        }
      }
      if (driver) {
        if (trip.driver && trip.driver._id.toString() !== driver) {
          return false;
        }
      }
      if (vehicle) {
        if (trip.tripRequirement !== vehicle) {
          return false;
        }
      }
      return true;
    })

    const driverWise = {};
    for (const trip of privatePay) {
      if (trip.driver) {
        if (trip.driver && driverWise[trip.driver.displayName]) {
          driverWise[trip.driver.displayName].push(trip);
        } else {
          driverWise[trip.driver.displayName] = [trip];
        }
      } else {
        if (driverWise["unassigned"]) {
          driverWise["unassigned"].push(trip);
        } else {
          driverWise["unassigned"] = [trip];
        }
      }

    }


    var sortedObject = {};
    var sortedKeys = Object.keys(driverWise).sort();
    for (var key of sortedKeys) {
      sortedObject[key] = driverWise[key];
    }

    for (const driverId in sortedObject) {
      for (const trip of driverWise[driverId]) {

        let forTrip;
        if (trip.jobType !== 'cooperate') {
          forTrip = trip.companyType === "" ? "Cab Dispatch" : getBrokersList[trip.companyType];
        }

        if (trip.jobType === 'cooperate') {
          forTrip = trip.cooperate ? trip.cooperate.displayName : "N/A"
        }

        let appointmentTime;
        if (trip.appointmentTime) {
          appointmentTime = moment(trip.appointmentTime).tz(timezone).format('MM/DD/YYYY HHmm');
        }
        let scheduleTime;
        if (trip.scheduleTime) {
          scheduleTime = moment(trip.scheduleTime).tz(timezone).format('MM/DD/YYYY HHmm');
        }
        let name = trip.priorityClient.displayName.split(' ');


        const t = [
          trip.driver ? trip.driver.displayName : '',
          forTrip,
          trip.tripId,
          scheduleTime,
          appointmentTime,
          scheduleTime.split(" ")[1] >= "22" ? "Y" : "N",
          this.checkCarType(trip.tripRequirement),
          trip.priorityClient.contactNumber,
          name[0], //"Passenger's First Name",
          name[1] ? name[1] : '', //"Passenger's Last Name",
          trip.originAddressAdditional && trip.originAddressAdditional.place ? trip.originAddressAdditional.place : '',
          trip.originAddressAdditional && trip.originAddressAdditional.phone ? trip.originAddressAdditional.phone : '',
          trip.jobOriginAddress, //"Passenger's Address",,
          trip.destinationAddressAdditional && trip.destinationAddressAdditional.place ? trip.destinationAddressAdditional.place : '',
          trip.destinationAddressAdditional && trip.destinationAddressAdditional.phone ? trip.destinationAddressAdditional.phone : '',
          trip.jobDestinationAddress, //"Passenger's Address",
          trip.note,
        ]
        table.push(t);
      }
    }


    exportToCsv(`drivers-manifest-${date}.csv`, table);
    // this.excelService.setCombinedFirstRowWithFontSize(date, table, `drivers-report-${moment().format("MM-DD-YYYY")}`);
  }

  checkCarType(car) {
    const vehicles = {
      'BW': 'SUV',
      'SUV': 'SUV',
      'Premium': 'P',
      'Stretcher': 'GUR',
      'S': 'GUR',
      'BLS': 'GUR',
      'BSTR': 'GUR',
      'VS': 'GUR',
      'LIV': 'A',
      'AMB': 'A',
      'Ambulatory': 'A',
      'Wheelchair': 'WC',
      'W': 'WC',
      'WAV': 'WC',
      'WPCA': 'WC',
      'WMAV': 'WC',
      'A': 'A',
      'L': 'A',
      'ADD': 'A',
      'AMAV': 'A',
      'Standard': 'A',
      '': 'A'
    };

    if (!vehicles[car]) return car;
    return vehicles[car];
  }
  generateCorporateReport(data) {
    const { trips, corporate, driver, vehicle, payor, timezone } = data;

    const table = [[
      "Passenger's First Name",
      "Passenger's Last Name",
      "Passenger DOB",
      "Schedule Time",
      "Appointment Time",
      "Gender",
      "Weight",
      "Passenger's Phone #",
      "Passenger's 2-nd Phone#",
      "Passenger's Address",
      "Email	On Hold (Y/N)",
      "Corporate Name",
      "Payor",
      "Active Payer (Y/N)"
    ]];

    let filterdataList = trips.filter(trip => {

      if (corporate) {
        if (trip.jobType !== 'cooperate') {
          return false;
        }
        if (trip.cooperate && trip.cooperate._id.toString() !== corporate) {
          return false;
        }

      }

      if (payor) {
        if (!trip.payor || trip.payor.toLowerCase() !== payor.toLowerCase()) {
          return false;
        }
      }

      if (driver) {
        if (trip.driver && trip.driver._id.toString() !== driver) {
          return false;
        }
      }

      if (vehicle) {
        if (trip.tripRequirement !== vehicle) {
          return false;
        }
      }
      return true;
    })


    for (const trip of filterdataList) {

      let forTrip;
      if (trip.jobType === 'cooperate') {
        forTrip = trip.cooperate ? trip.cooperate.displayName : "N/A"
      }

      let appointmentTime;
      if (trip.appointmentTime) {
        appointmentTime = moment(trip.appointmentTime).tz(timezone).format('MM/DD/YYYY HHmm');
      }
      let scheduleTime;
      if (trip.scheduleTime) {
        scheduleTime = moment(trip.scheduleTime).tz(timezone).format('MM/DD/YYYY HHmm');
      }
      let name = trip.priorityClient.displayName.split(' ');
      const t = [
        name[0], //"Passenger's First Name",
        name[1] ? name[1] : 'N/A', //"Passenger's Last Name",
        trip.dob, //"Passenger DOB",
        scheduleTime,
        appointmentTime,
        "---", //"Gender",
        trip.weight, //"Weight",
        trip.priorityClient.cellPhone, //"Passenger's Phone #",
        trip.priorityClient.contactNumber, //"Passenger's 2-nd Phone#",
        trip.jobOriginAddress, //"Passenger's Address",
        trip.priorityClient.email, //"Email	On Hold (Y/N)",
        forTrip, //"Payer",
        trip.payor, //"Payor",
        "TRUE", //"Active Payer (Y/N)",
      ]
      table.push(t);
    }
    exportToCsv(`payor-report-${moment().format("MM-DD-YYYY")}.csv`, table);
  }

  generateScoreReport(data) {
    const { date, scores } = data;
    const table = [
      [
        "Driver",
        "Completed",
        "Early",
        "On Time",
        "Late",
        "Early Time Score",
        "On Time Score",
        "Late Time Score"
      ]];

    scores.forEach(score => {
      table.push([
        score.driver.displayName,
        score.total,
        score.earlyTrips,
        score.onTimeTrips,
        score.lateTrips,
        isDecimal(score.percentageEarly) ? score.percentageEarly.toFixed(2) + '%' : score.percentageEarly + '%',
        isDecimal(score.percentageOnTime) ? score.percentageOnTime.toFixed(2) + '%' : score.percentageOnTime + '%',
        isDecimal(score.percentageLate) ? score.percentageLate.toFixed(2) + '%' : score.percentageLate + '%'
      ]);
    });
    exportToCsv(`driver-score-${date}.csv`, table);
  }

  generateTripsReport(data) {

    const trips = data.selectedTrips;
    const timeZone = data.timezone;
    const table = [[
      "Type of Trip",
      "Broker/Corp Name",
      "Trip ID",
      "Member Name",
      "Pick-up Address",
      "Drop-Off Address",
      "Phone Number 1",
      "Phone Number 2",
      "Mileage",
      "Schedule Time",
      "Actual Pick-up Time",
      "Appt. Time",
      "Actual Drop-Off Time",
      "Level Of Service",
      "Fare",
      "Driver Name",
      "Notes/Dispatch Notes",
      "Status",
      "On Scene At",
      "Member Onboard At",
      "Finished At",
      "Cancelled At",
      "Cancelled Reason"
    ]];
    for (const trip of trips) {
      let forTrip;
      if (trip.jobType !== 'cooperate') {
        forTrip = trip.companyType === "" ? "Cab Dispatch" : "Broker";
      }

      if (trip.jobType === 'cooperate') {
        forTrip = trip.cooperate ? "Corporate" : "N/A"
      }
      let corporateName

      if (trip.jobType !== 'cooperate') {
        corporateName = trip.companyType === "" ? "N/A" : getBrokersList[trip.companyType];
      }

      if (trip.jobType === 'cooperate') {
        corporateName = trip.cooperate ? trip.cooperate.displayName : "N/A"
      }

      let appointmentTime;
      if (trip.appointmentTime) {
        appointmentTime = moment(trip.appointmentTime).tz(timeZone).format('MM/DD/YYYY HHmm');
      }
      let scheduleTime;
      if (trip.scheduleTime) {
        scheduleTime = moment(trip.scheduleTime).tz(timeZone).format('MM/DD/YYYY HHmm');
      }

      let driverName;
      if (trip.driver) {
        driverName = trip.driver.displayName;
      }

      let notes;
      if (trip.note) {
        notes = trip.note ? trip.note : 'N/A';
      }

      if (trip.companyNote) {
        notes = trip.companyNote ? trip.companyNote : 'N/A';
      }

      let actualPickupTime;
      if (trip.job && trip.job.trackInfoJob && trip.job.trackInfoJob.jobStarted) {
        actualPickupTime = moment(trip.job.trackInfoJob.jobStarted.time).tz(timeZone).format('MM/DD/YYYY HHmm');
      }

      let actualDropOffTime;
      if (trip.job && trip.job.trackInfoJob && trip.job.trackInfoJob.jobFinished) {
        actualDropOffTime = moment(trip.job.trackInfoJob.jobFinished.time).tz(timeZone).format('MM/DD/YYYY HHmm');
      }

      let fare;
      if (trip.jobType !== 'cooperate' && trip.preSpecialRate) {
        fare = trip.preSpecialRate;
      }

      if (trip.jobType === 'cooperate' && trip.customerSpecialRate) {
        fare = trip.customerSpecialRate;
      }

      if (!fare) {
        if (trip.job && trip.job.jobFareDetail && trip.job.jobFareDetail.jobFee) {
          fare = trip.job.jobFareDetail.jobFee
        }
      }

      const status = this.getTripStatus(trip);

      let jobAccepted, jobArrived, jobStarted, jobCompleted, jobFinished, jobCancelled;
      if (trip.job && trip.job.trackInfoJob) {
        const jobInfo = trip.job.trackInfoJob;
        jobAccepted = jobInfo.jobAccepted ? moment(jobInfo.jobAccepted.time).tz(timeZone).format('MM/DD/YYYY HHmm') : "";
        jobArrived = jobInfo.jobArrived ? moment(jobInfo.jobArrived.time).tz(timeZone).format('MM/DD/YYYY HHmm') : ""; // On Scene
        jobStarted = jobInfo.jobStarted ? moment(jobInfo.jobStarted.time).tz(timeZone).format('MM/DD/YYYY HHmm') : ""; // Member Onboard
        jobCompleted = jobInfo.jobCompleted ? moment(jobInfo.jobCompleted.time).tz(timeZone).format('MM/DD/YYYY HHmm') : "";
        jobFinished = jobInfo.jobFinished ? moment(jobInfo.jobFinished.time).tz(timeZone).format('MM/DD/YYYY HHmm') : ""; // Finished
        jobCancelled = jobInfo.jobCancelled ? moment(jobInfo.jobCancelled.time).tz(timeZone).format('MM/DD/YYYY HHmm') : "";
      }

      const t = [
        forTrip, //"Trip Type"
        corporateName, //"Broker/Corp"
        trip.tripId,
        trip.priorityClient.displayName, //"Member Name"
        trip.jobOriginAddress, //"Job Origin Address"
        trip.jobDestinationAddress, //"Job Destination Address"
        trip.priorityClient.cellPhone, //"Passenger's Phone #",
        trip.priorityClient.contactNumber ? trip.priorityClient.contactNumber : 'N/A', //"Passenger's 2-nd Phone#",
        trip.milage ? trip.milage : 'N/A',
        scheduleTime,
        actualPickupTime ? actualPickupTime : 'N/A',
        appointmentTime,
        actualDropOffTime ? actualDropOffTime : 'N/A',
        trip.tripRequirement,
        fare ? '$' + fare : 'N/A',
        driverName,
        notes,
        status,
        jobArrived,
        jobStarted,
        jobFinished,
        jobCancelled,
        trip.changeReason
      ]
      table.push(t);
    }
    exportToCsv(`trips-report-${moment(data.scheduleTime).tz(timeZone).format("MM-DD-YYYY")}-To-${moment(data.endDate).tz(timeZone).format("MM-DD-YYYY")}.csv`, table);
  }

  generateCompletedTripReport(data) {
    const { trips, date, timezone } = data
    const table = [
      [
        "driver_name",
        "driver_email",
        "driver_phone",
        "driver_identifier",
        "vehicle_type",
        "vehicle_identifier",
        "vehicle_license_plate",
        "vehicle_operator_information",
        "vehicle_number",
        "customer_name",
        "customer_email",
        "customer_phone",
        "booked_by",
        "booking_agent",
        "affiliate_status",
        "affiliate",
        "ride_id",
        "ride_external_id",
        "ride_startAddr",
        "ride_endAddr",
        "ride_start_coordinate",
        "ride_end_coordinate",
        "ride_createdDate",
        "ride_acceptedDate",
        "ride_pickupDate",
        "ride_estimatedArrivedDate",
        "ride_arrivedDate",
        "ride_startDate",
        "ride_endDate",
        "ride_duration_minutes",
        "ride_miles",
        "ride_waiting_time_minutes",
        "ride_passengers",
        "ride_coupon_code",
        "ride_dispatcher_notes",
        "ride_drivers_notes",
        "service_type",
        "account_name",
        "account_id",
        "ride_status",
        "driver_rating_stars",
        "driver_rating_message",
        "rider_rating_stars",
        "rider_rating_message",
        "ride_chat_history",
        "ride_surcharge_breakdown",
        "ride_transaction_currency",
        "ride_transaction_total",
        "ride_transaction_subtotal",
        "ride_transaction_tax",
        "ride_transaction_tolls",
        "ride_transaction_waiting",
        "ride_transaction_other_surcharge",
        "ride_transaction_tip",
        "ride_transaction_discount",
        "ride_transaction_processingFees",
        "ride_transaction_refunded",
        "ride_transaction_credited",
        "ride_transaction_affiliate_share",
        "ride_transaction_dashride_fee",
        "ride_transaction_company_net",
        "ride_transaction_driverCommission",
        "ride_transaction_paymentMethod",
        "ride_bank_deposit_id",
        "ride_prepaid",
        "ride_prepaid_charged",
        "ride_prepaid_net",
        "ride_prepaid_refunded",
        "extraField_reason_for_transport",
        "extraField_patients_date_of_birth",
        "extraField_special_instructions:",
        "extraField_destination/office_suite#",
        "extraField_patients_cell_#",
        "extraField_facilities_#",
        "extraField_passengers",
        "extraField_appointment_type",
        "extraField_if_transport_by_wheelchair:",
        "extraField_medical_office_name:",
        "extraField_appointment_time",
        "extraField_date_requesting_transport:",
        "extraField_patients_weight",
        "extraField_facility_contact_number",
        "extraField_doctor/hospital_information",
        "extraField_special_instructions_for_driver",
        "extraField_special_instructions(i.e)_patient's_weight",
        "extraField_patients_weight",
        "extraField_patient_weight",
        "extraField_appointment_suite_number",
        "extraField_covid-19",
        "extraField_date_of_birth",
        "extraField_flight_#",
        "extraField_departure_time",
        "extraField_arrival_time"
      ]];
    trips.forEach(trip => {
      let created, acceptedDate, pickupDate, arrivedDate, startedDate, endDate, rideDuration, rideWaitTime, totalFare, rideMiles, vehicleType = '';
      if (trip) {
        if (trip.job) {
          if (trip.job.created) {
            created = moment(trip.job.created).tz(timezone).format("MMM DD, YYYY HH:mm A");
          }

          if (trip.job.trackInfoJob) {
            if (trip.job.trackInfoJob.jobAccepted) {
              if (trip.job.trackInfoJob.jobAccepted.time) {
                acceptedDate = moment(trip.job.trackInfoJob.jobAccepted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
              }
            }

            if (trip.job.trackInfoJob.jobArrived) {
              if (trip.job.trackInfoJob.jobArrived.time) {
                pickupDate = moment(trip.job.trackInfoJob.jobArrived.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
              }
            }

            if (trip.job.trackInfoJob.jobStarted) {
              if (trip.job.trackInfoJob.jobStarted.time) {
                arrivedDate = moment(trip.job.trackInfoJob.jobStarted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
              }
            }

            if (trip.job.trackInfoJob.jobStarted) {
              if (trip.job.trackInfoJob.jobStarted.time) {
                startedDate = moment(trip.job.trackInfoJob.jobStarted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
              }
              if (trip.job.trackInfoJob.jobStarted.duration) {
                rideWaitTime = trip.job.trackInfoJob.jobStarted.duration.toFixed(0);
              }
            }

            if (trip.job.trackInfoJob.jobCompleted) {
              if (trip.job.trackInfoJob.jobCompleted.time) {
                endDate = moment(trip.job.trackInfoJob.jobCompleted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
              }
              if (trip.job.trackInfoJob.jobCompleted.duration) {
                rideDuration = trip.job.trackInfoJob.jobCompleted.duration.toFixed(0);
              }
              if (trip.job.trackInfoJob.jobCompleted.distance) {
                rideMiles = trip.job.trackInfoJob.jobCompleted.distance.toFixed(1);
                if (trip.job.trackInfoJob.jobCompleted.actualMilage) {
                  rideMiles = trip.job.trackInfoJob.jobCompleted.actualMilage.toFixed(1);
                }
              }
            }



          }


          if (trip.job.jobFareDetail) {
            if (trip.job.jobFareDetail.jobFee) {
              totalFare = trip.job.jobFareDetail.jobFee.toFixed(2);
            }
          }
          if (trip.tripRequirement) {
            vehicleType = trip.tripRequirement
          }

        }


        const t = [
          trip.driver && trip.driver.displayName ? trip.driver.displayName : '',
          trip.driver && trip.driver.email ? trip.driver.email : '',
          trip.driver && trip.driver.contactNumber ? trip.driver.contactNumber : '',
          '',
          vehicleType ? vehicleType : ' ',
          '',
          '',
          '',
          '',
          trip.priorityClient && trip.priorityClient.displayName ? trip.priorityClient.displayName : '',
          trip.priorityClient && trip.priorityClient.email ? trip.priorityClient.email : '',
          trip.priorityClient && trip.priorityClient.contactNumber,
          trip.createdBy,
          '',
          'in-house',
          '',
          trip.tripId ? trip.tripId : '',
          trip.rawId ? trip.rawId : '',
          trip.jobOriginAddress ? trip.jobOriginAddress : '',
          trip.jobDestinationAddress ? trip.jobDestinationAddress : '',
          "[" + trip.jobOriginLatitude + "," + trip.jobOriginLongitude + "]",
          "[" + trip.jobDestinationLatitude + "," + trip.jobDestinationLongitude + "]",
          created,
          acceptedDate,
          pickupDate,
          '',
          arrivedDate,
          startedDate ,
          endDate,
          rideDuration,
          rideMiles ,
          rideWaitTime,
          1+trip.noOfEscorts,
          '',
          trip.companyNote,
          trip.driverNote,
          vehicleType,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'usd',
          totalFare,
          totalFare,
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          '0.00',
          totalFare,
          '0.00',
          'invoice',
          '',
          'no',
          '0.00',
          '0.00',
          '0.00',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ]
        table.push(t)
      }




    });
    exportToCsv(`reports-${date}.csv`, table);
  }

  generateFinishedReport(data){
    const { trips, date, timezone } = data
    const table = [
      [
        'Member Name',
        'Member Phone',
        'Member Email',
        "driver_name",
        "driver_email",
        "driver_phone",
        'Trip ID',
        'Raw ID',
        'Broker',
        'Vehicle',
        'Pickup Address',
        'Dropoff Address',
        'Pickup Coordinates',
        'Dropoff Coordinates',
        'Pickup Time',
        'Dropoff Time',
        'Total Miles',
        'Accepted time',
        'Accepted Duration',
        'Accepted Distance',
        'Accepted Coordinates',
        'Started Time',
        'Started Duration',
        'Started Distance',
        'Started Coordinates',
        'Arrived Time',
        'Arrived Duration',
        'Arrived Distance',
        'Arrived Coordinates',
        'Completed timeZone',
        'Completed Duration',
        'Completed Distance',
        'Completed Coordinates',
        'Finished',
        'Finished Duration',
        'Finished Distance',
        'Finished Coordinates',
      ]];
      trips.forEach(trip => {
        let scheduleTime,appointmentTime,acceptedTime,startedTime,arrivedTime,completedTime,finishedTime, rideMiles, vehicleType = '';
        if (trip) {
          if (trip.job) {
            if (trip.scheduleTime) {
              scheduleTime = moment(trip.scheduleTime).tz(timezone).format("MMM DD, YYYY HH:mm A");
            }
            if (trip.appointmentTime) {
              appointmentTime = moment(trip.appointmentTime).tz(timezone).format("MMM DD, YYYY HH:mm A");
            }

            if (trip.job.trackInfoJob) {
              if (trip.job.trackInfoJob.jobAccepted) {
                if (trip.job.trackInfoJob.jobAccepted.time) {
                  acceptedTime = moment(trip.job.trackInfoJob.jobAccepted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
                }
              }
              if (trip.job.trackInfoJob.jobStarted) {
                if (trip.job.trackInfoJob.jobStarted.time) {
                  startedTime = moment(trip.job.trackInfoJob.jobStarted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
                }
              }

              if (trip.job.trackInfoJob.jobArrived) {
                if (trip.job.trackInfoJob.jobArrived.time) {
                  arrivedTime = moment(trip.job.trackInfoJob.jobArrived.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
                }
              }
              if (trip.job.trackInfoJob.jobCompleted) {
                if (trip.job.trackInfoJob.jobCompleted.time) {
                  completedTime = moment(trip.job.trackInfoJob.jobCompleted.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
                }
                if (trip.job.trackInfoJob.jobCompleted.distance) {
                  rideMiles = trip.job.trackInfoJob.jobCompleted.distance.toFixed(1);
                  if (trip.job.trackInfoJob.jobCompleted.actualMilage) {
                    rideMiles = trip.job.trackInfoJob.jobCompleted.actualMilage.toFixed(1);
                  }
                }
              }

              if (trip.job.trackInfoJob.jobFinished) {
                if (trip.job.trackInfoJob.jobFinished.time) {
                  finishedTime = moment(trip.job.trackInfoJob.jobFinished.time).tz(timezone).format("MMM DD, YYYY HH:mm A");
                }
              }
            }
            if (trip.tripRequirement) {
              vehicleType = trip.tripRequirement
            }

          }


          const t = [
            trip.priorityClient && trip.priorityClient.displayName ? trip.priorityClient.displayName : '',
            trip.priorityClient && trip.priorityClient.contactNumber,
            trip.priorityClient && trip.priorityClient.email ? trip.priorityClient.email : '',
            trip.driver && trip.driver.displayName ? trip.driver.displayName : '',
            trip.driver && trip.driver.email ? trip.driver.email : '',
            trip.driver && trip.driver.contactNumber ? trip.driver.contactNumber : '',
            trip.tripId ? trip.tripId : '',
            trip.rawId ? trip.rawId : '',
            trip.companyType ? trip.companyType: trip.cooperate.displayName,
            vehicleType ? vehicleType : ' ',
            trip.jobOriginAddress ? trip.jobOriginAddress : '',
            trip.jobDestinationAddress ? trip.jobDestinationAddress : '',
            "[" + trip.jobOriginLatitude + "," + trip.jobOriginLongitude + "]",
            "[" + trip.jobDestinationLatitude + "," + trip.jobDestinationLongitude + "]",
            scheduleTime,
            appointmentTime,
            trip.milage,
            acceptedTime,
            trip.job.trackInfoJob.jobAccepted.duration,
            trip.job.trackInfoJob.jobAccepted.distance,
            "[" + trip.job.trackInfoJob.jobAccepted.coords[0] + trip.job.trackInfoJob.jobAccepted.coords[1] + "]",
            startedTime,
            trip.job.trackInfoJob.jobStarted.duration,
            trip.job.trackInfoJob.jobStarted.distance,
            "[" + trip.job.trackInfoJob.jobStarted.coords[0] + trip.job.trackInfoJob.jobStarted.coords[1] + "]",
            arrivedTime,
            trip.job.trackInfoJob.jobArrived.duration,
            trip.job.trackInfoJob.jobArrived.distance,
            "[" + trip.job.trackInfoJob.jobArrived.coords[0] + trip.job.trackInfoJob.jobArrived.coords[1] + "]",
            completedTime,
            trip.job.trackInfoJob.jobCompleted.duration,
            trip.job.trackInfoJob.jobCompleted.distance,
            "[" + trip.job.trackInfoJob.jobCompleted.coords[0] + trip.job.trackInfoJob.jobCompleted.coords[1] + "]",
            finishedTime,
            trip.job.trackInfoJob.jobFinished.duration,
            trip.job.trackInfoJob.jobFinished.distance,
            "[" + trip.job.trackInfoJob.jobFinished.coords[0] + trip.job.trackInfoJob.jobFinished.coords[1] + "]",

          ]
          table.push(t)
        }

  })
  exportToCsv(`Completed-${date}.csv`, table);

  }


  multiSheet(data, dateRange) {
    const { assignedKpis, brokerKpis, driverKpis, corporateKpis } = data


    let trips = []

    brokerKpis.forEach(trip => {
      trips.push(...trip.jobs)
    });
    const table1 = [
      [
        'Date Range',
        'Total Trips',
        'Completed',
        'Cancelled',
        'Total Receivable'
      ]
    ]

    for (let item of assignedKpis) {
      if (item) {
        let t = [
          moment(dateRange.startDate).format("MM/DD/YYYY") + '-' +  moment(dateRange.endDate).format("MM/DD/YYYY"),
          item.totalJobs,
          item.totalCompletedJobs,
          item.totalCancelledJobs,
          item.totalFee,
        ]
        table1.push(t)
      }
    }


    const table2 = [
      [
        'Broker',
        'Trips',
        'Manual',
        'Completed',
        'Cancelled',
        'Receivable',
        'Total Receivable'
      ]
    ]

    let brokerName;
    for (let item of brokerKpis) {
      if (item) {
        let total = 0;
        let t = [
          getBrokersList[item._id],
          item.totalJobs,
          item.totalManualJobs,
          item.totalCompletedJobs,
          item.totalCancelledJobs,
          item.totalFee,
          total += item.totalFee
        ]
        table2.push(t)
      }

    }
    const table3 = [
      [
        'Corporate',
        'Trips',
        'Completed',
        'Cancelled',
        'Receivable',
        'Total Receivable'
      ]
    ]

    for (let item of corporateKpis) {
      if (item) {
        let total = 0;
        let t = [
          item.earnings[0].cooperate.displayName,
          item.totalJobs,
          item.totalCompletedJobs,
          item.totalCancelledJobs,
          item.totalFee,
          total += item.totalFee
        ]
        table3.push(t)
      }
    }

    const table4 = [
      [
        'Driver',
        'Trips',
        'Completed',
        'Cancelled',
        'Receivable',
        'Total Receivable'
      ]
    ]

    for (let item of driverKpis) {
      let driverName
      if (item) {
        if (item.earnings[0] && item.earnings[0].driver && item.earnings[0].driver.displayName) {
          driverName = item.earnings[0].driver.displayName;
        }
        let total = 0;
        let t = [
          driverName,
          item.totalJobs,
          item.totalCompletedJobs,
          item.totalCancelledJobs,
          item.totalFee,
          total += item.totalFee
        ]
        table4.push(t)
      }


    }

    const table5 = [
      [
        'ID',
        'Member Name',
        'Broker',
        'Status',
        'Miles',
        'Driver',
        'Fare'
      ]
    ]

    for (let item of trips) {
      let driverName;
      if (item) {


        if (item.driver && item.driver.displayName) {
          driverName = item.driver.displayName;
        }
        let t = [
          item.tripId,
          item.priorityClient.displayName,
          getBrokersList[item.companyType],
          item.status,
          item.milage,
          driverName,
          item.jobFee

        ]
        table5.push(t)
      }
    }



    const sheets = {
      "Business Trips Report": table1,
      "BROKER TRIPS": table2,
      "CORPORATE TRIPS": table3,
      "DRIVER TRIPS": table4,
      "TRIPS": table5
    };
    exportToExcel("Business Report.xlsx", sheets);
  }


  multiSheetTimeline(data, dateRange, driver) {
    if(data && driver && dateRange){

    const table1 = [
      [
        'Date Range',
        'Driver Name',
        'Total Working Hours',
        'Total Break Hours',
        'Total Days',
      ]
    ]

    let t = [
      moment(dateRange.startDate).format("MM/DD/YYYY") + '-' + moment(dateRange.endDate).format("MM/DD/YYYY"),
      driver.displayName,
      this.viewHourFormat(data.workTimeInMillis),
      this.viewHourFormat(data.breakTimeInMillis),
      data.totalDays,
    ]
    table1.push(t)


    const table2 = [
      [
        'Date',
        'Time In',
        'Time Out',
        'Break Hours',
        'Working Hours',
      ]
    ]
    for (let item of data.jobs) {
      if (item) {
        let t = [
          moment(item.checkin).format("MM/DD/YYYY") + '-' +  moment(item.checkout).format("MM/DD/YYYY"),
          moment(item.checkin).format("HHmm"),
          moment(item.checkout).format("HHmm"),
          this.viewHourFormat(item.breakTimeInMillis),
          this.viewHourFormat(item.workTimeInMillis),
        ]
        table2.push(t)
      }
    }
    const sheets = {
      "Time In Time Out Report": table1,
      "History": table2,
    };
    exportToExcel("Driver-History.xlsx", sheets);
  }
}

  downloadInvoice(payload, selectedCompany, dateRange, timezone) {
    const table = [
      [
        "Bill To",
        "Company Alias ",
        "Completed Fee",
        "Cancelled Fee",
        "Pending Fee",
        "Discount Label",
        "Discount",
        "Arrears Label",
        "Arrears",
        "Dispatch Month Day",
        "Due Day",
      ]];
      let data = [
        payload.billTo,
        payload.alias,
        payload.completedFee,
        payload.cancelledFee,
        payload.pendingFee,
        payload.discountType,
        payload.discount,
        payload.arrearsType,
        payload.arrearsFee,
        payload.dispatchDate,
        payload.dueDay,
      ]

      table.push(data)

    exportToCsv(`${cleanUpSpaces(selectedCompany.displayName)}-${moment(dateRange[0]).tz(timezone).format("MM/DD/YYYY") + "-" + moment(dateRange[1]).tz(timezone).format("MM/DD/YYYY")}-invoice.csv`, table);
  }



viewHourFormat(milli) {
  let duration = moment.duration(milli, 'milliseconds');
  return `${Math.floor(duration.asHours())}hr ${duration.minutes()}mins`;
}

  getTripStatus(trip) {
    if (trip.jobStatus === "pending")
      return "Unassigned"
    if (trip.jobStatus === "arrived")
      return "On Scene"
    if (trip.jobStatus === "started")
      return "Member Onboard"
    else
      return this.capitalize.transform(trip.jobStatus);
  }


  downloaMTMClaimFileV2(data) {
    const { trips, date, timezone, preFleet } = data

    const table = [
      [
        'ID',
        "Driver",
        "Vehicle",
        "ScheduledPickupTime",

        "ReportedPickupArriveTime",
        "ReportedPickupPerformTime",
        "ScheduledDropoffTime",
        "ReportedDropoffArriveTime",

        "ReportedDropoffPerformTime",
        "TripLogSignature"
      ]];

    trips.forEach((trip) => {

      if (trip.job && trip.job.trackInfoJob) {
        const trackInfo = trip.job.trackInfoJob;
        const fleet = trip.job.fleet ? preFleet[trip.job.fleet] : null;
        let row = [
          trip.tripId,
          trip.driver && trip.driver.driverLicenseNumber ? trip.driver.driverLicenseNumber : '',
          fleet ? fleet.vin : '',
          trip.scheduleTime ? moment(trip.scheduleTime).tz(timezone).format('hh:mm A') : '',

          trackInfo && trackInfo.jobArrived && trackInfo.jobArrived.time ? moment(trackInfo.jobArrived.time).tz(timezone).format('hh:mm A') : '',
          trackInfo && trackInfo.jobStarted && trackInfo.jobStarted.time ? moment(trackInfo.jobStarted.time).tz(timezone).format('hh:mm A') : '',
          trip.appointmentTime ? moment(trip.appointmentTime).tz(timezone).format('hh:mm A') : '',
          trackInfo && trackInfo.jobCompleted && trackInfo.jobCompleted.time ? moment(trackInfo.jobCompleted.time).tz(timezone).format('hh:mm A') : '',
          trackInfo && trackInfo.jobFinished && trackInfo.jobFinished.time ? moment(trackInfo.jobFinished.time).tz(timezone).format('hh:mm A') : '',
          'Y'
        ]
        table.push(row);
      }
    });

    const sheets = {
      "Claim File": table,
    };
    exportToExcel(`MTM-Claim-File"${date}.xlsx`, sheets);
  }

  downloadUberTripsReport(data) {
    const { trips, date, timezone} = data

    const table = [
      [
        'ID',
        'Member Name',
        "Broker",
        "Vehicle",
        "Driver",
        'Pickup Address',
        'Pickup Time',
        'DropOff Address',
        'DropOff Time',
        "Miles",
        "Escorts",
        "Phone Number",
        "Contact Number",
        "Status",
        "BSR",
        'Tip',
        "Fare",
      ]];

    trips.forEach((trip) => {
        let row = [
          trip.tripId,
          trip.priorityClient && trip.priorityClient.displayName ? trip.priorityClient.displayName : '',
          trip.cooperate ? trip.cooperate.displayName : '',
          trip.tripRequirement ? trip.tripRequirement: '',
          trip.driver? trip.driver.displayName:' ',
          trip.jobOriginAddress,
          trip.scheduleTime ? moment(trip.scheduleTime).tz(timezone).format('hh:mm A') : '',
          trip.jobDestinationAddress,
          trip.appointmentTime ? moment(trip.appointmentTime).tz(timezone).format('hh:mm A') : '',
          trip.milage,
          trip.noOfEscorts,
          trip.priorityClient ? trip.priorityClient.cellPhone : ' ',
          trip.priorityClient ? trip.priorityClient.contactNumber : ' ',
          trip.status,
          trip.preSpecialRate,
          trip.job && trip.job.driverFareDetail ?trip.job.driverFareDetail.jobFee : ' ',
          trip.job && trip.job.jobFareDetail ? trip.job.jobFareDetail.jobFee : ' ',
        ]
        table.push(row);
    });

    const sheets = {
      "Trips": table,
    };
    exportToExcel(`uber-trips"${date}.xlsx`, sheets);
  }



}
