import { Injectable ,Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService  {
  private user = new BehaviorSubject(null);
  private subMenus = new BehaviorSubject(null);
  private datePicker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private date: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private smallScreenMenuState: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private nemtCodes = new BehaviorSubject(null);

  constructor() { }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  saveUser(user) {
    this.user.next(user);
  }

  getSubMenus() {
    return this.subMenus.asObservable();
  }

  saveSubMenus(subMenus) {
    this.subMenus.next(subMenus);
  }

  toggleDatePicker(bool){
    this.datePicker.next(bool);
  }

  getDatePickerSate(){
    return this.datePicker.asObservable();
  }

  setDate(date){
    this.date.next(date);
  }

  getDate(){
    return this.date.asObservable();
  }

  getSmallScreenMenuState(): Observable<any> {
    return this.smallScreenMenuState.asObservable();
  }

  setSmallScreenMenuState(b) {
    this.smallScreenMenuState.next(b);
  }

  getNemtCodes(): Observable<any> {
    return this.nemtCodes.asObservable();
  }

  saveNemtCodes(data) {
    this.nemtCodes.next(data);
  }

  generateHmac(url, method, body) {
    let payload = `${method}\n${url}`;
    if (['PUT', 'POST'].includes(method)) {
      payload += `\n${JSON.stringify(body)}`;
    }
    return CryptoJS.HmacSHA256(payload, environment.HMAC_APPLICATION_SECRET).toString(CryptoJS.enc.Hex);
  }
}
