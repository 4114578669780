import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';
import { CorporateAddonServiceService } from '../corporate-addon-service.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-add-corporate-addon',
  templateUrl: './add-corporate-addon.component.html',
  styleUrls: ['./add-corporate-addon.component.css']
})
export class AddCorporateAddonComponent implements OnInit, OnDestroy {
  corporateAddonForm: FormGroup;
  addonServicesForm: FormGroup;
  corporateUsers;
  corporateAddonId;
  submitted = false;
  addonServicesFormSubmitted = false;
  isAddonSubmitted = false;



  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private assignTripService: AssigTripsService,
    private corporateAddonService: CorporateAddonServiceService
  ) { }

  ngOnInit() {
    this.corporateAddonForm = this.fb.group({
      cooperate: [''],
      purpose: ['default'],
      status: ['', Validators.required],
      addonServices: [[]],

    });

    this.addonServicesForm = this.fb.group({
      title: ['', Validators.required],
      fare: ['', Validators.required],
    });

    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.corporateAddonId = params["id"];
    });

    this.getCorporateUserList();
    this.getAddonData()

  }

  onCreateCorporateAddon() {
    this.submitted = true;

    if (this.corporateAddonForm.invalid) {
      console.log('Form Status', this.corporateAddonForm.status)
      return;
    }
    if (this.corporateAddonId) {
      let payload = this.corporateAddonForm.value;
      if (payload.purpose === 'default') {
        delete payload.cooperate;
      }
      this.corporateAddonService.updateCorporateAddon(this.corporateAddonId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // sweetAlert('Success', "Corporate updated  Successfully", 'success', 'OK')
          this.router.navigateByUrl('/fares/corporateaddon')
        }
      }, (err) => {
        sweetAlert("Error", err, "error", "Ok");
        // this.router.navigateByUrl("staffs");
      })
    }
    else {
      const payload = this.corporateAddonForm.value;
      if (payload.purpose === 'default') {
        delete payload.cooperate;
      }
      this.corporateAddonService.addCorporateAddon(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (data.message) {
            sweetAlert("Error", data.message, "error", "Ok");
          }
          else {
            // sweetAlert('Success', "Corporate Addon added Successfully", 'success', 'OK')
            this.router.navigateByUrl('/fares/corporateaddon')
          }

        }
      }, (err) => {
        sweetAlert("Error", err, "error", "Ok");
        // this.router.navigateByUrl("staffs");
      })
    }
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data;
        }
      });

  }

  onDropdownChange(selectedValue: string) {



    if (selectedValue == "") {
      this.corporateAddonForm.patchValue({
        purpose: 'default'
      });

    } else {
      if(!this.corporateAddonId) {

        this.corporateAddonService.getCorporateAddonServices()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          // data.addonServices
          this.corporateAddonForm.patchValue({
            addonServices: data.addonServices
          });
        })
      }
      // -----
      this.corporateAddonForm.patchValue({
        purpose: 'corporate'
      });
    }


  }

  getAddonData() {
    if (this.corporateAddonId) {

      // console.log('Driver id here ==>', this.driverUserId)

      this.corporateAddonService
        .getCorporateAddonListById(this.corporateAddonId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.corporateAddonForm.patchValue({
              ...data,

            });

          }
        });
    }
  }

  addonServices() {
    this.isAddonSubmitted = true;
    this.addonServicesFormSubmitted = true;
    if (this.addonServicesForm.invalid) {
      return;
    }
    this.corporateAddonForm.value.addonServices.push(this.addonServicesForm.value);
    this.addonServicesForm.reset();
    this.addonServicesFormSubmitted = false;
    this.isAddonSubmitted = false;
  }

  removeaddonServices(index) {
    const z1 = this.corporateAddonForm.value.addonServices;
    z1.splice(index, 1);
    this.corporateAddonForm.patchValue({
      addonServices: z1
    });
  }

  get form() {
    return this.corporateAddonForm.controls;
  }

  get formControl(){
    return this.addonServicesForm.controls;
  }

  getAccountStatus() {
    return this.authService.accountStatus;
  }

  ngOnDestroy(): void {
  }

}
