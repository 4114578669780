import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PDFDocument } from "pdf-lib";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { urls } from "../../utils/url-utils";
import { errorHandl } from "../../utils/network-utils";
import { catchError } from "rxjs/operators";
import { AssigTripsService } from "../../component/assigned-trips/assigntrips-service/assigntrips-service";
import { ToastrService } from "ngx-toastr";
import { downloadFile } from "src/app/utils/pdf-downloader";
import {
  getImageAsEmbedNew,
  removeNonAllowedCharactersFromPDF,
  urlToArrayBuffer,
  urlToBase64,
} from "../../utils/utils.common";
import { FleetService } from "../../component/fleet/fleet-service/fleet.service";
import { C } from "@angular/cdk/keycodes";

@Injectable({
  providedIn: "root",
})
export class Cms1500Service {
  constructor(
    private toastr: ToastrService,
    private assignTripService: AssigTripsService,
    private fleetService: FleetService,
    private http: HttpClient
  ) { }

  accountStatus = {
    active: "Active",
    inactive: "InActive",
  };

  async fillPDF(pdfBuffer: ArrayBuffer, data, user): Promise<ArrayBuffer> {
    const { assigns, cms1500, dynamicFare } = data;
    const [legA, legB] = assigns;
    let total = assigns.length;
    let A0420Fare = 0,
      A0424Fare = 0,
      A0428Fare = 0;
    if (cms1500) {
      cms1500.codes.forEach((c) => {
        if (c.title === "A0420") {
          A0420Fare = c.fare;
        } else if (c.title === "A0424") {
          A0424Fare = c.fare;
        } else if (c.title === "A0428") {
          A0428Fare = c.fare;
        }
      });
    }

    if (!dynamicFare) {
      this.toastr.error("Fare not found", "Alert");
      return;
    }

    function splitPhoneNumber(phone: string): {
      areaCode: string;
      number: string;
    } {
      const cleaned = ("" + phone).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return { areaCode: match[1], number: match[2] + "-" + match[3] };
      }
      return { areaCode: "", number: phone };
    }
    function parseAddress(address) {
      const regex = /(.*),\s*([^,]*),\s*([A-Z]{2})\s*(\d{5})?,/;
      const match = address.match(regex);

      if (!match)
        return {
          street: "",
          city: "",
          state: "",
          zip: "",
        };

      return {
        street: match[1] ? match[1].trim() : "",
        city: match[2] ? match[2].trim() : "",
        state: match[3] ? match[3].trim() : "",
        zip: match[4] ? match[4].trim() : "",
      };
    }
    function getTransportationCodes(vehicleType) {
      return {
        code: dynamicFare.baseFareCode || '',
        miles: dynamicFare.perMileCode || '',
        waitTime: dynamicFare.waitTimeCode || '',
        dryRunCode: dynamicFare.dryRunCode || ''
      };
      if (["Standard", "LIV", "AMB", "A"].includes(vehicleType)) {
        return {
          code: "T2001",
          miles: "A0425",
          waitTime: "A0420",
        };
      } else if (["WAV", "W"].includes(vehicleType)) {
        return {
          code: "A0130",
          miles: "A0380",
          waitTime: "A0424",
        };
      } else if (vehicleType === "Stretcher") {
        return {
          code: "T2005",
          miles: "A0380",
          waitTime: "A0424",
        };
      }
      return null;
    }
    function isWithinTimeWindow(scheduleTime, timeZone) {
      const localTime = moment(scheduleTime).tz(timeZone, false);
      const hour = localTime.hour();
      return hour >= 18 || hour < 6;
    }
    const vehicleMap = {
      Standard: "Standard",
      Premium: "Premium",
      SUV: "SUV",
      WAV: "WAV",
      Stretcher: "Stretcher",
      S: "Stretcher",
      BLS: "Stretcher",
      GUR: "Stretcher",
      LIV: "Standard",
      AMB: "Standard",
      Ambulatory: "Standard",
      Wheelchair: "WAV",
      W: "WAV",
      L: "Standard",
      A: "Standard",
      "": "Standard",
      undefined: "Standard",
    };
    const isMidNightCode = isWithinTimeWindow(legA.scheduleTime, user.timeZone);
    const taxiCodes = getTransportationCodes(vehicleMap[legA.tripRequirement]);
    const address = parseAddress(legA.jobOriginAddress);
    const splitted = splitPhoneNumber(legA.priorityClient.contactNumber);
    let responseCode = taxiCodes.code
    let waitTime = taxiCodes.waitTime;
    let waitTimeFare = A0424Fare;
    let midNight = 3;
    let miles = legA.milage || 0;
    let baseFare = dynamicFare.baseFare.fare || 0;
    let perMile = dynamicFare.perMile || 0;
    let totalFare = 0;
    let totalMileFare = 0;
    if (total > 1) {
      miles = miles * total;
      baseFare = baseFare * total;
    }
    if (["Standard", "LIV", "AMB"].includes(legA.tripRequirement)) {
      waitTimeFare = A0420Fare;
      if (total === 1) {
        waitTime = "";
        waitTimeFare = 0;
      }
    }
    totalMileFare = perMile * miles;
    totalFare = waitTimeFare + baseFare + totalMileFare;
    if (legA.status === "cancelled") {
      responseCode = taxiCodes.dryRunCode;
      baseFare = dynamicFare.cancelCharges;
      totalFare = baseFare;
      total = 1;
    }
    const [s_mm, s_dd, s_yy] = moment(legA.scheduleTime)
      .tz(user.timeZone, false)
      .format("MM/DD/YY")
      .split("/");
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    form.getTextField("pt_name").setText(legA.priorityClient.displayName || "");
    const [mm, dd, yy] = legA.dob.split("/");
    form.getTextField("birth_mm").setText(mm || "");
    form.getTextField("birth_dd").setText(dd || "");
    form.getTextField("birth_yy").setText(yy || "");
    form.getTextField("pt_street").setText(address.street || "");
    form.getTextField("pt_city").setText(address.city || "");
    form.getTextField("pt_state").setText(address.state || "");
    form.getTextField("pt_zip").setText(address.zip || "");
    form.getTextField("pt_AreaCode").setText(splitted.areaCode || "");
    form.getTextField("pt_phone").setText(splitted.number || "");
    form.getTextField("ins_policy").setText(legA.ipa || "");
    form.getTextField("ins_dob_mm").setText(mm || "");
    form.getTextField("ins_dob_dd").setText(dd || "");
    form.getTextField("ins_dob_yy").setText(yy || "");
    form
      .getTextField("ins_name")
      .setText(legA.priorityClient.displayName || "");
    form.getTextField("ins_street").setText(address.street || "");
    form.getTextField("ins_city").setText(address.city || "");
    form.getTextField("ins_state").setText(address.state || "");
    form.getTextField("ins_zip").setText(address.zip || "");
    form.getTextField("ins_phone area").setText(splitted.areaCode || "");
    form.getTextField("ins_phone").setText(splitted.number || "");
    form.getTextField("insurance_id").setText(legA.memberId || "");
    form.getTextField("prior_auth").setText(legA.priorityClient.clientId || "");
    form
      .getTextField("pt_date")
      .setText(moment.tz(user.timezone).format("MM/DD/YY") || "");
    form
      .getTextField("physician_date")
      .setText(moment.tz(user.timezone).format("MM/DD/YY") || "");
    form.getTextField("fac_name").setText(user.displayName || "");
    form.getTextField("fac_street").setText(user.address || "");
    form.getTextField("tax_id").setText(user.taxId || "");
    form.getTextField("local1a").setText(user.npi || "");
    form.getTextField("pin").setText(user.npi || "");
    form.getTextField("pin1").setText(user.npi || "");
    form.getTextField("charge").setText(0.0 || "");
    form.getTextField("96").setText("AUTH ON FILE");
    form.getTextField("charge").setText("0.00");
    form.getTextField("diagnosis1").setText(legA.primaryDiagnosis || "");
    form.getTextField("73").setText("431");
    form.getTextField("cur_ill_mm").setText(s_mm || "");
    form.getTextField("cur_ill_dd").setText(s_dd || "");
    form.getTextField("cur_ill_yy").setText(s_yy || "");
    if (legA.gender === "male") {
      form.getCheckBox("sex_male").check();
      form.getCheckBox("ins_sex_male").check();
    }
    if (legA.gender === "female") {
      form.getCheckBox("sex_female").check();
      form.getCheckBox("ins_sex_female").check();
    }

    form.getTextField("sv1_mm_from").setText(s_mm || "");
    form.getTextField("sv1_dd_from").setText(s_dd || "");
    form.getTextField("sv1_yy_from").setText(s_yy || "");
    form.getTextField("sv1_mm_end").setText(s_mm || "");
    form.getTextField("sv1_dd_end").setText(s_dd || "");
    form.getTextField("sv1_yy_end").setText(s_yy || "");
    form.getTextField("place1").setText("41");
    form.getTextField("type1").setText("N");
    form.getTextField("cpt1").setText(responseCode || "");
    form.getTextField("ch1").setText(`${baseFare || ""}`);
    form.getTextField("day1").setText(`${total || ""}`);
    form.getTextField("diag1").setText("A");
    if (legA.status === "cancelled") {
      form.getTextField("mod1").setText("TP");
    }
    if (legA.status !== "cancelled") {
      form.getTextField("sv2_mm_from").setText(s_mm || "");
      form.getTextField("sv2_dd_from").setText(s_dd || "");
      form.getTextField("sv2_yy_from").setText(s_yy || "");
      form.getTextField("sv2_mm_end").setText(s_mm || "");
      form.getTextField("sv2_dd_end").setText(s_dd || "");
      form.getTextField("sv2_yy_end").setText(s_yy || "");
      form.getTextField("place2").setText("41");
      form.getTextField("type2").setText("N");
      form.getTextField("cpt2").setText(taxiCodes.miles || "");
      form.getTextField("diag2").setText("A");
      form.getTextField("ch2").setText(`${totalMileFare.toFixed(2) || "0"}`);
      form.getTextField("day2").setText(`${miles || ""}`);
      if (waitTime) {
        midNight = 4;
        form.getTextField("cpt3").setText(waitTime || "");
        form.getTextField("sv3_mm_from").setText(s_mm || "");
        form.getTextField("sv3_dd_from").setText(s_dd || "");
        form.getTextField("sv3_yy_from").setText(s_yy || "");
        form.getTextField("sv3_mm_end").setText(s_mm || "");
        form.getTextField("sv3_dd_end").setText(s_dd || "");
        form.getTextField("sv3_yy_end").setText(s_yy || "");
        form.getTextField("place3").setText("41");
        form.getTextField("type3").setText("N");
        form.getTextField("diag3").setText("A");
        form.getTextField(`ch3`).setText(`${waitTimeFare}`);
      }
      if (isMidNightCode) {
        form
          .getTextField(`cpt${midNight}`)
          .setText(isMidNightCode ? "A0428" : "" || "");
        form
          .getTextField(`mod${midNight}`)
          .setText(isMidNightCode ? "UJ" : "" || "");
        form.getTextField(`sv${midNight}_mm_from`).setText(s_mm || "");
        form.getTextField(`sv${midNight}_dd_from`).setText(s_dd || "");
        form.getTextField(`sv${midNight}_yy_from`).setText(s_yy || "");
        form.getTextField(`sv${midNight}_mm_end`).setText(s_mm || "");
        form.getTextField(`sv${midNight}_dd_end`).setText(s_dd || "");
        form.getTextField(`sv${midNight}_yy_end`).setText(s_yy || "");
        form.getTextField(`place${midNight}`).setText("41");
        form.getTextField(`type${midNight}`).setText("N");
        form.getTextField(`diag${midNight}`).setText("A");
        form.getTextField(`ch${midNight}`).setText(`${A0428Fare}`);
        totalFare += A0428Fare;
      }
    }
    form.getTextField(`t_charge`).setText(`${totalFare.toFixed(2)}`);

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    return await pdfDoc.save();
  }

  async fillAuthPDF(pdfBuffer: ArrayBuffer, data, user): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;
    form.getTextField("company_name").setText(company.displayName || "");
    form.getTextField("company_phone_one").setText(company.contactNumber || "");
    if (company.focalPerson) {
      form
        .getTextField("company_phone_two")
        .setText(company.focalPerson.contactNumber || "");
    }

    form.getTextField("facility_name").setText(company.displayName || "");
    if (
      (user.company && user.company.focalPerson) ||
      (user && user.focalPerson)
    ) {
      form
        .getTextField("facility_contact_name")
        .setText(
          (user.company && user.company.focalPerson
            ? user.company.focalPerson.displayName
            : user.focalPerson.displayName) || ""
        );
      form
        .getTextField("facility_phone")
        .setText(
          (user.company && user.company.focalPerson
            ? user.company.focalPerson.contactNumber
            : user.focalPerson.contactNumber) || ""
        );
    }

    form
      .getTextField("Date")
      .setText(moment().tz(user.timeZone).format("MM/DD/YY") || "" || "");
    form
      .getTextField("facility_fax")
      .setText((user.company ? user.company.faxNumber : user.faxNumber) || "");
    form.getCheckBox("facility_stay_no").check();
    form.getCheckBox("maryland_medicaid_program_yes").check();

    form
      .getTextField("medicaid_assistance_number")
      .setText(data.priorityClient.clientId || "");

    form
      .getTextField("member_first_name")
      .setText(data.priorityClient.displayName.split(" ")[0] || "");
    form
      .getTextField("member_last_name")
      .setText(
        data.priorityClient.displayName.split(" ")[1]
          ? data.priorityClient.displayName.split(" ")[1]
          : "" || ""
      );
    form
      .getTextField("member_phone")
      .setText(data.priorityClient.contactNumber || "");
    form.getTextField("member_dob").setText(data.dob || "");
    form.getCheckBox("is_amb_no").check();
    form.getCheckBox("facility_transport_no").check();

    let originPhone = data.priorityClient.contactNumber;
    let jobOriginAddress = data.jobOriginAddress;
    let originName = jobOriginAddress;
    jobOriginAddress += " (";
    if (data.originAddressAdditional) {
      if (data.originAddressAdditional.floor) {
        jobOriginAddress += `Floor ${data.originAddressAdditional.floor}`;
      }
      if (data.originAddressAdditional.room) {
        jobOriginAddress += ` Room ${data.originAddressAdditional.room}`;
      }
      if (data.originAddressAdditional.aptOrSuite) {
        jobOriginAddress += ` APT ${data.originAddressAdditional.aptOrSuite}`;
      }

      if (data.originAddressAdditional.place) {
        originName = data.originAddressAdditional.place;
      }

      if (data.originAddressAdditional.phone) {
        originPhone = data.originAddressAdditional.phone;
      }
    }
    jobOriginAddress += ")";
    form
      .getTextField("member_address")
      .setText(jobOriginAddress.replace("()", "") || "");
    form.getTextField("origin_name").setText(originName || "");
    form.getTextField("origin_phone").setText(originPhone || "");
    form
      .getTextField("origin_address")
      .setText(jobOriginAddress.replace("()", "") || "");
    form.getTextField("trip_purpose").setText(data.tripReason || "");
    form
      .getTextField("date_of_service")
      .setText(
        moment(data.scheduleTime).tz(user.timeZone).format("MM/DD/YY") || ""
      );

    if (data.tripRequirement === "Stretcher")
      form.getCheckBox("is_stretcher").check();
    else if (data.tripRequirement === "WAV")
      form.getCheckBox("is_wheelchair").check();

    let destinationPhone = data.priorityClient.contactNumber;
    let jobDestinationAddress = data.jobDestinationAddress;
    let destinationName = jobDestinationAddress;
    jobDestinationAddress += " (";
    if (data.destinationAddressAdditional) {
      if (data.destinationAddressAdditional.floor) {
        jobDestinationAddress += `Floor ${data.destinationAddressAdditional.floor}`;
      }
      if (data.destinationAddressAdditional.room) {
        jobDestinationAddress += ` Room ${data.destinationAddressAdditional.room}`;
      }
      if (data.destinationAddressAdditional.aptOrSuite) {
        jobDestinationAddress += ` APT ${data.destinationAddressAdditional.aptOrSuite}`;
      }

      if (data.destinationAddressAdditional.place) {
        destinationName = data.destinationAddressAdditional.place;
      }

      if (data.destinationAddressAdditional.phone) {
        destinationPhone = data.destinationAddressAdditional.phone;
      }
    }
    jobDestinationAddress += ")";
    form
      .getTextField("destination_address")
      .setText(jobDestinationAddress.replace("()", "") || "");
    form.getTextField("destination_name").setText(destinationName || "");
    form.getTextField("destination_phone").setText(destinationPhone || "");
    form.getTextField("trip_purpose").setText(data.tripReason || "");
    form.getTextField("specialty").setText(data.tripReason || "");
    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    return await pdfDoc.save();
  }

  async fillMedicaidPackage(
    pdfBuffer: ArrayBuffer,
    data,
    user
  ): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    const company = user.company ? user.company : user;
    if (company)
      form.getTextField("company_name").setText(company.displayName || "");
    form
      .getTextField("patient_name")
      .setText(data.priorityClient.displayName || "");
    form
      .getTextField("appt_date")
      .setText(
        moment(data.scheduleTime).tz(user.timeZone).format("MM/DD/YY") || ""
      );
    form
      .getTextField("arrival_time")
      .setText(
        moment(data.scheduleTime).tz(user.timeZone).format("HH:mm") || ""
      );
    if (data.job && data.job.signatureImage) {
      //   // Fetch JPEG image
      const jpgImageBytes: any = await urlToArrayBuffer(
        data.job.signatureImage
      );
      const pngImage = await getImageAsEmbedNew(
        pdfDoc,
        data.job.signatureImage,
        jpgImageBytes
      );
      if (pngImage) form.getTextField("signature").setImage(pngImage);
    }
    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    return await pdfDoc.save();
  }

  async getCmsReport(payload, user) {
    const response = await fetch("assets/documents/form-cms1500.pdf");
    let pdfBuffer = await response.arrayBuffer();

    this.assignTripService.getCMSReport(payload).subscribe(async (data) => {
      if (data) {
        pdfBuffer = await this.fillPDF(pdfBuffer, data, user);
        if (pdfBuffer) {
          const blob = new Blob([pdfBuffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "cms1500.pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    });
  }

  async sendForAuthPDF(data, user) {
    const response = await fetch(
      "assets/documents/auth-form-medicaid-transportation.pdf"
    );
    let pdfBuffer = await response.arrayBuffer();

    pdfBuffer = await this.fillAuthPDF(pdfBuffer, data, user);
    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "req-auth-RFA-" + data.tripId + ".pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async medicaidPackage(data, user) {
    const response = await fetch("assets/documents/wv-verification.pdf");
    let pdfBuffer = await response.arrayBuffer();
    pdfBuffer = await this.fillMedicaidPackage(pdfBuffer, data, user);
    return pdfBuffer;
  }

  async catsInvoice(data, dataRange, user) {
    const response = await fetch("assets/documents/cats-invoice.pdf");
    let pdfBuffer = await response.arrayBuffer();
    pdfBuffer = await this.fillCatsInvoice(pdfBuffer, data, dataRange, user);
    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        "cats-invoice-" +
        moment().tz(user.timeZone).format("MM-DD-YY") +
        ".pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    }
    return pdfBuffer;
  }

  async ahcccsReport(date, trips, user) {
    const sortedTrips = trips.sort((a, b) =>
      moment(a.scheduleTime).diff(moment(b.scheduleTime))
    );
    const response = await fetch("assets/documents/ahcccs-report.pdf");
    let pdfBuffer = await response.arrayBuffer();
    pdfBuffer = await this.fillAhcccsReport(pdfBuffer, sortedTrips, user);
    if (pdfBuffer) {
      const blob = new Blob([pdfBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        date +
        "-ahcccs-report-" +
        sortedTrips[0].priorityClient.displayName +
        ".pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    }
    return pdfBuffer;
  }

  async fillAhcccsReport(pdfBuffer, data, user) {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    // Get Fleet Info on base of id
    let fleet;

    if (data[0] && data[0].driver && data[0].driver.fleet ) {
      fleet = await this.fleetService
        .getFleetDataById(data[0].driver.fleet)
        .toPromise();
    }

    form
      .getTextField("provider_id")
      .setText((user.company ? user.company.npi : user.npi) || "");
    form
      .getTextField("provider_name")
      .setText(
        (user.company ? user.company.displayName : user.displayName) || ""
      );
    form
      .getTextField("provider_address")
      .setText((user.company ? user.company.address : user.address) || "");

    if (
      (user.company && user.company.focalPerson) ||
      (user && user.focalPerson)
    ) {
      form
        .getTextField("provider_phone")
        .setText(
          (user.company
            ? user.company.focalPerson.contactNumber
            : user.focalPerson.contactNumber) || ""
        );
    }

    form.getTextField("driver_name").setText(data[0].driver.displayName || "");
    form
      .getTextField("current_date")
      .setText(
        moment(data[0].appointmentTime).tz(user.timeZone).format("MM/DD/YY") ||
        ""
      );
    form
      .getTextField("vehicle_id")
      .setText(data[0].driver.driverLicenseNumber || "");

    if (fleet) {
      form
        .getTextField("vehicle_make_color")
        .setText(fleet.driverCarColor + fleet.driverCarModel || "");
    }

    form
      .getTextField("ahcccs_name")
      .setText(data[0].priorityClient.clientId || "");
    form.getTextField("dob").setText(data[0].dob || "");
    form
      .getTextField("member_name")
      .setText(data[0].priorityClient.displayName || "");
    form
      .getTextField("mailing_address")
      .setText(data[0].jobOriginAddress || "");

    form
      .getTextField("legA_pu_address")
      .setText(data[0].jobOriginAddress || "");
    form
      .getTextField("legA_do_address")
      .setText(data[0].jobDestinationAddress || "");
    form
      .getTextField("legA_pu_time")
      .setText(
        moment(data[0].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
      );
    form
      .getTextField("legA_do_time")
      .setText(
        moment(data[0].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
        ""
      );
    form
      .getTextField("legA_pu_odometer")
      .setText(data[0].job.odometerStart || "");
    form
      .getTextField("legA_do_odometer")
      .setText(data[0].job.odometerStop || "");
    form
      .getTextField("legA_trip_miles")
      .setText(data[0].jobOriginAddress || "");
    form
      .getTextField("legA_trip_miles")
      .setText(data[0].milage.toFixed(2) || "");

    if (data[0].tripRequirement === "WAV") {
      form.getCheckBox("isWheelChair").check();
    } else if (data[0].tripRequirement === "Stretcher") {
      form.getCheckBox("isStretcher").check();
    } else {
      form.getCheckBox("other").check();
      form.getTextField("otherTypeList").setText(data[0].tripRequirement || "");
    }

    if (data.length == 1) {
      form.getCheckBox("legA_isOneWayTrip").check();
    }

    form.getTextField("legA_reasonForVisit").setText(data[0].tripReason || "");
    form.getTextField("legA_escort_name").setText(data[0].job.escortName || "");
    form
      .getTextField("legA_escort_relationship")
      .setText(data[0].job.escortRelation || "");

    if (data[1]) {
      form
        .getTextField("legB_pu_address")
        .setText(data[1].jobOriginAddress || "");
      form
        .getTextField("legB_do_address")
        .setText(data[1].jobDestinationAddress || "");
      form
        .getTextField("legB_pu_time")
        .setText(
          moment(data[1].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
        );
      form
        .getTextField("legB_do_time")
        .setText(
          moment(data[1].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
          ""
        );
      form
        .getTextField("legB_pu_odometer")
        .setText(data[1].job.odometerStart || "");
      form
        .getTextField("legB_do_odometer")
        .setText(data[1].job.odometerStop || "");
      form
        .getTextField("legB_trip_miles")
        .setText(data[1].jobOriginAddress || "");
      form
        .getTextField("legB_trip_miles")
        .setText(data[1].milage.toFixed(2) || "");
      // form.getCheckBox('legB_isOneWayTrip').check();
      form.getCheckBox("legB_isRoundTrip").check();
      form
        .getTextField("legB_reasonForVisit")
        .setText(data[1].tripReason || "");
      form
        .getTextField("legB_escort_name")
        .setText(data[1].job.escortName || "");
      form
        .getTextField("legB_escort_relationship")
        .setText(data[1].job.escortRelation || "");
    }

    if (data[2]) {
      form
        .getTextField("legC_pu_address")
        .setText(data[2].jobOriginAddress || "");
      form
        .getTextField("legC_do_address")
        .setText(data[2].jobDestinationAddress || "");
      form
        .getTextField("legC_pu_time")
        .setText(
          moment(data[2].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
        );
      form
        .getTextField("legC_do_time")
        .setText(
          moment(data[2].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
          ""
        );
      form
        .getTextField("legC_pu_odometer")
        .setText(data[2].job.odometerStart || "");
      form
        .getTextField("legC_do_odometer")
        .setText(data[2].job.odometerStop || "");
      form
        .getTextField("legC_trip_miles")
        .setText(data[2].jobOriginAddress || "");
      form
        .getTextField("legC_trip_miles")
        .setText(data[2].milage.toFixed(2) || "");
      form.getCheckBox("legC_isOneWayTrip").check();
      form
        .getTextField("legC_reasonForVisit")
        .setText(data[2].tripReason || "");
      form
        .getTextField("legC_escort_name")
        .setText(data[2].job.escortName || "");
      form
        .getTextField("legC_escort_relationship")
        .setText(data[2].job.escortRelation || "");
    }

    if (data[3]) {
      form
        .getTextField("legD_pu_address")
        .setText(data[3].jobOriginAddress || "");
      form
        .getTextField("legD_do_address")
        .setText(data[3].jobDestinationAddress || "");
      form
        .getTextField("legD_pu_time")
        .setText(
          moment(data[3].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
        );
      form
        .getTextField("legD_do_time")
        .setText(
          moment(data[3].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
          ""
        );
      form
        .getTextField("legD_pu_odometer")
        .setText(data[3].job.odometerStart || "");
      form
        .getTextField("legD_do_odometer")
        .setText(data[3].job.odometerStop || "");
      form
        .getTextField("legD_trip_miles")
        .setText(data[3].jobOriginAddress || "");
      form
        .getTextField("legD_trip_miles")
        .setText(data[3].milage.toFixed(2) || "");
      form.getCheckBox("legD_isOneWayTrip").check();
      form
        .getTextField("legD_reasonForVisit")
        .setText(data[3].tripReason || "");
      form
        .getTextField("legD_escort_name")
        .setText(data[3].job.escortName || "");
      form
        .getTextField("legD_escort_relationship")
        .setText(data[3].job.escortRelation || "");
    }

    if (data[4]) {
      form
        .getTextField("legE_pu_address")
        .setText(data[4].jobOriginAddress || "");
      form
        .getTextField("legE_do_address")
        .setText(data[4].jobDestinationAddress || "");
      form
        .getTextField("legE_pu_time")
        .setText(
          moment(data[4].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
        );
      form
        .getTextField("legE_do_time")
        .setText(
          moment(data[4].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
          ""
        );
      form
        .getTextField("legE_pu_odometer")
        .setText(data[4].job.odometerStart || "");
      form
        .getTextField("legE_do_odometer")
        .setText(data[4].job.odometerStop || "");
      form
        .getTextField("legE_trip_miles")
        .setText(data[4].jobOriginAddress || "");
      form
        .getTextField("legE_trip_miles")
        .setText(data[4].milage.toFixed(2) || "");
      form.getCheckBox("legE_isOneWayTrip").check();
      form
        .getTextField("legE_reasonForVisit")
        .setText(data[4].tripReason || "");
      form
        .getTextField("legE_escort_name")
        .setText(data[4].job.escortName || "");
      form
        .getTextField("legE_escort_relationship")
        .setText(data[4].job.escortRelation || "");
    }

    if (data[5]) {
      form
        .getTextField("legF_pu_address")
        .setText(data[5].jobOriginAddress || "");
      form
        .getTextField("legF_do_address")
        .setText(data[5].jobDestinationAddress || "");
      form
        .getTextField("legF_pu_time")
        .setText(
          moment(data[5].scheduleTime).tz(user.timeZone).format("hh:mm a") || ""
        );
      form
        .getTextField("legF_do_time")
        .setText(
          moment(data[5].appointmentTime).tz(user.timeZone).format("hh:mm a") ||
          ""
        );
      form
        .getTextField("legF_pu_odometer")
        .setText(data[5].job.odometerStart || "");
      form
        .getTextField("legF_do_odometer")
        .setText(data[5].job.odometerStop || "");
      form
        .getTextField("legF_trip_miles")
        .setText(data[5].jobOriginAddress || "");
      form
        .getTextField("legF_trip_miles")
        .setText(data[5].milage.toFixed(2) || "");
      form.getCheckBox("legF_isOneWayTrip").check();
      form
        .getTextField("legF_reasonForVisit")
        .setText(data[5].tripReason || "");
      form
        .getTextField("legF_escort_name")
        .setText(data[5].job.escortName || "");
      form
        .getTextField("legF_escort_relationship")
        .setText(data[5].job.escortRelation || "");
    }
    // form.getCheckBox('isadditonal_yes').check()
    // form.getCheckBox('isadditional_no').check()
    // form.getCheckBox('member_unable_signature').check
    // form.getCheckBox("sameVehicleTrip_no").check();
    form.getCheckBox("sameVehicleTrip_yes").check();
    form.getCheckBox("isadditional_no").check();
    form
      .getTextField("date")
      .setText(
        moment(data[0].appointmentTime).tz(user.timeZone).format("MM/DD/YY") ||
        ""
      );

    if (data[data.length - 1].job && data[data.length - 1].job.signatureImage) {
      //   // Fetch JPEG image
      const jpgImageBytes: any = await urlToArrayBuffer(
        data[data.length - 1].job.signatureImage
      );
      const pngImage = await getImageAsEmbedNew(
        pdfDoc,
        data[data.length - 1].job.signatureImage,
        jpgImageBytes
      );
      if (pngImage) form.getTextField("member_signature").setImage(pngImage);
    }

    if (
      data[data.length - 1].driver &&
      data[data.length - 1].driver.driverSignatureImage
    ) {
      //   // Fetch JPEG image
      const jpgImageBytes: any = await urlToArrayBuffer(
        data[data.length - 1].driver.driverSignatureImage
      );
      const pngImage = await getImageAsEmbedNew(
        pdfDoc,
        data[data.length - 1].driver.driverSignatureImage,
        jpgImageBytes
      );
      if (pngImage) form.getTextField("driver_signature").setImage(pngImage);
    }

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    return await pdfDoc.save();
  }

  async fillCatsInvoice(pdfBuffer, data, dataRange, user) {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    form
      .getTextField("company_name")
      .setText(
        (user.company ? user.company.displayName : user.displayName) || ""
      );
    form
      .getTextField("current_date")
      .setText(moment().tz(user.timeZone).format("MM/DD/YY") || "");
    form
      .getTextField("invoice_no")
      .setText("" + Math.floor(Math.random() * 999999));
    form
      .getTextField("company_address")
      .setText((user.company ? user.company.address : user.address) || "");
    form
      .getTextField("company_phone")
      .setText(
        (user.company ? user.company.contactNumber : user.contactNumber) || ""
      );
    form
      .getTextField("company_fax")
      .setText((user.company ? user.company.faxNumber : user.faxNumber) || "");
    form
      .getTextField("period")
      .setText(
        (dataRange && typeof dataRange !== "string"
          ? moment(dataRange[0]).tz(user.timeZone).format("MM/DD/YY") +
          " - " +
          moment(dataRange[1]).tz(user.timeZone).format("MM/DD/YY")
          : moment(dataRange).tz(user.timeZone).format("MM/DD/YY")) || ""
      );
    form.getTextField("trips_count").setText("" + data.totalTrips || "");
    form
      .getTextField("total_amount")
      .setText("$" + data.totalAmount.toFixed(2) || "");
    form
      .getTextField("total_miles")
      .setText("" + data.totalMiles.toFixed(2) || "");
    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    return await pdfDoc.save();
  }

  rnsCorporateInvoice1Report(selectedCorporateUser, payload) {
    this.assignTripService
      .rnsCorporateInvoice1Report(selectedCorporateUser, payload)
      .subscribe((data) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.REPORT_SERVER_URL + "/pdf/" + name;
          downloadFile(url, "", name);
        }
      });
  }

  addCmsCodes(payload, codeId): Observable<any> {
    if (codeId) {
      return this.http.put<any>(
        urls.BASE_URL + urls.CMS_1500_CODES + "/" + codeId,
        payload
      );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.CMS_1500_CODES, payload);
    }
  }

  getCmsCodes(): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.CMS_1500_CODES)
      .pipe(catchError(errorHandl));
  }

  getCmsCodesById(codeId): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.CMS_1500_CODES + "/" + codeId)
      .pipe(catchError(errorHandl));
  }
}
