import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceserviceService } from '../../service/invoiceservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import * as moment from 'moment';
import { urls } from 'src/app/utils/url-utils';
import { downloadFile } from 'src/app/utils/pdf-downloader';



@Component({
  selector: 'app-invoice-setting-list',
  templateUrl: './invoice-setting-list.component.html',
  styleUrls: ['./invoice-setting-list.component.css']
})
export class InvoiceSettingListComponent implements OnInit, OnDestroy {

  submitted = false;
  providerinvoicesettingForm: FormGroup;
  search: any;
  company = [];
  companyId;
  selectedCompany: any;
  invoiceId: any;
  invoiceData;
  user: any;
  timeZone: any;
  todayDate: any = [];
  constructor(
    private fb: FormBuilder,
    private invoiceService: InvoiceserviceService,
    private csvService : CsvService,
    private sharedDataService : SharedDataService
  ) { }

  ngOnInit() {
    this.providerinvoicesettingForm = this.fb.group({
      billTo: ['', Validators.required],
      alias: ['', Validators.required],
      completedFee: ['', Validators.required],
      cancelledFee: ['', Validators.required],
      company: [''],
      pendingFee: ['', Validators.required],
      discountType: ['', Validators.required],
      discount: ['', Validators.required],
      arrearsType: ['', Validators.required],
      arrearsFee: ['', Validators.required],
      dispatchDate: ['', Validators.required],
      dueDay: ['', Validators.required],
      dateRange: [[ moment().startOf("day"), moment().endOf("day")]],
      isCancelledWaived: [false],
      isCompletedWaived: [false],
      isPendingWaived: [false],
    });
    console.log(this.providerinvoicesettingForm.value.dateRange);

    this.getUser();
  }

  get form() {
    return this.providerinvoicesettingForm.controls;
  }

  getUser(){
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = [
            moment.tz(this.timeZone).startOf("day").toISOString(),
            moment.tz(this.timeZone).endOf("day").toISOString(),
          ];

          this.providerinvoicesettingForm.patchValue({
            dateRange: [
              moment.tz(this.timeZone),
              moment.tz(this.timeZone),
            ]
          });

          this.getCompanies();

        }
      });


  }
  getCompanies() {
    this.invoiceService
      .getInvoiceList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.company = data;
          this.providerinvoicesettingForm.patchValue({
            dateRange:[
              moment.tz(this.timeZone),
              moment.tz(this.timeZone),
            ]
          })
         }
      });
  }

  onSelectCompany(company) {
    this.selectedCompany = company;
    this.providerinvoicesettingForm.reset();
    this.providerinvoicesettingForm.patchValue({
      dateRange:[
        moment.tz(this.timeZone),
        moment.tz(this.timeZone),
      ]
    })
    this.companyData();
  }


  companyData() {
    this.invoiceId = null;
    const payload = {
      company: this.selectedCompany._id
    }
    this.invoiceService
      .getCompanyData(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.invoiceData = data
          this.invoiceId = data._id
          this.providerinvoicesettingForm.patchValue({
            ...data
        })
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }


  updateInvoice() {
    this.submitted = true;

    if (this.providerinvoicesettingForm.invalid) {
      return;
    }
    const payload = this.providerinvoicesettingForm.value;
    payload.company = this.selectedCompany._id;
    this.invoiceService
      .saveInvoice(this.invoiceId, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Invoice Updated Successfully', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  downloadInvoice() {
    const payload = {
      "startDate": this.providerinvoicesettingForm.value.dateRange[0].startOf("day").toISOString(),
      "endDate": this.providerinvoicesettingForm.value.dateRange[1].endOf("day").toISOString(),
      "company": this.selectedCompany
    }

    this.invoiceService
    .downloadInvoice(this.invoiceId,payload)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        let name = data.pdfName;
        let url = urls.REPORT_SERVER_URL + 'pdf/' + name;
        downloadFile(url, '', name);
       }
    }, err => {
      console.log(err);
      sweetAlert('Error', err, 'warning', 'OK');
    });
  }

  // onDateChange(event) {
  //   this.todayDate = [
  //     event[0].toISOString(),
  //     event[1].endOf("day").toISOString(),
  //   ];
  // }

  ngOnDestroy(): void {
  }
}
