import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farmout-trips',
  templateUrl: './farmout-trips.component.html',
  styleUrls: ['./farmout-trips.component.css']
})
export class FarmoutTripsComponent implements OnInit {
  reportDetails = [];

  constructor() { }

  ngOnInit() {
  }

}
