import { Component, OnInit, NgZone, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ControlPosition, MapTypeControlOptions, MapsAPILoader, MouseEvent } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PAYOR, getBrokersList, loadStaticMap } from '../../../utils/utils.common';
import { DispatchCenterService } from '../dispatch-center-service/dispatch-center-service';
import { LatLngLiteral } from '@agm/core';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.css']
})
export class TripDetailsComponent implements OnInit, OnDestroy {
  payorList = PAYOR;
  @Input() tripId: any
  @Input() clientId: any
  @Input() corporatesByKey: any
  @Input() openFrom?: any
  @Output() onCloseModal = new EventEmitter<any>();
  @Output() updateJobFee = new EventEmitter<any>();
  pickUpLatitude: number;
  dropOfflatitude: number;
  pickUpLongitude: number;
  dropOffLongitude: number;
  address: string;
  private geoCoder;
  zoom: number;
  jobId;
  currentFareDetails;
  currentFareBreakDown;
  tripDetails = null;
  jobAcceptedUrl = null;
  jobOnSceneUrl = null;
  jobStartedUrl = null;
  jobEndedUrl = null;
  jobFinishedUrl = null;
  jobCancelledUrl = null;
  fareType = 'company';
  viewAllDetail = false;
  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  isDrawRoute = false;
  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };
  assign;
  cameraImages;
  user;
  tripAssistance : {
    displayName: '',
    contactNumber: ''
  };
  isUberIntegeration = false;

  constructor(
    private dispatchCenterService: DispatchCenterService,
    private assigTripsService: AssigTripsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.jobId = this.tripId;
    this.sharedDataService
    .getUser()
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(user => {
      if (user) {
        this.user = user;
        if (user.hasOwnProperty('isUber')) {
          this.isUberIntegeration = user.isUber
        }
        this.getTripDetails(this.jobId);
        this.getBrokerRate(this.jobId);
      }
    });

  }

  getTripDetails(jobId) {
    this.dispatchCenterService
      .getTripDetails(jobId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripDetails = data;
          this.tripAssistance = {
            displayName: this.tripDetails.priorityClient.displayName,
            contactNumber: this.tripDetails.priorityClient.contactNumber
          };
          this.currentFareDetails = this.tripDetails.jobFareDetail;
          this.currentFareBreakDown = this.tripDetails.companyFareFormat;
          this.jobAcceptedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobAccepted.coords[1],
            this.tripDetails.trackInfoJob.jobAccepted.coords[0]);
          this.jobOnSceneUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobArrived.coords[1],
            this.tripDetails.trackInfoJob.jobArrived.coords[0]);
          this.jobStartedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobStarted.coords[1],
            this.tripDetails.trackInfoJob.jobStarted.coords[0]);
          this.jobEndedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobCompleted.coords[1],
            this.tripDetails.trackInfoJob.jobCompleted.coords[0]);
          this.jobFinishedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobFinished.coords[1],
            this.tripDetails.trackInfoJob.jobFinished.coords[0]);
          this.jobCancelledUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobCancelled.coords[1],
            this.tripDetails.trackInfoJob.jobCancelled.coords[0]);

          this.origin = {
            lat: this.tripDetails.jobOriginLatitude,
            lng: this.tripDetails.jobOriginLongitude
          };
          this.destination = {
            lat: this.tripDetails.jobDestinationLatitude,
            lng: this.tripDetails.jobDestinationLongitude
          };
        }
      });
  }

  getBrokerRate(jobId) {
    this.dispatchCenterService
      .getBrokerRate(jobId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.assign = data;
          this.getUploadedCameraImages(jobId,this.assign._id);
        }
      });
  }

  getBroker(key) {
    // console.log(key)
    return getBrokersList[key];
  }
  getUploadedCameraImages(jobId, assignId) {
    this.dispatchCenterService
      .getCameraImages(jobId, assignId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.cameraImages = data;
          console.log("images check", this.cameraImages)
        }
      });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          sweetAlert('Alert', 'No results found', 'warning', 'OK')
        }
      } else {
        sweetAlert('Alert', `Geocoder failed due to: ${status}`, 'warning', 'Ok')
      }

    });
  }

  sortMethod(a, b) {
    return a.value.sort > b.value.sort ? 1 : -1;
  }

  selectFor(fareType) {
    this.fareType = fareType;
    this.currentFareDetails = this.tripDetails.jobFareDetail;
    this.currentFareBreakDown = this.tripDetails.companyFareFormat;
    if (fareType === 'driver') {
      this.currentFareDetails = this.tripDetails.driverFareDetail;
      this.currentFareBreakDown = this.tripDetails.driverFareFormat;
    }
  }

  onViewAllDetail() {
    this.viewAllDetail = !this.viewAllDetail;
  }

  updateDriverFare() {

    // -----------Update--------------
    sweetAlert('Alert', 'Are you sure you want to update', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          const payload = { driverFareDetail: this.tripDetails.driverFareDetail };
          this.dispatchCenterService
            .saveDriverFare(this.tripDetails._id, payload)
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe(data => {
              if (data) {
                this.updateJobFee.emit({tripId: this.tripId ,jobFee: data.driverFareDetail.jobFee});
              }
            });
        }
      })
    // -----------Update--------------


  }

  twodigit(val) {
    return parseFloat(parseFloat(val).toFixed(2));
  }


  closeDetailModal() {
    this.onCloseModal.emit();
  }

  breakString(str) {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }

  getPayorTypeTitle(corporateId, payorType){
    if(!this.corporatesByKey) return;

    let payor;
    let types = this.corporatesByKey[corporateId];
    if (types && types.length) {
      payor = types.find(t => t.value === payorType);
    }
    return payor ? payor.title: '';
}

isStringExist(substrings, str) {
  if (substrings.some(v => str.toLowerCase().includes(v))) {
    return true;
  }
  return false;
}

  sendSMS() {
    if (!this.tripAssistance.displayName || !this.tripAssistance.contactNumber) return;
    this.assigTripsService
      .sendTripAssistanceSMS(this.assign._id, this.tripAssistance)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (data.success) {
            sweetAlert('Success', data.message +' '+data.link, 'success', 'OK');
          } else {
            sweetAlert('Error', data.message, 'error', 'OK');
          }
        }
      });
  }

  ngOnDestroy(): void {
  }
}
