import { Injectable } from '@angular/core';
import { Menu, SubMenu } from './menu-models';

export enum UserRoles {
  ADMIN = 'admin',
  COMPANY = 'company',
  ACCOUNTANT = 'accountant',
  CREDENTIAL = 'credential',
  FULLACCESS = 'company',
  DISPATCHER = 'dispatcher',

  DISPATCHER_MNGR = 'mdispatcher',
  CALL_CENTER_REP = 'rcallcenter',
  CALL_CENTER_MNGR = 'mcallcenter',
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menus = {};
  userRole;

  constructor() { }

  addMenu(menu: Menu): void {
    if (this.canView(menu.roles)) {
      this.menus[menu.key] = menu;
    }
  }

  getAssignedSubMenu(role){
   let subMenus = [
      {
        name: "All",
        value: "all",
        roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "Unassigned",
        value: "unassigned",
        roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "Assigned",
        value: "hanging",
        roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "In Progress",
        value: "inprogress",
        roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "Cancelled",
        value: "cancelled",
        roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "Archived",
        value: "archived",
        roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      },
      {
        name: "Completed",
        value: "completed",
        roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
      }
    ];
    // console.log("menu Roles", role)
    let roleSpecificMenus= [];

    for (let i = 0; i < subMenus.length; i++) {
      // console.log("menu Roles 1", subMenus[i].roles , role )
      if (subMenus[i].roles.includes(role)) {
        roleSpecificMenus.push(subMenus[i]);
    }
    }
    // console.log("menu Roles", roleSpecificMenus)
    return roleSpecificMenus;
  }


  addSubMenu(key: string, subMenu: SubMenu) {
    if ((this.menus[key] && this.canView(subMenu.roles))) {
      const subMenus = this.menus[key].subMenus;
      if (!subMenus) {
        this.menus[key].subMenus = [];
      }
      this.menus[key].subMenus.push(subMenu);
    }
  }

  getMenu(userRole): object {
    this.menus = {};
    this.userRole = userRole;
    this.addMenus();
    return this.menus;
  }

  addMenus() {
    // Dispatch Center
    this.addMenu({
      title: "Dashboard",
      url: "dashboard",
      icon: this.getIcon("home"),
      key: "dashboard",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR],
    });

    // this.addSubMenu('dashboard', {
    //   title: "Dispatch Center",
    //   url: "dashboard",
    //   position: 1,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    // });

    // this.addSubMenu('home', {
    //   title: "Timeline Load",
    //   url: "timeline",
    //   position: 2,
    //   roles: [UserRoles.COMPANY]
    // });

    // operations
    this.addMenu({
      title: "Operations",
      url: "operations",
      icon: this.getIcon("operations"),
      key: "operations",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR],
    });

    // Trips
    this.addMenu({
      title: "Trips",
      url: "trips",
      icon: this.getIcon("trips"),
      key: "trips",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP],
    });

    this.addSubMenu('trips', {
      title: "Trips",
      url: "trips",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    this.addSubMenu('trips', {
      title: "Offered",
      url: "trips/offered",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    this.addSubMenu('trips', {
      title: "Drivers Load",
      url: "trips/drivers-load",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    // this.addSubMenu('trips', {
    //   title: "Drivers Load V2",
    //   url: "trips/drivers-load-v2",
    //   position: 3.1,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    // });

    this.addSubMenu('trips', {
      title: "Standing Orders",
      url: "trips/standing-orders",
      position: 4,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });
    this.addSubMenu('trips', {
      title: "Farm Out",
      url: "trips/farmout",
      position: 4,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    // this.addSubMenu('trips', {
    //   title: "Standing Orders Old",
    //   url: "trips/standing-orders-old",
    //   position: 4,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    // });

    this.addSubMenu('trips', {
      title: "Routing",
      url: "trips/grouping",
      position: 5,
      roles: [UserRoles.COMPANY]
    });

    // this.addSubMenu('trips', {
    //   title: "Manual Route",
    //   url: "trips/manual",
    //   position: 6,
    //   roles: [UserRoles.COMPANY]
    // });

    this.addSubMenu('trips', {
      title: "Trip Sheet",
      url: "trips/trip-sheet",
      position: 7,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    this.addSubMenu('trips', {
      title: "Dirt Road",
      url: "trips/dirt-road",
      position: 8,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    this.addSubMenu('trips', {
      title: "New Trip",
      url: "trips/new",
      position: 9,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP]
    });

    // Trips
    this.addMenu({
      title: "Manifest",
      url: "manifest",
      icon: this.getIcon("manifest"),
      key: "manifest",
      position: 3.1,
      roles: [UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR, UserRoles.CALL_CENTER_REP, UserRoles.DISPATCHER],
    });

    // Credentails
    this.addMenu({
      title: "Credentialing",
      url: "credentialing",
      icon: this.getIcon("credentialing"),
      key: "credentialing",
      position: 4,
      roles: [UserRoles.ADMIN, UserRoles.COMPANY , UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR],
    });

    this.addSubMenu('credentialing', {
      title: "Drivers",
      url: "credentialing/drivers",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('credentialing', {
      title: "Fleet",
      url: "credentialing/fleet",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('credentialing', {
      title: "Staff",
      url: "credentialing/staff",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER, UserRoles.DISPATCHER_MNGR]
    });

    // this.addSubMenu('credentialing', {
    //   title: "Members",
    //   url: "credentialing/members",
    //   position: 4,
    //   roles: [UserRoles.COMPANY]
    // });

    this.addSubMenu('credentialing', {
      title: "Corporate Users",
      url: "credentialing/corporates",
      position: 5,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('credentialing', {
      title: "Service Areas",
      url: "credentialing/service-areas",
      position: 5.1,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('credentialing', {
      title: "Companies",
      url: "credentialing/companies",
      position: 4,
      roles: [UserRoles.ADMIN]
    });

    this.addSubMenu('credentialing', {
      title: "Quarters",
      url: "credentialing/quarterpanels",
      position: 5,
      roles: [UserRoles.ADMIN]
    });

    this.addSubMenu("credentialing", {
      title: "LOS",
      url: "credentialing/levelofservice",
      position: 5,
      // roles: [UserRoles.COMPANY, UserRoles.FULLACCESS]
      roles: [UserRoles.ADMIN ]
    });

    //Time Sheet

    this.addMenu({
      title: "Timesheet",
      url: "timelines",
      icon: this.getIcon("timelines"),
      key: "timelines",
      position: 4.1,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR],
    });

    this.addSubMenu('timelines', {
      title: "Dashboard",
      url: "timelines/dashboard",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('timelines', {
      title: "Timesheet",
      url: "timelines/time-sheet",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    this.addSubMenu('timelines', {
      title: "Amendments",
      url: "timelines/amendments",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR]
    });

    // Fares
    this.addMenu({
      title: "Fares",
      url: "fares",
      icon: this.getIcon("fares"),
      key: "fares",
      position: 5,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT , UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR],
    });

    this.addSubMenu('fares', {
      title: "Fares",
      url: "fares",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('fares', {
      title: "Distributions",
      url: "fares/distributions",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('fares', {
      title: "Corporate Addons",
      url: "fares/corporateaddon",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    });
    this.addSubMenu('fares', {
      title: "Trip Purposes",
      url: "fares/trip-purposes",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('fares', {
      title: "CMS 1500 Codes",
      url: "fares/cmscodes",
      position: 4,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.CALL_CENTER_MNGR]
    });


    // Billing
    this.addMenu({
      title: "Billing",
      url: "billing",
      icon: this.getIcon("fares"),
      key: "billing",
      position: 5.1,
      roles: [UserRoles.COMPANY , UserRoles.ACCOUNTANT, UserRoles.FULLACCESS],
    });


    this.addSubMenu('billing', {
      title: "TRFs & Claims",
      url: "billing/adjusted-trips",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    });

    this.addSubMenu('billing', {
      title: "TRFs Templates",
      url: "billing/trfs-templates",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    });
    this.addSubMenu('billing', {
      title: "Primary Diagnosis",
      url: "billing/primary-diagnosis",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    });
    this.addSubMenu('billing', {
      title: "Modifier Codes",
      url: "billing/modifier-codes",
      position: 4,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    });

    // this.addSubMenu('billing', {
    //   title: "Adjustments",
    //   url: "billing/adjustments",
    //   position: 2,
    //   roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.ACCOUNTANT]
    // });

    // Maintenance
    this.addMenu({
      title: "Maintenance",
      url: "maintenances",
      icon: this.getIcon("maintain"),
      key: "maintenances",
      position: 6.2,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR],
    });

    this.addSubMenu('maintenances', {
      title: "Driver Expense",
      url: "maintenances/driver-expense",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('maintenances', {
      title: "Vehicle Expense",
      url: "maintenances/vehicle-expense",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('maintenances', {
      title: "Statistics",
      url: "maintenances/maintenance-stats",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    // Reports
    this.addMenu({
      title: "Reports",
      url: "reports",
      icon: this.getIcon("reports"),
      key: "reports",
      position: 6,
      roles: [UserRoles.COMPANY, UserRoles.ADMIN, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR],
    });

    this.addSubMenu('reports', {
      title: "Earnings",
      url: "reports/earnings",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('reports', {

      title: "Business Reports",
      url: "reports/nemtreport",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('reports', {
      title: "Statistics",
      url: "reports/statistics",
      position: 2,
      roles: [UserRoles.COMPANY, UserRoles.ACCOUNTANT, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('reports', {
      title: "Weekly Invoice",
      url: "reports/driver-invoice",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('reports', {
      title: "Vehicle Inspection",
      url: "reports/vehicle-inspection",
      position: 3,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
    });

    // this.addSubMenu('reports', {
    //   title: "Daily  V.Inspection",
    //   url: "reports/daily-vehicle-inspection",
    //   position:4,
    //   roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
    // });

    this.addSubMenu('reports', {
      title: "Accidental Reports",
      url: "reports/accidental-report",
      position:5,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
    });

    this.addSubMenu('reports', {
      title: "Grievance / Item Lost",
      url: "reports/drivergrievance",
      position:5,
      roles: [UserRoles.COMPANY, UserRoles.CREDENTIAL, UserRoles.FULLACCESS]
    });

    this.addSubMenu('reports', {
      title: "Driver Score",
      url: "reports/driver-score",
      position: 5.1,
      roles: [UserRoles.COMPANY, UserRoles.FULLACCESS, UserRoles.DISPATCHER_MNGR, UserRoles.CALL_CENTER_MNGR]
    });

    this.addSubMenu('reports', {
      title: "Reports",
      url: "reports/superadmin/nemtreport",
      position: 5,
      roles: [UserRoles.ADMIN]
    });

    this.addSubMenu('reports', {
      title: "Earnings",
      url: "reports/superadmin/earning",
      position: 6,
      roles: [UserRoles.ADMIN]
    });

    // this.addMenu({
    //   title: "Quarters",
    //   url: "quarterpanels",
    //   icon: this.getIcon("quarters"),
    //   key: "quarters",
    //   position: 7,
    //   roles: [UserRoles.ADMIN],
    // });

    this.addMenu({
      title: "Invoice",
      url: "providerinvoicesettings",
      icon: this.getIcon("invoices"),
      key: "invoices",
      position: 8,
      roles: [UserRoles.ADMIN],
    });

    // this.addSubMenu('reports', {
    //   title: "Driver Breakdown",
    //   url: "reports/breakdown",
    //   position: 3,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER]
    // });

    // Support
    this.addMenu({
      title: "Support",
      url: "driveralerts",
      icon: this.getIcon("support"),
      key: "driveralerts",
      position: 7,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER],
    });

    // this.addSubMenu('driveralerts', {
    //   title: "Queries & Support",
    //   url: "support/queries",
    //   position: 1,
    //   roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    // });
    this.addSubMenu('driveralerts', {
      title: "Driver Alerts",
      url: "driveralerts",
      position: 1,
      roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
    });

        // guide
        this.addMenu({
          title: "Manual",
          url: "manual",
          icon: this.getIcon("help"),
          key: "manual",
          position: 7,
          roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS],
        });

        this.addSubMenu('manual', {
          title: "Helping Material",
          url: "manual/helpingmaterial",
          position: 1,
          roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
        });

        this.addSubMenu('manual', {
          title: "Nemt Pro Tips",
          url: "manual/nemtprotips",
          position: 2,
          roles: [UserRoles.COMPANY, UserRoles.DISPATCHER, UserRoles.FULLACCESS]
        });

        this.addMenu({
          title: "Pro Tips",
          url: "nemtprotips",
          icon: this.getIcon("protips"),
          key: "protips",
          position: 9,
          roles: [UserRoles.ADMIN],
        });
  }

  getIcon(menu) {
    switch (menu) {
      case "manifest":
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
      </svg>
      `;
      case "home":
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M6 19v1q0 .425-.287.712Q5.425 21 5 21H4q-.425 0-.712-.288Q3 20.425 3 20v-8l2.1-6q.15-.45.538-.725Q6.025 5 6.5 5h11q.475 0 .863.275q.387.275.537.725l2.1 6v8q0 .425-.288.712Q20.425 21 20 21h-1q-.425 0-.712-.288Q18 20.425 18 20v-1Zm-.2-9h12.4l-1.05-3H6.85ZM5 12v5Zm2.5 4q.625 0 1.062-.438Q9 15.125 9 14.5t-.438-1.062Q8.125 13 7.5 13t-1.062.438Q6 13.875 6 14.5t.438 1.062Q6.875 16 7.5 16Zm9 0q.625 0 1.062-.438Q18 15.125 18 14.5t-.438-1.062Q17.125 13 16.5 13t-1.062.438Q15 13.875 15 14.5t.438 1.062Q15.875 16 16.5 16ZM5 17h14v-5H5Z"
          />
        </svg>`
        case 'help':
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          >
          <path fill="currentColor"
          d="M14 8.775q0-.225.163-.463T14.524 8q.725-.25 1.45-.375T17.5 7.5q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113T17.5 9q-.65 0-1.275.125t-1.2.325q-.45.175-.737-.025T14 8.775Zm0 5.5q0-.225.163-.463t.362-.312q.725-.25 1.45-.375T17.5 13q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113T17.5 14.5q-.65 0-1.275.113t-1.2.312q-.45.175-.738-.013T14 14.276Zm0-2.75q0-.225.163-.463t.362-.312q.725-.25 1.45-.375t1.525-.125q.5 0 .988.063t.962.162q.225.05.388.25t.162.45q0 .425-.275.625t-.7.1q-.35-.075-.737-.113t-.788-.037q-.65 0-1.275.125t-1.2.325q-.45.175-.737-.025t-.288-.65ZM6.5 16q1.175 0 2.288.263T11 17.05V7.2q-1.025-.6-2.175-.9T6.5 6q-.9 0-1.788.175T3 6.7v9.9q.875-.3 1.738-.45T6.5 16Zm6.5 1.05q1.1-.525 2.212-.788T17.5 16q.9 0 1.763.15T21 16.6V6.7q-.825-.35-1.713-.525T17.5 6q-1.175 0-2.325.3T13 7.2v9.85Zm-6-5.4Zm5 7.825q-.35 0-.663-.088t-.587-.237q-.975-.575-2.05-.862T6.5 18q-1.05 0-2.063.275T2.5 19.05q-.525.275-1.012-.025T1 18.15V6.1q0-.275.138-.525T1.55 5.2q1.15-.6 2.4-.9T6.5 4q1.45 0 2.838.375T12 5.5q1.275-.75 2.663-1.125T17.5 4q1.3 0 2.55.3t2.4.9q.275.125.413.375T23 6.1v12.05q0 .575-.487.875t-1.013.025q-.925-.5-1.937-.775T17.5 18q-1.125 0-2.2.288t-2.05.862q-.275.15-.588.238t-.662.087Z"/></svg>`
         case 'protips':
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
           d="M10 18v-.107c0-.795-.496-1.488-1.117-1.984a5 5 0 1 1 6.235 0c-.622.497-1.118 1.189-1.118 1.984V18m-4 0v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2m-4 0h4m6-6h1M4 12H3m9-8V3m5.657 3.343l.707-.707m-12.02.707l-.708-.707M12 15v-2"/></svg>`
        case 'operations':
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
        <path
          fill="currentColor"
          d="M24 21h2v5h-2zm-4-5h2v10h-2zm-9 10a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z"
        />
        <path
          fill="currentColor"
          d="M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm0 9H14V4h14ZM12 4v7H4V4ZM4 28V13h24l.002 15Z"
        />
      </svg>`
      case 'trips':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M14.844 20H6.5C5.121 20 4 18.879 4 17.5S5.121 15 6.5 15h7c1.93 0 3.5-1.57 3.5-3.5S15.43 8 13.5 8H8.639a9.812 9.812 0 0 1-1.354 2H13.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-7C4.019 13 2 15.019 2 17.5S4.019 22 6.5 22h9.593a10.415 10.415 0 0 1-1.249-2zM5 2C3.346 2 2 3.346 2 5c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5 6.5z"
        />
        <path
          fill="currentColor"
          d="M19 14c-1.654 0-3 1.346-3 3c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19 18.5z"
        />
      </svg>`
      case 'credentialing':
        return `<svg class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32">
        <path fill="currentColor" d="M16 22a4 4 0 1 0-4-4a4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2zM14 6h4v2h-4z" />
        <path fill="currentColor"
          d="M24 2H8a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h16a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm-4 26h-8v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Zm2 0v-2a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2H8V4h16v24Z" />
      </svg>`
      case 'timelines':
        return `<svg class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>`
      case 'maintain':
        return `<svg xmlns="http://www.w3.org/2000/svg"
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round" stroke-width="1.5"
        d="M11.42 15.17L17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"/>
        </svg>`
      case 'fares':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
        />
      </svg>`
      case 'reports':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
        />
      </svg>`
      case 'quarters':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke-width="1.5"
         stroke="currentColor"
         >
        <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
        />
      </svg>`
      case 'invoices':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48 48"><g fill="none"
         stroke="currentColor"
         stroke-width="4"
         >
         <path
         stroke-linecap="round"
         stroke-linejoin="round"
         d="M24 6H9a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-5m-18 8v8m-10 0h20"/><circle cx="37" cy="13" r="3"/><path stroke-linecap="round" stroke-linejoin="round" d="M37 20v-4m0-6V6m-6.062 10.5l3.464-2m5.196-3l3.464-2m-12.124 0l3.464 2m5.196 3l3.464 2"
         /></g>
         </svg>`
      case 'support':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
        />
      </svg>`
      default:
        return '<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>';
    }
  }

  canView(menuRoles) {
    if (Array.isArray(this.userRole)) {
      // In case if user has multiple roles assigned to them.
      // Logic will be added here if needed.
      return false;
    }

    return menuRoles.includes(this.userRole);
  }

  getUserRoleTitle(role) {
    let title = '';
    if (role === UserRoles.DISPATCHER_MNGR) {
      title = "Dispatcher Manager";
    } else if (role === UserRoles.CALL_CENTER_MNGR) {
      title = "Call Center Manager";
    } else if (role === UserRoles.CALL_CENTER_REP) {
      title = "Call Center Representative";
    } else if (role === UserRoles.FULLACCESS) {
      title = "Full Access";
    } else {
      title = role;
    }

    return title;
  }
}
