import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { urls } from "../../../utils/url-utils";
import { errorHandl } from "../../../utils/network-utils";
import { element } from "protractor";

@Injectable({
  providedIn: "root",
})
export class AssigTripsService {
  constructor(private http: HttpClient) {}

  getAssignList(endPoint): Observable<any> {
    let params = new HttpParams();

    if (endPoint.isShowAllTrips)
      params = params.append("isShowAllTrips", endPoint.isShowAllTrips);

    params = params.append("status", endPoint.status);

    if (endPoint.skip) params = params.append("skip", endPoint.skip);

    if (endPoint.date) {
      params = params.append("scheduleTime", endPoint.date);
    }

    if (endPoint.jobStatuses) {
      params = params.append("jobStatuses", endPoint.jobStatuses);
    }

    if (endPoint.scheduleTime) {
      params = params.append("scheduleTime", endPoint.scheduleTime);
    }

    return this.http
      .get<any>(urls.BASE_URL + `${urls.GET_UNASSIGN_TRIPS_URL}`, { params })
      .pipe(catchError(errorHandl));
  }

  getAssignManifestList(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append("isShowAllTrips", endPoint.isShowAllTrips);
    params = params.append("status", endPoint.status);
    params = params.append("isRecurring", endPoint.isRecurring);

    if (endPoint.skip) params = params.append("skip", endPoint.skip);

    if (endPoint.date) {
      params = params.append("scheduleTime", endPoint.date);
    }

    if (endPoint.jobStatuses) {
      params = params.append("jobStatuses", endPoint.jobStatuses);
    }

    if (endPoint.status === "associatestandingorder") {
      params = params.append("so", endPoint.so);
    }

    return this.http
      .get<any>(
        urls.BASE_URL + `${urls.GET_UNASSIGN_TRIPS_URL}/getmanifesttrips`,
        { params }
      )
      .pipe(catchError(errorHandl));
  }
  getOutstandingTrips(payload): Observable<any> {
    // let params = new HttpParams();
    // params = params.append('driver', endPoint.driver);
    // params = params.append('scheduleTime', endPoint.scheduleTime);

    // return this.http.get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
    //   .pipe(
    //     catchError(errorHandl)
    //   );

    return this.http
      .post<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, payload)
      .pipe(catchError(errorHandl));
  }

  getBulkWriteTrips(payload): Observable<any> {
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_BULKWRITE_TRIPS_URL, payload)
      .pipe(catchError(errorHandl));
  }

  getStandingOrders(): Observable<any> {
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, {})
      .pipe(catchError(errorHandl));
  }

  getOutstandingTripsForBucket(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append("scheduleTime", payload.scheduleTime);

    return this.http
      .get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  getBulkwriteTripsForBucket(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append("scheduleTime", payload.scheduleTime);

    return this.http
      .get<any>(urls.BASE_URL + urls.GET_BULKWRITE_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  getUnparsedTrips(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append("rawId", endPoint.rawId);

    return this.http
      .get<any>(urls.BASE_URL + urls.GET_UNPARSED_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  getSweepTripsList(): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL)
      .pipe(catchError(errorHandl));
  }

  removeSweepTrips(data): Observable<any> {
    return this.http
      .post<any>(
        urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL + "?assignId=" + data._id,
        data
      )
      .pipe(catchError(errorHandl));
  }

  sendTripListEmail(data): Observable<any> {
    return this.http
      .put<any>(urls.BASE_URL + urls.SEND_TRIP_LIST_EMAIL_URL + data._id, data)
      .pipe(catchError(errorHandl));
  }

  calculateFare(id): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tempbrokerfare/${id}`
      )
      .pipe(catchError(errorHandl));
  }

  cloneTrip(id): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/clonetrip/${id}`
      )
      .pipe(catchError(errorHandl));
  }
  removeTrip(data, id): Observable<any> {
    return this.http
      .post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, data)
      .pipe(catchError(errorHandl));
  }
  UpdateTripStatusWithNgrx(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(catchError(errorHandl));
  }

  sendToMarketplace(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(catchError(errorHandl));
  }

  updateAssociateImg(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(catchError(errorHandl));
  }

  updateCompTripImg(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .post<any>(urls.BASE_URL + urls.GET_COMPLETED_TRIPS_URL, payload)
      .pipe(catchError(errorHandl));
  }

  markCompletedTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .post<any>(
        urls.BASE_URL + urls.MARK_COMPLETED_TRIP + `?assignId=${id}`,
        payload
      )
      .pipe(catchError(errorHandl));
  }

  addSpecialRate(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(catchError(errorHandl));
  }

  getAssignTripsList(requestData): Observable<any> {
    return this.http
      .post<any>(urls.BASE_URL + urls.GET_ASSIGNED_TRIPS_LIST, requestData)
      .pipe(catchError(errorHandl));
  }

  getMemberTrips(requestData): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL +
          urls.GET_ASSIGNED_TRIPS_LIST +
          "?displayName=" +
          requestData.displayName
      )
      .pipe(catchError(errorHandl));
  }

  getCorporateUserList(): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL +
          urls.GET_CORPORATE_USER_LIST +
          "?accountStatus=" +
          "active"
      )
      .pipe(catchError(errorHandl));
  }

  getCorporateAddonsList(id): Observable<any> {
    let subUrl;

    if (id) {
      subUrl = "cooperate=" + id;
    } else {
      subUrl = "isdefault=true";
    }

    return this.http
      .get<any>(urls.BASE_URL + "addonservices/corporate?" + subUrl)
      .pipe(catchError(errorHandl));
  }

  getTripsForAssignDashboard(): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.TRIPS_FOR_ASSIGN_DASHBOARD_URL)
      .pipe(catchError(errorHandl));
  }

  assignTrip(data): Observable<any> {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`,
        data
      )
      .pipe(catchError(errorHandl));
  }

  assignMultipleTrip(data): Observable<any> {
    return this.http
      .post<any>(urls.BASE_URL + urls.ASSIGN_MULTIPLE_TRIPS_URL, data)
      .pipe(catchError(errorHandl));
  }

  addBillerNote(data): Observable<any> {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`,
        data
      )
      .pipe(catchError(errorHandl));
  }

  addAdditionalNote(data): Observable<any> {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`,
        data
      )
      .pipe(catchError(errorHandl));
  }

  updateTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http
      .put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(catchError(errorHandl));
  }

  verifiedCancelTrip(data, id) {
    return this.http
      .post<any>(urls.BASE_URL + urls.VERIFIED_CANCEL_TRIP_URL + `/${id}`, data)
      .pipe(catchError(errorHandl));
  }

  createNewTrip(tripData, tripId?): Observable<any> {
    const normalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'memberId', 'tripId', 'dob'];
    const lcpDispatchRemoveKeys = ['cooperate'];
    const medicalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance'];
    const corporateDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance'];

    if (tripData.jobType === "assigned") {
      if (tripData.companyType) {
        if (tripData.companyType === "lcp") {
          lcpDispatchRemoveKeys.forEach((element) => delete tripData[element]);
        } else {
          medicalDispatchRemoveKeys.forEach(
            (element) => delete tripData[element]
          );
        }
      } else {
        normalDispatchRemoveKeys.forEach((element) => delete tripData[element]);
      }

      if (!tripData.cooperate || tripData.cooperate) {
        if (!tripId || tripData.cooperate === "") {
          delete tripData.cooperate;
        }
      }
    } else if (tripData.jobType === "cooperate") {
      corporateDispatchRemoveKeys.forEach(
        (element) => delete tripData[element]
      );
    }

    if (tripData.tripLegs) {
      tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length);
      if (tripData.tripLegs.length === 0) {
        delete tripData["tripLegs"];
      } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
        for (let i = 0; i < tripData.tripLegs.length; i++) {
          delete tripData.tripLegs[i].tripId;
        }
      }
    }

    // if (tripId) {
    if (tripData.companyNote) {
      let c = tripData.companyNote.split("<|>");
      if (c.length) {
        tripData.companyNote = c[0];
      }
    }
    if (tripData.companyNote) {
      tripData.companyNote += ` <|> \n`;
    } else {
      tripData.companyNote = ` <|> \n`;
    }
    // added adition

    if (tripData.addonServices && tripData.addonServices.length) {
      tripData.companyNote += `\nAddon Services`;
      for (let addons of tripData.addonServices) {
        tripData.companyNote +=
          (tripData.companyNote.length ? `\n` : "") +
          `${addons.title} * ${addons.quantity}`;
      }
      tripData.companyNote += `\n`;
    }

    if (tripData.originAddressAdditional) {
      if (
        tripData.originAddressAdditional.floor &&
        tripData.originAddressAdditional.floor.length
      ) {
        tripData.companyNote +=
          (tripData.companyNote.length ? `\n` : "") +
          `Origin Address Additional\n`;
        tripData.companyNote += `Floor :  ${tripData.originAddressAdditional.floor} \n`;
      }

      if (
        tripData.originAddressAdditional.room &&
        tripData.originAddressAdditional.room.length
      ) {
        tripData.companyNote += `Room :  ${tripData.originAddressAdditional.room} \n`;
      }

      if (
        tripData.originAddressAdditional.aptOrSuite &&
        tripData.originAddressAdditional.aptOrSuite.length
      ) {
        tripData.companyNote += `Apt or Suite :  ${tripData.originAddressAdditional.aptOrSuite} \n`;
      }
      if (
        tripData.originAddressAdditional.phone &&
        tripData.originAddressAdditional.phone.length
      ) {
        tripData.companyNote += `Phone Number:  ${tripData.originAddressAdditional.phone} \n`;
      }
      if (
        tripData.originAddressAdditional.place &&
        tripData.originAddressAdditional.place.length
      ) {
        tripData.companyNote += `Home/Facility Name:  ${tripData.originAddressAdditional.place} \n`;
      }
    }

    if (tripData.destinationAddressAdditional) {
      if (
        tripData.destinationAddressAdditional.floor &&
        tripData.destinationAddressAdditional.floor.length
      ) {
        tripData.companyNote +=
          (tripData.companyNote.length ? `\n` : "") +
          `Destination Address Additional\n`;
        tripData.companyNote += `Floor :  ${tripData.destinationAddressAdditional.floor} \n`;
      }

      if (
        tripData.destinationAddressAdditional.room &&
        tripData.destinationAddressAdditional.room.length
      ) {
        tripData.companyNote += `Room :  ${tripData.destinationAddressAdditional.room} \n`;
      }

      if (
        tripData.destinationAddressAdditional.aptOrSuite &&
        tripData.destinationAddressAdditional.aptOrSuite.length
      ) {
        tripData.companyNote += `Apt or Suite :  ${tripData.destinationAddressAdditional.aptOrSuite} \n`;
      }

      if (
        tripData.destinationAddressAdditional.phone &&
        tripData.destinationAddressAdditional.phone.length
      ) {
        tripData.companyNote += `Phone Number:  ${tripData.destinationAddressAdditional.phone} \n`;
      }
      if (
        tripData.destinationAddressAdditional.place &&
        tripData.destinationAddressAdditional.place.length
      ) {
        tripData.companyNote += `Home/Facility Name:  ${tripData.destinationAddressAdditional.place} \n`;
      }

    }

    if (
      tripData.weight
    ) {
      tripData.companyNote += `Weight:  ${tripData.weight} \n`;
    }

    if (tripData.tripLegs && tripData.tripLegs.length) {
      tripData.tripLegs.forEach((leg) => {
        if (leg.companyNote) {
          let c = leg.companyNote.split(" <|>");
          if (c.length) {
            leg.companyNote = c[0];
          }
        }
        if (leg.companyNote) {
          leg.companyNote += ` <|> \n`;
        } else {
          leg.companyNote = ` <|> \n`;
        }
        // added adition

        if (tripData.addonServices) {
          leg.companyNote += `\nAddon Services`;
          for (let addons of tripData.addonServices) {
            leg.companyNote +=
              (leg.companyNote.length ? `\n` : "") +
              `${addons.title} * ${addons.quantity} \n`;
          }
          leg.companyNote += `\n`;
        }

        if (leg.originAddressAdditional) {
          if (
            leg.originAddressAdditional.floor &&
            leg.originAddressAdditional.floor.length
          ) {
            leg.companyNote +=
              (leg.companyNote.length ? `\n` : "") +
              `Origin Address Additional\n`;
            leg.companyNote += `Floor :  ${leg.originAddressAdditional.floor} \n`;
          }

          if (leg.originAddressAdditional.room && leg.originAddressAdditional.room.length) {
            leg.companyNote += `Room :  ${leg.originAddressAdditional.room} \n`
          }

          if (leg.originAddressAdditional.aptOrSuite && leg.originAddressAdditional.aptOrSuite.length) {
            leg.companyNote += `Apt or Suite :  ${leg.originAddressAdditional.aptOrSuite} \n`
          }

        }

        if (leg.destinationAddressAdditional) {

          if (leg.destinationAddressAdditional.floor && leg.destinationAddressAdditional.floor.length) {
            leg.companyNote += (leg.companyNote.length ? `\n` : '') + `Destination Address Additional\n`
            leg.companyNote += `Floor :  ${leg.destinationAddressAdditional.floor} \n`
          }

          if (leg.destinationAddressAdditional.room && leg.destinationAddressAdditional.room.length) {
            leg.companyNote += `Room :  ${leg.destinationAddressAdditional.room} \n`
          }

          if (leg.destinationAddressAdditional.aptOrSuite && leg.destinationAddressAdditional.aptOrSuite.length) {
            leg.companyNote += `Apt or Suite :  ${leg.destinationAddressAdditional.aptOrSuite} \n`
          }

        }

      });

    }
    if (tripId) {
      return this.http
        .put<any>(
          urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + tripId,
          tripData
        )
        .pipe(catchError(errorHandl));
    } else {
      return this.http
        .post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL, tripData)
        .pipe(catchError(errorHandl));
    }
  }

  IsoStringConversion(tripData) {
    const dates = {
      scheduleTime: ["schedulePickupHour", "schedulePickupMinutes"],
      appointmentTime: ["appointmentPickupHour", "appointmentPickupMinutes"],
      returnScheduleTime: ["returnPickupHour", "returnPickupMinutes"],
    };
    Object.keys(dates).forEach((key) => {
      let date = tripData[key].split("T")[0];
      tripData[key] = date + "T";
      dates[key].forEach((element) => {
        tripData[key] = tripData[key] + tripData[element] + ":";
      });
      tripData[key] = tripData[key] + "00.000Z";
    });
    return tripData;
  }

  getUnassignedTripDetailById(unassignedTripId): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + unassignedTripId
      )
      .pipe(catchError(errorHandl));
  }

  getAdjustedTrips(queryParams): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  getCompletedTrips(payload): Observable<any> {
    return this.http
      .post<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + "/getmanifesttrips",
        payload
      )
      .pipe(catchError(errorHandl));
  }

  adjustCompletedTrips(payload): Observable<any> {
    return this.http
      .post<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + "/getmanifesttrips",
        payload
      )
      .pipe(catchError(errorHandl));
  }

  getAssignTripLogsFile(payload, endPoint): Observable<any> {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.CREATE_NEW_TRIP_URL + `/${endPoint}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  // downlodFile(url, name): Observable<Blob> {
  //     return this.http.get<any>(urls.BASE_URL + url , {responseType: 'blob'})
  //         .pipe(
  //             catchError(errorHandl)
  //         );
  // }

  generateSignature(payload, endPoint): Observable<any> {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GENRATE_TRIP_SIGNATURE_URL + `/${endPoint}`,
        payload
      )
      .pipe(catchError(errorHandl));
  }

  updateAllTripsSignature(payload): Observable<any> {
    return this.http
      .post<any>(urls.BASE_URL + urls.UPDATE_ALL_TRIPS_SIGNATURE_URL, payload)
      .pipe(catchError(errorHandl));
  }

  bankedTrips(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.BANKED_TRIP_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getETA(tripId) {
    return this.http
      .get<any>(urls.BASE_URL + urls.SINGLE_TRIP + "/" + tripId)
      .pipe(catchError(errorHandl));
  }

  getDirtRoadTrips(params) {
    return this.http.get(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, params);
  }

  sendTripAssistanceSMS(id,payload): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.GET_TRIP_ASSISTANCE_LIST+'/asssign/'+id, payload)
      .pipe(
        catchError(errorHandl)
      );
  }
  sendTrackingSMS(payload): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.TRACKING_SMS_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsReport(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsIntineraryReport(payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendConfirmationText(payload) {
    return this.http
      .post<any>(urls.BASE_URL + urls.SEND_CONFIRMATION_TEXT_URL, payload)
      .pipe(catchError(errorHandl));
  }

  sendMemberVerificationText(payload) {
    return this.http
      .post<any>(urls.BASE_URL + urls.SEND_CONFIRMATION_TEXT_URL, payload)
      .pipe(catchError(errorHandl));
  }

  autoTripAdjustment(payload) {
    return this.http
      .post<any>(urls.BASE_URL + urls.AUTO_TRIP_ADJUSTMENT_URL, payload)
      .pipe(catchError(errorHandl));
  }

  adjustTripOdometer(payload) {
    return this.http
      .post<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(catchError(errorHandl));
  }

  adjustAllTripsOdometer(payload) {
    return this.http
      .put<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(catchError(errorHandl));
  }

  donwloadCallTheCarInvoice(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/callthecar', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadSoutheastransManifest(driverId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/manifest/' + driverId, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLogisticareManifest(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/logisticmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLcpManifest(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/lcpmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAmeraManifest(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/ameramanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoiceReport(corporateUserId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoice(corporateUserId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMedtransDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/nmntrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmTrf(driverId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmClaim(payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/claimfile', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadTNCClaim(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/tncarrierclaims', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAllDriverLog(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + '/mtm/alldriverlogs', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  billedToModivcare(payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.BILLED_TO_MODIVCARE_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripActivityLog(assignId){
    return this.http
    .get<any>(urls.BASE_URL + urls.TRIP_ACTIVITY_LOG + "?assignId=" + assignId) .pipe(
      catchError(errorHandl)
    );
}

  vapremiumDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/vapremium/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  access2CareDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/access2care/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  veyoDriverLog(driverId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/access2care/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  tripsLog(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  memberTripsLog(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/membertripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  memberTripsLogHHT(payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/membertripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  logistiCareTriplogs(driverId, payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/manifest/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  signatureUpdate(payload) {
    return this.http
      .post<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/autosignature`,
        payload
      )
      .pipe(catchError(errorHandl));
  }

  bankedTrip(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/banked`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  claimFile(payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/claimfile`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  cameraTripslog(payload) {
    return this.http.put<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tripslog`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  corporateAeroInvoice(corporateId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/corporate/aero/${corporateId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  rnsCorporateInvoice1Report(corporateId, payload) {
    return this.http.post<any>(urls.BASE_REPORT_SERVER_URL + urls.GET_UNASSIGN_TRIPS_URL + `/corporate/rns/${corporateId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripAssistanceList(): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.GET_TRIP_ASSISTANCE_LIST)
      .pipe(catchError(errorHandl));
  }

  processTripAssistance(id, payload) {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GET_TRIP_ASSISTANCE_LIST + `/${id}`,
        payload
      )
      .pipe(catchError(errorHandl));
  }

  cloneStandingOrderTrip(id, queryParams): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });

    return this.http
      .get<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/clonetrip/${id}`,
        { params }
      )
      .pipe(catchError(errorHandl));
  }

  getCMSReport(params): Observable<any> {
    return this.http.post<any>(
      urls.BASE_URL + urls.GET_CMS_1500_REPORT,
      params
    );
  }

  getNemtCodes() {
    return this.http
      .get<any>(urls.BASE_URL + urls.GET_NEMT_CODES)
      .pipe(catchError(errorHandl));
  }

  sendDriverAcknowledgementText(payload) {
    return this.http
      .post<any>(urls.BASE_URL + urls.SEND_DRIVER_MANIFEST, payload)
      .pipe(catchError(errorHandl));
  }

  fetchManifestTrips(payload): Observable<any> {
    return this.http
      .put<any>(
        urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + "/getmanifesttrips",
        payload
      )
      .pipe(catchError(errorHandl));
  }

  tripDetail(id): Observable<any> {
    return this.http
      .get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, id)
      .pipe(catchError(errorHandl));
  }

}
