import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { PAYOR, exportToCsv, getBrokersList } from '../../../utils/utils.common';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DispatchCenterService } from '../../dispatch-center/dispatch-center-service/dispatch-center-service';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { urls } from 'src/app/utils/url-utils';
import { MenuService } from '../../shared/layout/menu-service/menu.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Cms1500Service } from 'src/app/service/cms-service/cms1500.service';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../reports/reports-service/report.service';
import { FilterByKeyPipe } from 'src/app/pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { FilterPipe } from 'src/app/pipe/list-filter-pipe/list-filter-pipe';
import { environment } from 'src/environments/environment';
import { ServiceAreasService } from '../../service-areas/service-areas-service/service-areas.service';
import { FarmoutService } from 'src/app/service/farmout-service/farmout.service';

declare var $;
@Component({
  selector: "app-unassigned",
  templateUrl: "./unassigned.component.html",
  styleUrls: ["./unassigned.component.css"],
})
export class UnassignedComponent implements OnInit, OnDestroy {
  @ViewChild("timer", { static: false }) timer;
  payorList = PAYOR;
  duration = 10 * 1000; // 10 seconds
  dataList = [];
  subMenus = [];
  filterdataList = [];
  todayDate;
  status = "";
  isShowAllTrips = false;
  showSlideFilter = false;
  isTimerEnabled = false;
  search: any;
  live;
  selectedBroker = "";
  selectedPayor = "";
  assignModalObj;
  componentType;
  tripIndex = -1;
  sweepTripsList = null;
  timeZone;
  settingInterval;
  secondaryCompType = null;
  specialRate;
  user;
  cancelTripModalObj;
  selectedTrip;
  billerNoteModalObj;
  additionalNoteModalObj;
  selectedTrips = [];
  isMedical = false;
  isCorporate = false;
  isMarketplace = false;
  orderBy = "scheduleTime";
  toggleActionButtons: boolean = false;
  view = "grid";
  selectedDropdownIndex: any;
  tripDetailModalObj;
  completedTripModalObj;
  skip = 0;
  editTripModalObj;
  selectedTripId;
  isAllTripsSelected: boolean = false;
  clientId;
  vehicles;
  brokers;
  payors;
  selectedVehicle = '';
  drivers;
  selectedDriver = "";
  cooperates;
  selectedCooperate = "";
  isShowCms1500 = false;
  associateImageModalObj;
  partialEditModalObj;
  tripPurposesAndAddons;
  selectedTripPurpose = '';
  isUberModal = false;
  isAdvanceMode = false;
  uberDetails;
  isUberIntegeration = false;
  addServiceAreaModalObj;
  serviceAreas
  preServiceAreas;
  isProduction = environment.production;
  tripLogObj;

  constructor(
    private reportService: ReportService,
    private cms1500Service: Cms1500Service,
    private assignTripService: AssigTripsService,
    private dispatchCenterService: DispatchCenterService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private menuService: MenuService,
    private csvService: CsvService,
    private toastr: ToastrService,
    private serviceAreasService: ServiceAreasService,
    private farmoutService: FarmoutService

  ) { }

  ngOnInit() {
    // if (this.activatedRoute.snapshot.url.length === 2) {
    //   this.status = this.activatedRoute.snapshot.url[1].path;
    // }
    window.addEventListener("keyup", this.initializeTimer);
    this.getUser();
    this.listenSocket();
    this.getServiceAreas();
  }

  initializeTimer = (e) => {
    if (
      e.altKey &&
      (e.key.toLowerCase() === "v" || e.key.toLowerCase() === "√")
    ) {
      this.isAdvanceMode = !this.isAdvanceMode;
    }
  };

  getServiceAreas() {
    this.serviceAreasService
      .getServiceAreas({status: 'active'})
      .subscribe(data => {
        if (data && data.length) {
          this.serviceAreas = data;
          this.preServiceAreas = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }

  setTripStatus(status, isSkipCheck?) {
    if (!isSkipCheck && this.status === status) return;
    this.skip = 0;
    this.clearTrips();
    this.selectedDropdownIndex = -1;
    this.status = status;
    this.getTrips();
    this.reset();
  }

  reset() {
    // this.search = "";
    // this.isMarketplace = false;
    // this.isMedical = false;
    // this.isCorporate = false;
    // this.selectedBroker = '';
    // this.selectedPayor = "";
    this.selectedTrips = [];
    this.orderBy = "scheduleTime";
    // this.selectedVehicle = "";
    // this.selectedDriver = "";
    // this.selectedCooperate = "";
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          if(data.hasOwnProperty('isUber')) {
            this.isUberIntegeration = data.isUber
          }
          // this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.todayDate = [
            moment.tz(this.timeZone).startOf("day").toISOString(),
            moment.tz(this.timeZone).endOf("day").toISOString(),
          ];
          this.getStaffRoles();
          if (this.user.hasOwnProperty("isListView") && this.user.isListView) {
            this.view = "list";
          }

          if (this.user.hasOwnProperty("isShowCMS1500")) {
            this.isShowCms1500 = this.user.isShowCMS1500;
          }
        }
      });
  }

  getTrips() {
    // this.filterdataList = [];
    // this.dataList = [];
    const object = {
      isShowAllTrips: this.isShowAllTrips,
      status: this.status,
      date: this.todayDate,
      skip: this.skip,
    };
    // this.store.dispatch(new GetUnassignedList(object));
    this.assignTripService
      .getAssignList(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (this.skip === 0) {
            this.clearTrips();
          }
          if (data && object.status === this.status) {
            // this.selectedBroker = '';
            // this.search = '';
            this.dataList = [...data, ...this.dataList];
            this.filterdataList = this.dataList;
            this.group(true);

            // console.log('TEST100:Arrays:Drivers', this.drivers);
            // console.log('TEST100:Arrays:Vehicles', this.vehicles);


            if (data.length) {
              this.skip += data.length;
              this.getTrips();
            } else {
              this.skip = 0;
              if (this.timer && this.status === 'unassigned') {
                if (this.isTimerEnabled)
                  this.timer.start();
              }
              this.drivers = [];
              this.vehicles = [];
              this.cooperates = [];
              this.brokers = [];
              this.payors = [];
              this.tripPurposesAndAddons = [];
              this.dataList.forEach(trip => {
                if (trip.driver) {
                  this.drivers.push({ _id: trip.driver._id, displayName: trip.driver.displayName });
                }
                if (trip.cooperate) {
                  this.cooperates.push({ _id: trip.cooperate._id, displayName: trip.cooperate.displayName });
                }
                this.vehicles.push(trip.tripRequirement);

                if (trip.jobType !== 'cooperate' && trip.companyType) {
                  this.brokers.push(trip.companyType);
                }
                if (trip.payor) {
                  this.payors.push(trip.payor);
                }

                if (trip.tripReason) {
                  // remove the item having empty string
                  const reasons = trip.tripReason.split(',').filter((reason) => reason !== '');
                  if (reasons && reasons.length) {
                    this.tripPurposesAndAddons.push(...reasons);
                  }
                }

                if (trip.addonServices && trip.addonServices.length) {
                  for (let addon of trip.addonServices){
                    this.tripPurposesAndAddons.push(addon.title);
                 }
                }
              });

              if (this.selectedBroker)
                this.brokers.push(this.selectedBroker)

              if (this.selectedPayor)
                this.payors.push(this.selectedPayor)

              if (this.selectedVehicle)
                this.vehicles.push(this.selectedVehicle)

              if (this.selectedTripPurpose)
                this.vehicles.push(this.selectedTripPurpose)

              // if (this.selectedDriver)
              //   this.brokers.push(this.selectedDriver)

              // if (this.selectedCooperate)
              //   this.brokers.push(this.selectedCooperate)


              this.vehicles = [...new Set(this.vehicles)].sort();
              this.drivers = _.uniq(this.drivers, '_id').sort();
              this.cooperates = _.uniq(this.cooperates, '_id').sort();
              this.brokers = [...new Set(this.brokers)].sort();
              this.payors = [...new Set(this.payors)].sort();
              this.tripPurposesAndAddons = [...new Set(this.tripPurposesAndAddons)].sort();
              // if (this.selectedBroker) {
              //   const brokerType = this.brokers.some(broker => broker === this.selectedBroker);
              //   if(!brokerType)
              //   this.selectedBroker = '';
              // }
              // if(this.selectedPayor){
              //   const payorType = this.payors.some(payor => payor === this.selectedPayor);
              //   if(!payorType)
              //   this.selectedPayor = '';
              // }
              if (this.status === 'unassigned')
                this.selectedDriver = "";
            }
          } else if (this.timer && this.status === 'unassigned') {
            if (this.isTimerEnabled)
              this.timer.start();
          }
          this.filterTripsByModel();
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
        });
  }

  clearTrips() {
    this.skip = 0;
    this.filterdataList = [];
    this.dataList = [];
  }

  listenSocket() {
    this.socketService
      .addListener(Events.DROP_TRIP)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          // sweetAlert('Success', data.message, 'success', 'OK');
          const indexOfDataList = _.findIndex(this.dataList, {
            _id: data.assignId,
          });

          if (indexOfDataList > -1) this.dataList.splice(indexOfDataList, 1);

          const indexOfFilterdataList = _.findIndex(this.filterdataList, {
            _id: data.assignId,
          });
          if (indexOfFilterdataList > -1)
            this.filterdataList.splice(indexOfFilterdataList, 1);
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });

    this.socketService
      .addListener(Events.UPDATE_ASSIGN_TRIP)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          // sweetAlert('Success', data.message, 'success', 'OK');
          this.dataList.filter((item) => {
            if (data.assign._id === item._id) {
              item.isReadyForPickup = data.assign.isReadyForPickup;
              item.isVipTrip = data.assign.isVipTrip;
              item.isConfirmedTrip = data.assign.isConfirmedTrip;
              item.isVoiceMail = data.assign.isVoiceMail;
              item.bankStatus = data.assign.bankStatus;
              item.isMethadone = data.assign.isMethadone;

              item.notAnswer = data.assign.notAnswer;
              item.badPhoneNumber = data.assign.badPhoneNumber;
              item.isDialysis = data.assign.isDialysis;
              item.isDeadMiles = data.assign.isDeadMiles;
            }
          });
          this.filterdataList.filter((item) => {
            if (data.assign._id === item._id) {
              item.isReadyForPickup = data.assign.isReadyForPickup;
              item.isVipTrip = data.assign.isVipTrip;
              item.isConfirmedTrip = data.assign.isConfirmedTrip;
              item.isVoiceMail = data.assign.isVoiceMail;
              item.bankStatus = data.assign.bankStatus;
              item.isMethadone = data.assign.isMethadone;

              item.notAnswer = data.assign.notAnswer;
              item.badPhoneNumber = data.assign.badPhoneNumber;
              item.isDialysis = data.assign.isDialysis;
              item.isDeadMiles = data.assign.isDeadMiles;
            }
          });
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });

    this.socketService
      .addListener(Events.GET_DRIVER_ESTIMATED_FARE)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data.success) {
          const fares = data.fares;

          var message = `Company Fare: $${fares.brokerRate || fares.customerSpecialRate
            } and Driver Fare: $${fares.driverRate || 0}`;

          sweetAlert(
            "Alert",
            `Are you sure you want to update ${message}?`,
            "warning",
            "Yes",
            "No"
          ).then((result: any) => {
            if (result.value) {
              // sweetAlert('Success', 'Fare Calculated Successfully', 'success', 'OK')
              const payload = {
                driverFareDetail: { jobFee: fares.driverRate },
                jobFareDetail: {
                  jobFee: fares.brokerRate || fares.customerSpecialRate,
                },
              };
              this.updateDriverFare(payload);
            }
          });
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });

    this.socketService
      .addListener(Events.GET_ASSIGN_TRIP_ETA)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        // console.log(data);
        // console.log("error message check", this.filterdataList)
        // console.log("error message data check", data)
        // console.log("error message data length check", this.filterdataList.length)
        if (data.success) {
          if (this.filterdataList && this.filterdataList.length) {
            const selectedTrip = this.filterdataList.find(
              (x) => x._id === this.selectedTripId
            );
            if (selectedTrip) {
              selectedTrip.loadedMessage = data.message;
              // console.log("error message loadedMessage check", selectedTrip.loadedMessage)
            }
          }

          this.selectedTripId = null;
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });
  }

  onDateChange(event) {
    this.reset();
    this.skip = 0;
    this.clearTrips();
    // this.todayDate = event.toISOString();
    this.todayDate = [
      event[0].toISOString(),
      event[1].endOf("day").toISOString(),
    ];
    this.getTrips();
  }

  setTripFilter(tripType) {
    // if (tripType === "marketplace") {
    //   if (this.isMarketplace) this.isMarketplace = false;
    //   else this.isMarketplace = true;
    // } else if (tripType === "medical") {
    //   if (this.isMedical) this.isMedical = false;
    //   else this.isMedical = true;
    // } else if (tripType === "corporate") {
    //   if (this.isCorporate) this.isCorporate = false;
    //   else this.isCorporate = true;
    // }

    this.filterTripsByModel();

    // this.filterdataList = this.dataList.filter(trip => {
    //   let checks = [];
    //   let v = true;

    //   if (this.isMarketplace) {
    //     trip.jobStatus === 'marketplaced' ? checks.push(true) : checks.push(false);
    //   }

    //   if (this.isMedical) {
    //     trip.isMedical ? checks.push(true) : checks.push(false);
    //   }

    //   if (this.isCorporate) {
    //     trip.jobType === 'cooperate' ? checks.push(true) : checks.push(false)
    //   }

    //   checks.forEach(c => {
    //     if (!c) {
    //       v = false;
    //       return;
    //     }
    //   });

    //   return v;
    // });
    // this.showFilter()
  }

  claimFile(file) {
    let selectedTripIds;

    if (!this.selectedTrips.length)
      selectedTripIds = this.dataList.map((trip) => trip._id);
    else
      selectedTripIds = this.getSelectedTrips().map((trip) => trip._id)

    let payload = {
      companyType: this.selectedBroker,
      // tempVin: vm.tempVin,
      status: this.status,
      _ids: selectedTripIds,
      scheduleTime: this.todayDate,
      // companyImage: vm.companyImage
    };
    if (!payload.companyType) {
      delete payload.companyType;
    }

    // if (file === "generateExcelFile") {
    //   this.assignTripService
    //     .generateTripsReport(payload)
    //     .pipe(takeUntil(componentDestroyed(this)))
    //     .subscribe((data) => {
    //       if (data) {
    //         let name = data.excelName;
    //         let url = urls.REPORT_SERVER_URL + "/excel/" + name;
    //         downloadFile(url, "", name);
    //       }
    //     });
    // } else
    if (file === "generateItineraryFile") {
      this.assignTripService
        .generateTripsIntineraryReport(payload)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
          if (data) {
            let name = data.excelName;
            let url = urls.REPORT_SERVER_URL + "/excel/" + name;
            downloadFile(url, "", name);
          }
        });
    }
  }
  getSelectedTrips() {
    return this.dataList
      .filter((trip) => trip.isSelected);

  }

  group(isNotFromButton?) {
    if (this.filterdataList && this.filterdataList.length) {
      if (!isNotFromButton) {
        this.orderBy =
          this.orderBy === "scheduleTime"
            ? "priorityClient.displayName"
            : "scheduleTime";
      }
      if (this.orderBy === "priorityClient.displayName") {
        this.filterdataList.sort((a, b) =>
          a.priorityClient.displayName.localeCompare(
            b.priorityClient.displayName
          )
        ); // ASC
      } else {
        this.filterdataList.sort(
          (a, b) =>
            <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
        );
      }
    }
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  showActionButtons(idx) {
    this.selectedDropdownIndex = idx;
    this.toggleActionButtons = true;
  }

  closeActionMenu(evt) {
    this.selectedDropdownIndex = -1;
    this.toggleActionButtons = false;
  }

  setPartialEditModal(trip) {
    this.partialEditModalObj = trip;
  }

  setModalContent(trips, type, ind?) {
    if (trips)
      this.assignModalObj = trips;
    else
      this.assignModalObj = this.getSelectedTrips().map((trip) => trip._id);

    this.secondaryCompType = null;
    if (ind >= 0) {
      this.tripIndex = ind;
    }
    if (type === "companyNote") {
      this.componentType = type;
    } else if (type === "dsr") {
      this.specialRate = JSON.parse(JSON.stringify(this.assignModalObj));
      this.componentType = type;
    } else if (type === "Multiple Assign") {
      this.componentType = type;
      this.secondaryCompType = this.status;
    } else {
      if (this.status === "hanging") {
        this.componentType = "hanging";
      } else if (this.status === "cancelled") {
        this.componentType = "cancelled";
      } else if (this.status === "archived") {
        this.componentType = "archived";
      } else if (this.status === "all") {
        this.componentType = "all";
      } else {
        this.componentType = type;
      }
    }
  }

  markTripPaid(assign) {
    sweetAlert(
      "Alert",
      "Are you sure you want to mark this trip paid?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        let params = {
          assignId: assign._id,
        };
        params["bankStatus"] = "paid";
        this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
      }
    });
  }

  tripAssignedResponse(tripStatus: string): void {
    // if (tripStatus === 'Trip Assigned' || tripStatus === 'Trip Un Assigned' || tripStatus === 'All Trips Assigned') {
    //   // sweetAlert('Success', tripStatus, 'success', 'OK');
    // } else if (tripStatus !== null) {
    //   sweetAlert('Error', tripStatus, 'error', 'OK');
    // }
    this.selectedTrips = [];
    this.assignModalObj = null;
    // this.clearTrips();
    this.skip = 0;
    this.getTrips();
  }

  onCloseModal(evt) {
    // console.log('Close Assign Modal', evt);
    // if (evt) {
    this.assignModalObj = null;
    // }
  }

  verifiedCancelled(trip, ind) {
    sweetAlert(
      "Alert",
      "Are you sure you want to mark this trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        this.tripIndex = ind;
        const data = {
          isVerifiedCancelled: !trip.isVerifiedCancelled,
        };
        // this.store.dispatch(new VerifiedCancelledTrip(data, trip._id));
        this.assignTripService
          .verifiedCancelTrip(data, trip._id)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe(
            (data) => {
              if (data) {
                let tempInd = -1;
                _.find(this.dataList, (item, i) => {
                  if (item._id === this.filterdataList[this.tripIndex]._id) {
                    tempInd = i;
                    return i;
                  }
                });
                this.filterdataList[this.tripIndex].isVerifiedCancelled =
                  !this.filterdataList[this.tripIndex].isVerifiedCancelled;
                // this.dataList[tempInd].isVerifiedCancelled = !this.dataList[tempInd].isVerifiedCancelled;
                tempInd = -1;
              }
              this.tripIndex = -1;
            },
            (err) => {
              this.tripIndex = -1;
            }
          );
      }
    });
  }

  // Select/Un-Select All & Select Single Trip
  onSelectTrip(trip) {
    const index = this.selectedTrips.indexOf(trip._id);
    if (index > -1) {
      trip.isSelected = false;
      this.selectedTrips.splice(index, 1);
    } else {
      trip.isSelected = true;
      this.selectedTrips.push(trip._id);
    }
  }

  selectAllTrips() {
    this.filterdataList.forEach((assign) => {
      if(this.status !== 'all'){
        if (assign.status === "cancelled" && assign.job && assign.job._id) {
          return;
        }
      }
     
      if (assign.issues && assign.issues.length) {
        return;
      }
      assign.isSelected = true;
      if(!this.selectedTrips.includes(assign._id))
      this.selectedTrips.push(assign._id);
    });
    // }

    this.isAllTripsSelected =
      this.selectedTrips.length === this.filterdataList.length;
    return this.isAllTripsSelected;
  }

  filterTripsByModel() {
    this.filterdataList = this.dataList;
    this.filterdataList = new FilterPipe().transform(
      this.filterdataList,
      this.search
    );

    if (this.isMedical)
      this.filterdataList = new FilterByKeyPipe().transform(
        this.filterdataList,
        "assign.isMedical",
        true
      );

    if (this.isCorporate)
      this.filterdataList = new FilterByKeyPipe().transform(
        this.filterdataList,
        "assign.jobType",
        "cooperate"
      );

    if (this.isMarketplace)
      this.filterdataList = new FilterByKeyPipe().transform(
        this.filterdataList,
        "assign.jobStatus",
        "marketplaced"
      );

    this.filterdataList = new FilterByKeyPipe().transform(
      this.filterdataList,
      "assign.companyType",
      this.selectedBroker
    );
    this.filterdataList = new FilterByKeyPipe().transform(
      this.filterdataList,
      "assign.payor",
      this.selectedPayor
    );
    this.filterdataList = new FilterByKeyPipe().transform(
      this.filterdataList,
      "assign.driver._id",
      this.selectedDriver
    );
    this.filterdataList = new FilterByKeyPipe().transform(
      this.filterdataList,
      "assign.cooperate._id",
      this.selectedCooperate
    );
    this.filterdataList = new FilterByKeyPipe().transform(
      this.filterdataList,
      "assign.tripRequirement",
      this.selectedVehicle
    );

    if (this.selectedTripPurpose) {
      this.filterdataList = new FilterPipe().transform(
        this.filterdataList,
        this.selectedTripPurpose
      );
    }
  }

  unSelectAllTrips() {
    const unselectedTrips = [];
    this.filterdataList.forEach((trip) => {
      trip.isSelected = false;
      unselectedTrips.push(trip._id);
    });

    this.selectedTrips = this.selectedTrips.filter(x => !unselectedTrips.includes(x));
    this.isAllTripsSelected = false;
  }

  sendMemberText(singleTrip, isMvt) {
    sweetAlert(
      "Alert",
      `Are you sure you want to send ${isMvt? 'verification' :'confirmation'} SMS to members?`,
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        if(singleTrip){
          this.requestConfirmationText([singleTrip._id],isMvt);
        }else{
          const selectedTrips = this.getSelectedTrips().map((trip) => trip._id);
          this.requestConfirmationText(selectedTrips,isMvt);
        }
      }
    });
  }

  requestConfirmationText(selectedTrips, isMvt){
        let payload = { selectedTrips };
        if(isMvt){
          payload['isMvt'] =true;
        }
        this.assignTripService
          .sendConfirmationText(payload)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            if (data) {
              this.toastr.success(
                "Text send to members successfully!",
                "Alert"
              );
              // sweetAlert('Success', 'Confirmation text sent to member successfully', 'success', 'OK');
            }
          });
  }

  sendMemberVerificationText() {
    sweetAlert(
      "Alert",
      "Are you sure you want to send verification SMS to members?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        const selectedTrips = this.getSelectedTrips().map((trip) => trip._id);
        const payload = {
          isMvt: true,
          selectedTrips
        };
        this.assignTripService
          .sendConfirmationText(payload)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            if (data) {
              this.toastr.success(
                "Verification text send to members successfully!",
                "Alert"
              );
              // sweetAlert('Success', 'Confirmation text sent to member successfully', 'success', 'OK');
            }
          });
      }
    });
  }

  adjustTrips() {
    const selectedTrips = this.getSelectedTrips().map((trip) => trip._id);
    const payload = { selectedTrips };
    this.assignTripService
      .autoTripAdjustment(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            // sweetAlert('Success', 'Trips adjusted successfully', 'success', 'OK');
          }
        },
        (err) => {
          // this.clearSelection();
        }
      );
  }

  getParseTripList() {
    this.sweepTripsList = [
      {
        _id: "1",
        total: "",
        scraps: [
          {
            _id: "",
            rawId: "",
            created: "",
            type: "",
            company: "",
            jobs: "",
          },
        ],
      },
    ];
    // this.store.dispatch(new GetSweepTripsList());
    this.assignTripService
      .getSweepTripsList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.sweepTripsList = data;
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  // **************** ACTIONS ON TRIP ****************
  // Send To Marketplace
  sendToMarketPlace(assign) {
    sweetAlert(
      "Alert",
      "Are you sure you want to send this trip to marketplace?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        assign.jobStatus = "marketplaced";
        assign.status = "unassigned";
        this.marketPlaceRequest(assign);
      }
    });
  }

  marketPlaceRequest(assign) {
    this.assignTripService
      .sendToMarketplace(assign)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.clearTrips();
            this.getTrips();
          }
        },
        (err) => {
          sweetAlert("Error", "Operation Failed", "error", "OK");
        }
      );
  }

  // Add Biller Note
  addBillerNote(trip, ind) {
    // this.tripIndex = ind;
    this.billerNoteModalObj = trip;
  }

  onBillerNoteAdded(event) {
    if (event) {
      // sweetAlert('Success', 'Biller Note Added Successfully', 'success', 'OK');
      this.billerNoteModalObj = null;
    } else {
      this.billerNoteModalObj = null;
    }
    // this.assignTripService
    //   .addBillerNote(event)
    //   .subscribe(data => {
    //     if (data) {
    //       let tempInd = -1;
    //         _.find(this.dataList, (item, i) => {
    //           if (item._id === this.filterdataList[this.tripIndex]._id) {
    //             tempInd = i;
    //             return i;
    //           }
    //         });
    //         this.filterdataList[this.tripIndex] = this.assignModalObj;
    //         this.dataList[tempInd] = this.assignModalObj;
    //         this.tripIndex = -1;
    //         tempInd = -1;
    //         this.assignModalObj = null;
    //     }
    //   }, err => {
    //     this.tripIndex = -1;
    //     sweetAlert('Error', err, 'error', 'OK');
    //   });
  }

  // Add Additional Note
  addAdditionalNote(trip, idx) {
    this.additionalNoteModalObj = trip;
  }

  onAdditionalNoteAdded(evt) {
    if (evt) {
      // sweetAlert('Success', 'Additional Note Added Successfully', 'success', 'OK');
    }
    this.additionalNoteModalObj = null;
  }

  // Add Special Rates
  addSpecialRates(data) {
    this.assignTripService
      .addSpecialRate(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.dataList.forEach((item, i) => {
              if (item._id === data._id) {
                this.dataList[i].customerSpecialRate = data.customerSpecialRate;
                this.dataList[i].specialRate = data.specialRate;
              }
            });
            this.filterdataList.forEach((item, i) => {
              if (item._id === data._id) {
                this.filterdataList[i].customerSpecialRate =
                  data.customerSpecialRate;
                this.filterdataList[i].specialRate = data.specialRate;
              }
            });
            // sweetAlert('Success', 'Trip Special Rate Added Successfully', 'success', 'OK');
          }
          this.closeActionMenu(null);
        },
        (err) => {
          sweetAlert("Error", err, "warning", "OK");
          this.closeActionMenu(null);
        }
      );
  }

  // Calculate Fare
  calculateFare(assign) {
    this.assignTripService
      .calculateFare(assign._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            assign.estimatedSpecialRate = data.totalFare || "";
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  // Add Special Rate
  addSpecialRate() {
    this.addSpecialRates(this.specialRate);
    // $('#dsrModal .close').click();
  }

  // Update Job - Mark Ready, Mark VIP, Mark Confirm, Mark Voicemail
  updateJob(assign, assignKey) {
    let params = {
      assignId: assign._id,
    };
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
  }
  sendForAuth(assign) {
    this.cms1500Service.sendForAuthPDF(assign, this.user);
  }

  // Update Job - Mark Ready, Mark VIP, Mark Confirm, Mark Voicemail
  dropTrip(assign) {
    sweetAlert(
      "Alert",
      "Are you sure you want to drop this trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        let params = {
          assignId: assign._id,
          changeReason: "Will Do Later",
          driverId: assign.driver._id,
          company: { _id: this.user._id, displayName: this.user.displayName },
        };
        this.socketService.emit(Events.DROP_TRIP, params);
      }
    });
  }

  // Clone Trip
  cloneTrip(id) {
    sweetAlert(
      "Alert",
      "Are you sure you want to clone this trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        this.assignTripService
          .cloneTrip(id)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe(
            (data) => {
              if (data) {
                this.getTrips();
                // sweetAlert('Success', 'Trip Cloned Successfully', 'success', 'OK');
              }
            },
            (err) => {
              sweetAlert("Error", err, "error", "OK");
            }
          );
      }
    });

    // const isConfirmed = confirm('Are you sure you want to clone this trip');
    // if (isConfirmed) {
    //   this.assignTripService
    //     .cloneTrip(id)
    //     .subscribe(data => {
    //       if (data) {
    //         sweetAlert('Success', 'Trip Cloned Successfully', 'success', 'OK');
    //       }
    //     }, err => {
    //       sweetAlert('Error', err, 'error', 'OK');
    //     });
    // }
  }

  // Remove Trip
  cancelTrip(trip) {
    this.cancelTripModalObj = trip;
  }

  onCancelTrip(evt) {
    if (evt) {
      const index = this.filterdataList.findIndex(
        (trip) => trip._id === evt._id
      );
      this.filterdataList.splice(index, 1);
    }
    this.cancelTripModalObj = null;
  }

  getCompanyDriverEstimatedFare(assign) {
    this.selectedTrip = assign;
    const params = {
      assignId: assign._id,
      driverId: assign.driver._id,
    };
    this.socketService.emit(Events.GET_DRIVER_ESTIMATED_FARE, params);
  }

  updateDriverFare(payload) {
    this.dispatchCenterService
      .saveDriverFare(this.selectedTrip.job._id, payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.filterdataList.forEach((trip) => {
            if (trip._id === this.selectedTrip._id) {
              trip.job.driverFareDetail.jobFee = data.driverFareDetail.jobFee;
            }
          });
          this.selectedTrip = null;
          // sweetAlert('Success', 'Fare updated successfully', 'success', 'OK');
        }
      });
  }

  isStringExist(substrings, str) {
    if (substrings.some((v) => str.toLowerCase().includes(v))) {
      return true;
    }
    return false;
  }

  rejectRide(assign, index) {
    sweetAlert(
      "Alert",
      "Are you sure you want to re-route this Trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        assign.status = "archived";
        this.assignTripService
          .assignTrip(assign)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            if (data) {
              let findTripIndex = this.filterdataList.findIndex(
                (t) => t._id === assign._id
              );
              this.filterdataList.splice(findTripIndex, 1);
              sweetAlert('Success', 'Trip Re-routed Successfully', 'success', 'OK');
            }
          });
      }
    });
  }

  markCompletedTrip(assign) {
    sweetAlert(
      "Alert",
      "Are you sure you want to complete this trip?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        this.assignTripService
        .markCompletedTrip(assign)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
          if (data) {
            let findTripIndex = this.filterdataList.findIndex(
              (t) => t._id === assign._id
            );
            this.filterdataList.splice(findTripIndex, 1);
          }
        });
      }
    });
  }

  markCompletedAll() {
    sweetAlert(
      "Alert",
      "Are you sure you want to complete all trips?",
      "warning",
      "Yes",
      "No"
    ).then(async (result: any) => {
      if (result.value) {
        let trips = this.getSelectedTrips();
        console.log({trips});
        for (let trip of trips) {
          console.log({trip});
          const res:any = await this.assignTripService
            .markCompletedTrip(trip).toPromise();
            console.log({res});
          if (res) {
            let findIndex = this.filterdataList.findIndex(
              (t) => t._id === res._id
            );
            this.filterdataList.splice(findIndex, 1);
          }

        }

        this.selectedTrips.length = 0;
      }
    });
  }



  cancelCompleted(assign, index) {
    // adding temprary key to log, we are cancelling this as  Finished cancel trip.
    assign.isFinishedCalled = true;
    this.cancelTripModalObj = assign;
  }

  markDvRequired(assign, index) {
    this.assignTripService
      .updateTrip(assign)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', `${assign.isDvRequired ? 'Marked' : 'Un-Marked'} DV Required Successfully`, 'success', 'OK');
        }
      });
  }

  isPaidTrip(assign, index) {
    this.assignTripService
      .updateTrip(assign)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          // sweetAlert('Success', `${assign.takeSignaturesOnly ? 'Marked' : 'Un-Marked'} Is Paid Successfully`, 'success', 'OK');
        }
      });
  }

  showFilter() {
    this.showSlideFilter = !this.showSlideFilter;
  }

  closeFilter() {
    this.showSlideFilter = false;
  }

  viewTripDetails(trip) {
    this.tripDetailModalObj = trip;
  }

  viewCompletedTripDetails(trip) {
    this.clientId = trip.priorityClient.clientId;
    this.completedTripModalObj = trip;
  }

  editTrip(assign) {
    this.editTripModalObj = assign._id;
  }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex((t) => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);
      // console.log('find index ', index)
      if (
        moment(event.appointmentTime).format("YYYY-MM-DD") !==
        moment(this.filterdataList[idx].appointmentTime).format(
          "YYYY-MM-DD"
        ) ||
        moment(event.scheduleTime).format("YYYY-MM-DD") !==
        moment(this.filterdataList[idx].scheduleTime).format("YYYY-MM-DD")
      ) {
        // this.clearTrips();
        // this.getTrips();
        this.filterdataList.splice(idx, 1);
      } else {
        this.filterdataList[idx] = event;
      }
      this.editTripModalObj = null;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.settingInterval);
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
    this.sharedDataService.toggleDatePicker(false);
    window.removeEventListener("keyup", this.initializeTimer);
  }

  loadedMiles(trip) {
    this.selectedTripId = trip._id;
    const payload = {
      assignId: trip._id,
      isDriveTime: true,
    };
    this.socketService.emit(Events.GET_ASSIGN_TRIP_ETA, payload);
  }

  getStaffRoles() {
    if (this.user && this.user.roles) {
      if (typeof this.user.roles === "string") {
        if (this.user.sRoles)
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.sRoles);
        else
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles);
      } else if (typeof this.user.roles === "object") {
        this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles[0]);
      }
    }

    if (this.subMenus && this.subMenus.length) {
      if (this.subMenus[0].value === "all" && this.subMenus.length > 1)
        this.status = this.subMenus[1].value;
      else this.status = this.subMenus[0].value;

      this.getTrips();
    }
  }

  onClearVehicle() {
    this.selectedVehicle = "";
  }

  onClearDriver() {
    this.selectedDriver = "";
  }
  onClearCooperate() {
    this.selectedCooperate = "";
  }

  onClearTripPurpose() {
    this.selectedTripPurpose = "";
  }

  onTimerComplete(): void {
    this.timer.init();
    // this.clearTrips();
    this.skip = 0;
    this.getTrips();
  }

  downloadPassengerReport() {
    const trips = this.getSelectedTrips();
    const data = {
      trips,
      corporate: this.selectedCooperate,
      driver: this.selectedDriver,
      vehicle: this.selectedVehicle,
      timezone: this.timeZone,
    };
    this.csvService.generatePassengerReport(data);
  }

  downloadDriverReport() {
    let trips;

    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();


    const data = {
      date:
        moment(this.todayDate[0]).format("MM/DD/YYYY") +
        " - " +
        moment(this.todayDate[1]).format("MM/DD/YYYY"),
      trips,
      corporate: this.selectedCooperate,
      driver: this.selectedDriver,
      vehicle: this.selectedVehicle,
      timezone: this.timeZone,
    };
    this.csvService.generateDriverReport(data);
  }

  downloadR2Report() {
    let trips;

    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();


    const data = {
      date:
        moment(this.todayDate[0]).format("MM/DD/YYYY") +
        " - " +
        moment(this.todayDate[1]).format("MM/DD/YYYY"),
      trips,
      // corporate: this.selectedCooperate,
      // driver: this.selectedDriver,
      // vehicle: this.selectedVehicle,
      timezone: this.timeZone,
    };
    // console.log("Trip Data",data);
    // return;
    this.csvService.generateCompletedTripReport(data);
  }
  downloadCompletedReport() {
    let trips;

    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();


    const data = {
      date:
        moment(this.todayDate[0]).format("MM/DD/YYYY") +
        " - " +
        moment(this.todayDate[1]).format("MM/DD/YYYY"),
      trips,
      // corporate: this.selectedCooperate,
      // driver: this.selectedDriver,
      // vehicle: this.selectedVehicle,
      timezone: this.timeZone,
    };
    // console.log("Trip Data",data);
    // return;
    this.csvService.generateFinishedReport(data);
  }

  corporateReport() {
    let trips;

    if (!this.selectedTrips.length)
      trips = this.dataList;
    else
      trips = this.getSelectedTrips();

    const data = {
      trips,
      corporate: this.selectedCooperate,
      driver: this.selectedDriver,
      vehicle: this.selectedVehicle,
      payor: this.selectedPayor,
      timezone: this.timeZone,
    };
    this.csvService.generateCorporateReport(data);
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  toggleTimer() {
    this.isTimerEnabled = !this.isTimerEnabled;
    if (this.isTimerEnabled) {
      this.skip = 0;
      this.getTrips();
    } else {
      this.timer.init();
    }
  }

  getCmsReport(trip) {
    let queryParams = {
      companyType: trip.companyType,
      tripIdentity: trip.tripIdentity,
      type: "triplogs",
    };
    if (trip.cooperate) {
      queryParams["cooperate"] = trip.cooperate._id;
    }
    this.cms1500Service.getCmsReport(queryParams, this.user);
  }
  rnsCorporateInvoice1Report() {
    const payload = {
      scheduleTime: this.todayDate,
    };
    this.cms1500Service.rnsCorporateInvoice1Report(
      this.selectedCooperate,
      payload
    );
  }

  markRead(assign) {
    if (assign.viewStatus === "returned") return;
    sweetAlert(
      "Alert",
      "Are you sure you want to mark this trip as acknowledged?",
      "warning",
      "Yes",
      "No"
    ).then((result: any) => {
      if (result.value) {
        assign.viewStatus = "";
        assign.dispatchNoteTwo += ` Mark Acknowledged : ${this.user.displayName
          } ${moment().tz(`${this.timeZone}`).format("MM/DD/YY HHmm")}`;
        this.assignTripService
          .assignTrip(assign)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe((data) => {
            if (data) {
              // sweetAlert('Success', 'Ride Re-routed Successfully', 'success', 'OK');
              this.closeActionMenu(null);
            }
          });
      }
    });
  }

  updatedJobFee(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find(
        (x) => x.job._id === evt.tripId
      );
      if (
        selectedTrip &&
        selectedTrip.job &&
        selectedTrip.job.driverFareDetail
      ) {
        selectedTrip.job.driverFareDetail.jobFee = evt.jobFee;
      }
    }
  }

  updatedAssociateImg(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find(
        (x) => x._id === evt.tripId
      );
      if (selectedTrip) {
        selectedTrip.associateImage = evt.associateImage;
        selectedTrip.associateImageType = evt.associateImageType;
      }
    }
  }

  updatedCompImg(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find(
        (x) => x.job._id === evt.jobId
      );
      if (selectedTrip) {
        selectedTrip.associateImage = evt.associateImage;
        selectedTrip.associateImageType = evt.associateImageType;
      }
    }
  }

  updateTripData(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find((x) => x._id === evt._id);
      if (selectedTrip) {
        selectedTrip.attachment = evt.attachment;
        selectedTrip.companyNote = evt.companyNote;
        selectedTrip.dispatchNoteTwo = evt.dispatchNoteTwo;
      }
    }
  }

  onBFilterChange(evt, isBrokerChange) {
    if (isBrokerChange) this.selectedPayor = "";
    else this.selectedBroker = "";

    this.filterTripsByModel();
  }

  attachAssociateImg(trip) {
    this.associateImageModalObj = trip;
  }

  acknowledgeDriver() {
    let trips = this.getSelectedTrips();
    trips = trips.filter(
      (trip) => trip.driver && trip.driver._id === this.selectedDriver
    );
    if (!trips || !trips.length) return;
    const payload = {
      driver: trips[0].driver,
      totalTrips: trips.length,
      scheduleTime: this.todayDate[0],
      endDate: this.todayDate[1],
    };

    this.assignTripService
      .sendDriverAcknowledgementText(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          sweetAlert("Success", data.message, "success", "OK");
        }
      });
  }

  driverGrivenceReport(trip) {
    this.reportService.getDriverGrivenceReport(trip, this.user);
  }

  adjustCompletedTrips() {
    sweetAlert(
      "Alert",
      `Are you sure you want to adjust the trips?`,
      "warning",
      "Yes",
      "No"
    ).then((result) => {
      if (result.value) {
        const payload = {
          scheduleTime: this.todayDate.toString(),
        };
        this.assignTripService
          .adjustCompletedTrips(payload)
          .subscribe((data) => {
            if (data && data.success) {
              if (data.jobIds && data.jobIds.length) {
                this.filterdataList.forEach((trip) => {
                  if (
                    trip.job &&
                    trip.job._id &&
                    data.jobIds.includes(trip.job._id)
                  ) {
                    trip.job.isFareAdjusted = true;
                  }
                });
              }
              this.toastr.success(data.message, "Alert");
            }
          });
      }
    });
  }

  tripsReport(){
    let selectedTrips;

    if (!this.selectedTrips.length)
      selectedTrips = this.dataList;
    else
      selectedTrips = this.getSelectedTrips();
    const data = {
      selectedTrips,
      timezone: this.timeZone,
      scheduleTime: this.todayDate[0],
      endDate: this.todayDate[1],

    };
    this.csvService.generateTripsReport(data);
  }

  openUberModal(uberTrip){
    this.uberDetails = uberTrip
    this.isUberModal = true
  }


  cancelRideRequest(uberTrip) {
    const companyId = this.user.company ? this.user.company._id : this.user._id
    sweetAlert('Alert', `Are you sure you want to cancel farmout trip?`, 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          this.farmoutService.cancelRideRequest(uberTrip.requestId, {company:companyId})
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((data) => {
              if (data) {
                sweetAlert("Success", 'Farmout trip cancel request in process.', "success", "OK");
              }
            });
        }
      });
  }

  closeUberModal(evt){
    this.isUberModal = evt
  }

  prodCheck(){
    if (this.isProduction) {
      const companyId = this.user.company ? this.user.company._id : this.user._id
      if(companyId === '6463be83c60e5d659486581f'){
        return true;
      }else {
        return false;
      }

    }
    else{
      return true
    }
  }

  addServiceArea(trip) {
    this.addServiceAreaModalObj = trip;
  }

  onServiceAreaAdded(evt) {
    this.addServiceAreaModalObj = null;
  }

  openTripLog(assign){
    this.tripLogObj = assign;
  }
}
