import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AssigTripsService } from "../../assigned-trips/assigntrips-service/assigntrips-service";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { ControlPosition, MapTypeControlOptions } from "@agm/core";
import { sweetAlert } from "src/app/utils/swal";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { FarmoutService } from "src/app/service/farmout-service/farmout.service";
import * as moment from 'moment-timezone';

@Component({
  selector: "app-uber-trip-modal",
  templateUrl: "./uber-trip-modal.component.html",
  styleUrls: ["./uber-trip-modal.component.css"],
})
export class UberTripModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() onClose = new EventEmitter<any>();
  uberDetail;
  driverTip;
  uberReceipt;
  isDrawRoute = true;
  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };

  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  driverLocation;
  user;

  constructor(private assignTripService: AssigTripsService, private sharedDataService: SharedDataService, private farmoutService: FarmoutService
) {}


  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.details();
        }
      });
  }

  closeDetailModal(evt) {
    this.onClose.emit(evt);
  }

  details() {
    const companyId = this.user.company ? this.user.company._id : this.user._id

    console.log("Assign Data", this.data);
    this.farmoutService
      .rideDetailsRequest({...this.data.uber, company:companyId})
      .subscribe((data) => {
        if (data) {
          this.uberDetail = data;
          this.origin = {
            lat: this.uberDetail.pickup.latitude,
            lng: this.uberDetail.pickup.longitude
          };
          this.destination = {
            lat: this.uberDetail.destination.latitude,
            lng: this.uberDetail.destination.longitude
          };

          if (this.uberDetail.location) {
            this.driverLocation = {
              latitude: this.uberDetail.location.latitude,
              longitude: this.uberDetail.location.longitude
            }
          } else if (this.uberDetail.trip_end_location) {
            this.driverLocation = {
              latitude: this.uberDetail.trip_end_location.latitude,
              longitude: this.uberDetail.trip_end_location.longitude
            }
          }
        }
      }, err => {
        sweetAlert('Error', err,'warning', 'OK');
      });
    // this.assignTripService
    //   .uberRideReceiptRequest(this.data.uber)
    //   .subscribe((data) => {
    //     if (data) {
    //       console.log("Uber Receipt Data", data);
    //     }
    //   });
  }

  sendTip() {
    if (!this.driverTip) return;
    const companyId = this.user.company ? this.user.company._id : this.user._id

    const payload = {
      "request_id": this.uberDetail.request_id,
      "tip_amount": this.driverTip,
      "company" : companyId
    }
    this.farmoutService
      .tipRequest(payload)
    .subscribe(data => {
      if (data) {
        this.uberDetail.can_tip = false;
        this.details();
      }
    }, err => {
      console.log(err);
      sweetAlert('Error', err,'warning', 'OK');
    });

  }

  getReceipt() {
    const companyId = this.user.company ? this.user.company._id : this.user._id
    this.farmoutService
      .rideReceiptRequest(this.uberDetail.request_id, {company:companyId})
      .subscribe(data => {
        if (data) {
          this.uberReceipt = data;
        }
      }, err => {
        console.log(err);
        sweetAlert('Error', err,'warning', 'OK');
      });
  }

  convertTime(time) {
    if (!time) return 'N/A';
    return moment(time).tz(this.user.timeZone).format("MM/DD/YY HHmm")
  }

  ngOnDestroy(): void {
  }
}
