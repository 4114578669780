import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from 'src/app/utils/utils.common';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import * as _ from 'underscore';
import { MenuService } from '../../shared/layout/menu-service/menu.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import { ServiceAreasService } from '../../service-areas/service-areas-service/service-areas.service';


@Component({
  selector: 'app-uber-trips',
  templateUrl: './uber-trips.component.html',
  styleUrls: ['./uber-trips.component.css']
})
export class UberTripsComponent implements OnInit,OnDestroy {
  duration = 10 * 1000; // 10 seconds
  filterdataList = [];
  todayDate;
  isShowAllTrips = false;
  search: any;
  timeZone;
  user;
  completedTripModalObj;
  tripDetailModalObj;
  skip = 0;
  editTripModalObj;
  partialEditModalObj;
  clientId;
  vehicles;
  drivers;
  selectedDriver = [];
  driverListMain = [];
  filteredDrivers = [];
  isShowStatusDropdown = false;
  tripStatuses = [
    { label: 'Unassigned', status: 'pending', isChecked: true },
    { label: 'Assigned', status: 'hanging', isChecked: true },
    { label: 'En Route', status: 'onway', isChecked: true },
    { label: 'Arrived', status: 'arrived', isChecked: true },
    { label: 'In Progress', status: 'started', isChecked: true },
    { label: 'Completed', status: 'finished', isChecked: false },
    { label: 'Cancelled', status: 'cancelled', isChecked: false },
  ];
  selectedStatus: any = "active";
  driverList = [];
  corporateUsers = [];
  trip;
  payors = {
    medicaid: "Medicaid",
    medicare: "Medicare",
    medical: "MediCal",
    optum: "Optum",
    privatePay: "Private Pay",
    billFacility: "Bill Facility",
    tricare: "Tricare",
    champva: "CHAMPVA",
    federalemployees: "Federal Employees",
    fecaBlackLung: "FECA Black Lung",
    other: "Other"
  };

  subMenus= [];
  status = "";
  isUberModal = false;
  uberDetails;
  selectedTripId;
  preServiceAreas = {}

  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,
    private driverService: DriversService,
    private menuService: MenuService,
    private socketService: SocketService,
    private csvService:CsvService,
    private serviceAreasService : ServiceAreasService


  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getDriverList();
          this.getCorporateUserList();
          this.getTrips();
          this.getStaffRoles();
          this.getServiceAreas();
        }
      });
  }

  getDriverList() {
    this.driverService.getDrivers(this.user._id, this.selectedStatus)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.driverListMain = data;
            this.driverList = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
          }
        },
      );
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }

  onChangeStatus() {
    this.getTrips();
  }

  toggleStatusDropdown() {
    this.isShowStatusDropdown = !this.isShowStatusDropdown
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.getTrips();
  }

  getTrips() {
    // this.filterdataList = [];
    // this.dataList = [];
    let jobStatuses = [];
    this.tripStatuses.forEach(t => {
      if (t.isChecked) {
        if (t.status === 'hanging') {
          jobStatuses.push('offered');
          jobStatuses.push('accepted');
        } else {
          jobStatuses.push(t.status);
        }
      }
    })
    const object = {
      isShowAllTrips: this.isShowAllTrips,
      status:'manifest',
      date: [this.todayDate, moment(this.todayDate).endOf('day').toISOString()],
      skip: this.skip,
      jobStatuses
    };
    // this.store.dispatch(new GetUnassignedList(object));
    this.assignTripService.getAssignList(object)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.filterdataList = [];
        let drivers = [];
        if (data) {
          this.filterdataList = data.filter((trip)=> trip.uber)

          this.filterdataList.forEach(trip => {
            if (trip.driver) {
              drivers.push({ _id: trip.driver});
            }
          });

          drivers = _.uniq(drivers, '_id').sort();
          if (drivers && drivers.length) {
            this.filteredDrivers = this.driverListMain.filter(driver =>
              drivers.some(d => d._id._id === driver._id)
            );
          }
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }


  editTrip(assign) {
    this.editTripModalObj = assign._id;
    this.trip = assign;
  }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex(t => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);

      if (moment(event.appointmentTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].appointmentTime).format("YYYY-MM-DD") || moment(event.scheduleTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].scheduleTime).format("YYYY-MM-DD")) {
        // this.clearTrips();
        // this.getTrips();
        this.filterdataList.splice(idx, 1);
      } else {
        this.filterdataList[idx] = event;
      }
      if (event.status === 'archived') {
        this.filterdataList.splice(idx, 1);
      }

      if (event.jobStatus === 'cancelled' || event.status === 'cancelled') {
        const isCancelledSelected = this.tripStatuses.find(ts => ts.status === 'cancelled' && ts.isChecked);
        if (!isCancelledSelected) {
          this.filterdataList.splice(idx, 1);
        }
      }
      this.editTripModalObj = null;
    }
  }

  viewCompletedTripDetails(trip) {
    this.clientId = trip.priorityClient.clientId;
    this.completedTripModalObj = trip;
  }

  openUberModal(uberTrip) {
    this.uberDetails = uberTrip
    this.isUberModal = true
  }

  getDriverByID(id) {
    return this.driverList[id];
  }

  getCorporateByID(id) {
    return this.corporateUsers[id];
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  viewTripDetails(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.driver = this.getDriverByID(trip.driver);
    this.tripDetailModalObj = obj;
  }

  updateTripData(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find((x) => x._id === evt._id);
      if (selectedTrip) {
        selectedTrip.attachment = evt.attachment;
        selectedTrip.companyNote = evt.companyNote;
        selectedTrip.dispatchNoteTwo = evt.dispatchNoteTwo;
      }
    }
  }

  setPartialEditModal(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.cooperate = this.getCorporateByID(trip.cooperate);
    this.partialEditModalObj = obj;
  }

  getServiceAreas() {
    this.serviceAreasService
      .getServiceAreas({ status: 'active' })
      .subscribe(data => {
        if (data && data.length) {
          this.preServiceAreas = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }


  getStaffRoles() {
    if (this.user && this.user.roles) {
      if (typeof this.user.roles === "string") {
        if (this.user.sRoles)
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.sRoles);
        else
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles);
      } else if (typeof this.user.roles === "object") {
        this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles[0]);
      }
    }

    if (this.subMenus && this.subMenus.length) {
      if (this.subMenus[0].value === "all" && this.subMenus.length > 1)
        this.status = this.subMenus[1].value;
      else this.status = this.subMenus[0].value;

      this.getTrips();
    }
  }

  downloadReport(){
    const paylaod = {
      trips:this.filterdataList,
      date: moment(this.todayDate).tz(this.timeZone).format("MM/DD/YYYY"),
      timezone: this.timeZone
    }
    this.csvService.downloadUberTripsReport(paylaod);
  }

  closeUberModal(evt) {
    this.isUberModal = evt
  }

  ngOnDestroy(): void {
  }
}

