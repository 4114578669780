import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CircleTimerModule } from './modules/circle-timer/src/public-api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { CreateNewTripComponent } from './component/dispatch-center/create-new-trip/create-new-trip.component';
import { RangePipe } from './pipe/range-pipe/range.pipe';
import { FilterPipe } from './pipe/list-filter-pipe/list-filter-pipe';
import { FilterByKeyPipe } from './pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { DispatchDashboardComponent } from './component/dispatch-center/dashboard/dashboard.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { StaffMainComponent } from './component/staffs/staff-main/staff-main.component';
import { StaffsCreateComponent } from './component/staffs/staffs-create/staffs-create.component';
import { TokenInterceptorService } from './service/token-interceptor-service/token-interceptor.service';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
// we are using this in edit profile for all time zone list
import { AutocompleteComponent } from './component/common-components/goolge-places-selecters/google-places.component';
// import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { DriverListComponent } from './component/drivers/driver-list/driver-list.component';
import { RolePipe } from './pipe/role-value-filter/role.pipe';
import { AccountStatusPipe } from './pipe/account-status-value-filter/account-status.pipe';
import { CreateDriverComponent } from './component/drivers/create-driver/create-driver.component';
import { DriverProfileDashboardComponent } from './component/drivers/driver-profile-dashboard/driver-profile-dashboard.component';
import { ImageModalComponent } from './modal/image-modal/image-modal.component';
import { CamelCasePipe } from './pipe/camelcase.filter/camelcase.filter.pipe';
import { CorporateuserComponent } from './component/corporate-user/corporate-user-list/corporate-user.component';
import { CorporateAddUserComponent } from './component/corporate-user/corporate-add-user/corporate-add-user.component';
import { FleetListComponent } from './component/fleet/fleet-list/fleet-list.component';
import { CreateFleetComponent } from './component/fleet/create-fleet/create-fleet.component';
import { InviteSmsComponent } from './component/Invite/invite-sms/invite-sms.component';
import { DriverDistributionsComponent } from './component/driver-distributions/driver-distributions-list/driver-distributions.component';
// tslint:disable-next-line: max-line-length
import { DriverDistributionsCreateComponent } from './component/driver-distributions/driver-distributions-create/driver-distributions-create.component';
import { DriverAlertComponent } from './component/driver-alert/driver-alert/driver-alert.component';
import { HelpingMaterialListComponent } from './component/helping-material/helping-material-list/helping-material-list.component';
import { UrlSafePipe } from './pipe/url-safe-pipe/url-safe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParseTripsComponent } from './component/planner/parse-trips/parse-trips.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { PlannerEffects } from './ngrx/store/effects/planner-effects';
import { DashboardComponent } from './component/assigned-trips/dashboard/dashboard.component';
import { UnassignedComponent } from './component/assigned-trips/unassigned/unassigned.component';
import { VerifiedTripLogsComponent } from './component/assigned-trips/verified-trip-logs/verified-trip-logs.component';
import { NewTripComponent } from './component/assigned-trips/new-trip/new-trip.component';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { NemtStatisticsComponent } from './component/kpis/nemt-statistics/nemt-statistics.component';
import { NemtReportComponent } from './component/kpis/nemt-report/nemt-report.component';

import { DynamicFareListComponent } from './component/dynamic-fare/dynamic-fare-list/dynamic-fare-list.component';
import { DynamicFareAddComponent } from './component/dynamic-fare/dynamic-fare-add/dynamic-fare-add.component';
import { HourPipePipe } from './pipe/hour-range-pipe/hour-pipe.pipe';
import { MinPipePipe } from './pipe/minute-range-pipe/min-pipe.pipe';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { urls } from './utils/url-utils';
import { SocketService } from './service/socket-service/socket-service.service';
import { AgmOverlays } from 'agm-overlays';
import { EarningsComponent } from './component/reports/earnings/earnings.component';
import { TripDetailsComponent } from './component/dispatch-center/trip-details/trip-details.component';
import { AdjustmentComponent } from './component/dispatch-center/adjustment/adjustment.component';
import { TripFilterPipe } from './pipe/trip-list-filter-pipe/trip-filter.pipe';
import { TripAssignModalComponent } from './component/assigned-trips/trip-assign-modal/trip-assign-modal.component';
import { TimeFormatDirective } from './directives/time-format-directive/time-format.directive';
import { CompanyNoteModalComponent } from './component/assigned-trips/company-note-modal/company-note-modal.component';
import { CancelTripModalComponent } from './component/assigned-trips/cancel-trip-modal/cancel-trip-modal.component';
import { TimePipe } from './pipe/time-pipe/time.pipe';
import { AgmDirectionModule } from 'agm-direction';
import { TripDetailDialogComponent } from './component/common-components/trip-detail-dialog/trip-detail-dialog.component';
import { BillerNoteModalComponent } from './component/assigned-trips/biller-note-modal/biller-note-modal.component';

import { SignaturePadModule } from 'angular2-signaturepad';
import { CommonAlertComponent } from './component/common-components/common-alert/common-alert.component';
import { DriverBreakdownComponent } from './component/kpis/driver-breakdown/driver-breakdown.component';
import { SweepTripsModalComponent } from './component/assigned-trips/sweep-trips-modal/sweep-trips-modal.component';
import { TimeagoModule } from 'ngx-timeago';
import { MySocketWrapper } from './service/socket-service/socket-wrapper';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from './modules/ngx-ui-loader/src/public-api';
import { TripsInProgressModalComponent } from './component/dispatch-center/trips-in-progress-modal/trips-in-progress-modal.component';
import { DatePipe } from './pipe/date-pipe/date.pipe';
import { OwlModule } from 'ngx-owl-carousel';
import { TodayTripsPipe } from './pipe/today-trips-pipe/today-trips.pipe';
import { DashboardTripsDetailsComponent } from './component/assigned-trips/dashboard-trips-details/dashboard-trips-details.component';
import { NotificationDetailsComponent } from './notification/notification-details/notification-details.component';
// import { AccidnetsListComponent } from './component/accidents/accidnets-list/accidnets-list.component';
// import { AddAccidentComponent } from './component/accidents/add-accident/add-accident.component';
import { HelpingMaterialPdfComponent } from './component/helping-material/helping-material-pdf/helping-material-pdf.component';
// import { AccidentDetailInTailwindFormComponent } from './component/accidents/accident-detail-in-tailwind-form/accident-detail-in-tailwind-form.component';
import { LogsComponent } from './component/offered-trips/logs/logs.component';
import { MemberListComponent } from './component/members/member-list/member-list.component';
import { CreateMemberComponent } from './component/members/create-member/create-member.component';
import { DriverLoadComponent } from './component/assigned-trips/driver-load/driver-load.component';
import { OutstandingComponent } from './component/assigned-trips/outstanding/outstanding.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { DriverInvoicesComponent } from './component/drivers/driver-invoices/driver-invoices.component';
import { DirtRoadComponent } from './component/assigned-trips/dirt-road/dirt-road.component';
import { StandingOrdersComponent } from './component/assigned-trips/standing-orders/standing-orders.component';
import { DriverTimelinesComponent } from './component/drivers/driver-timelines/driver-timelines.component';
import { TripDetailModelComponent } from './component/assigned-trips/trip-detail-model/trip-detail-model.component';
import { OutstandingTripsModalComponent } from './component/assigned-trips/outstanding-trips-modal/outstanding-trips-modal.component';
import { AdditionalNoteModalComponent } from './component/assigned-trips/additional-note-modal/additional-note-modal.component';
import { ZDatesComponent } from './component/z-dates/z-dates.component';
import { SideNavComponent } from './component/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from './component/shared/layout/header/header.component';
import { DriverEarningModalComponent } from './component/reports/earnings/driver-earning-modal/driver-earning-modal.component';
import { NotificationsSlideoutComponent } from './component/shared/slideouts/notifications-slideout/notifications-slideout.component';
import { DispatchInprogressTripsSlideoutComponent } from './component/shared/slideouts/dispatch-inprogress-trips-slideout/dispatch-inprogress-trips-slideout.component';
import { SortByDatePipe } from './pipe/sort-by-date.pipe';
import { AddExpenseModalComponent } from './component/kpis/add-expense-modal/add-expense-modal.component';
import { OutsideClickDirective } from './directives/outside-click-directive/outside-click.directive';
import { BrokerDropdownComponent } from './component/shared/broker-dropdown/broker-dropdown.component';
import { TripActionsDropdownComponent } from './component/shared/trip-actions-dropdown/trip-actions-dropdown.component';
import { ClockTimePipe } from './pipe/clock-time/clock-time.pipe';
import { EditTripModalComponent } from './component/assigned-trips/edit-trip-modal/edit-trip-modal.component';
import { AdjustedTripOdomterModalComponent } from './component/assigned-trips/adjusted-trip-odomter-modal/adjusted-trip-odomter-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { OfferedComponent } from './component/assigned-trips/offered/offered.component';
import { BrokerSettingComponent } from './component/planner/broker-setting/broker-setting.component';
import { BrokerSettingListComponent } from './component/planner/broker-setting-list/broker-setting-list.component';
import { AdminNemtReportComponent } from './component/superAdmin/admin-nemt-report/admin-nemt-report.component';
import { AuthTokenComponent } from './component/auth-token/auth-token.component';
import { CompaniesListComponent } from './component/companies/companies-list/companies-list.component';
import { CreateCompanyComponent } from './component/companies/create-company/create-company.component';
import { CreatQuarterComponent } from './component/superAdmin/quarters/creat-quarter/creat-quarter.component';
import { QuarterListComponent } from './component/superAdmin/quarters/quarter-list/quarter-list.component';
import { InvoiceSettingListComponent } from './component/superAdmin/invoice-settings/invoice-setting-list/invoice-setting-list.component';
import { AddressPickerComponent } from './component/shared/address-picker/address-picker.component';
import { CorporateAddonListComponent } from './component/corporate-addons/corporate-addon-list/corporate-addon-list.component';
import { AddCorporateAddonComponent } from './component/corporate-addons/add-corporate-addon/add-corporate-addon.component';
import { NemtProTipsComponent } from './component/helping-material/nemt-pro-tips/nemt-pro-tips/nemt-pro-tips.component';
import { ImagesliderDirective } from './directives/image-slider/imageslider.directive';
import { SortByPipe } from './pipe/sortby-filter-pipe/sortby-filter-pipe';
import { StatisticsDetailsComponent } from './component/kpis/nemt-statistics-details/statistics-details/statistics-details.component';
import { PoisitiveNumberDirective } from './directives/positive-number/poisitive-number.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManifestComponent } from './component/assigned-trips/manifest/manifest.component';
import { ConvertPhoneNumberDirective } from './directives/number-directive/convert-phone-number.directive';
import { AdjustmentTripComponent } from './component/assigned-trips/adjustment-trip/adjustment-trip.component';
import { StandingOrdersV2Component } from './component/assigned-trips/standing-orders-v2/standing-orders-v2.component';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule } from './modules/picker/src/public_api';
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from './modules/picker/src/lib/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { OwlMomentDateTimeModule } from './modules/picker/src/lib/date-time/adapter/moment-adapter/moment-date-time.module';
import { TimezonePickerModule } from './modules/ng2-timezone-selector/src';

import { NemtSuperProTipsComponent } from './component/superAdmin/nemt-super-tips/nemt-super-pro-tips/nemt-super-pro-tips.component';
import { CreateTipsComponent } from './component/superAdmin/create-pro-tips/create-tips/create-tips.component';
import { StandingOrderCloneModalComponent } from './component/assigned-trips/standing-order-clone-modal/standing-order-clone-modal.component';
import { GanttChartComponent } from './component/assigned-trips/gantt-chart/gantt-chart.component';
import { UnAuthorizationGuard } from './service/non-auth-guard/unauthorization-guard.service';
import { StandingorderDetailModalComponent } from './component/assigned-trips/standingorder-detail-modal/standingorder-detail-modal/standingorder-detail-modal.component';
import { VehicleInspectionComponent } from './component/reports/inspection/vehicle-inspection/vehicle-inspection.component';
import { AddVehicleInspectionComponent } from './component/reports/inspection/add-vehicle-inspection/add-vehicle-inspection.component';
import { SignatureModalComponent } from './component/assigned-trips/signature-modal/signature-modal/signature-modal.component';
import { DailyVehicleInspectionComponent } from './component/reports/inspection/daily-vehicle-inspection/daily-vehicle-inspection/daily-vehicle-inspection.component';
import { VehicleDetailModalComponent } from './component/reports/inspection/daily-vehicle-inspection/daily-vehicle-detail-modal/vehicle-detail-modal.component';
import { TrfsTemplatesComponent } from './component/assigned-trips/trfs-templates/trfs-templates.component';
import { CtcClaimedTripsComponent } from './component/assigned-trips/offered/ctc-claimed-trips/ctc-claimed-trips.component';
import { AddCmsCodeComponent } from './component/cms-1500/add-cms-code/add-cms-code.component';
import { ListCmsCodesComponent } from './component/cms-1500/list-cms-codes/list-cms-codes.component';
import { NumericSortPipe } from './pipe/numeric-sort-pipe/numeric-sort.pipe';
import { TripGenerateModalComponent } from './component/assigned-trips/outstanding-generate-trip-modal/trip-generate-modal/trip-generate-modal.component';
import { ManualLineTripComponent } from './component/assigned-trips/manual-line-trip/manual-line-trip.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DriverShiftModalComponent } from './component/assigned-trips/driver-shift-modal/driver-shift-modal/driver-shift-modal.component';
import { AccidentalReportListComponent } from './component/accidental-report/accidental-report-list/accidental-report-list.component';
import { DriverLoadV2Component } from './component/assigned-trips/driver-load-v2/driver-load-v2.component';
import { DriverExpensesComponent } from './component/maintenances/driver-expenses/driver-expenses.component';
import { VehicleExpensesComponent } from './component/maintenances/vehicle-expenses/vehicle-expenses.component';
import { MaintenanceDetailModalComponent } from './component/maintenances/maintenance-detail-modal/maintenance-detail-modal.component';
import { MaintenanceStatsComponent } from './component/maintenances/maintenance-stats/maintenance-stats.component';
import { AssociateImageModalComponent } from './component/assigned-trips/associate-image-modal/associate-image-modal.component';
import { DriverScoreComponent } from './component/reports/driver-score/driver-score.component';
import { DriverGrievanceComponent } from './component/driver-grievance/driver-grievance/driver-grievance.component';
import { FormatTitleCasePipe } from './pipe/format-titlecase.filter/format-titlecase.filter.pipe';
import { DriverLoadWrapperComponent } from './component/assigned-trips/driver-load/driver-load-wrapper/driver-load-wrapper.component';
import { RoutingWrapperComponent } from './component/assigned-trips/outstanding/routing-wrapper/routing-wrapper.component';
import { InspectionWrapperComponent } from './component/reports/inspection/inspection-wrapper/inspection-wrapper.component';
import { PartialEditComponent } from './component/assigned-trips/partial-edit/partial-edit.component';
import { SortByNumberPipe } from './pipe/sortby-number/sort-by-number.pipe';
import { BtModalComponent } from './component/assigned-trips/bt-modal/bt-modal.component';
import { BtFacilityModalComponent } from './component/assigned-trips/bt-facility-modal/bt-facility-modal.component';
import { PopperDirective } from './directives/popper-tooltip/popper.directive';
import { AerotransModalComponent } from './component/assigned-trips/aerotrans-modal/aerotrans-modal.component';
import { OperationsV2Component } from './component/assigned-trips/operations-v2/operations-v2.component';
import { DispatchDriverTripsModalComponent } from './component/shared/slideouts/dispatch-driver-trips-modal/dispatch-driver-trips-modal/dispatch-driver-trips-modal.component';
import { DriverTimelinesV2Component } from './component/drivers/driver-timelines-V2/driver-timelines-v2/driver-timelines-v2.component';
import { NumberDirective } from './directives/number-only-directive/number-only-directive';
import { DriverAmendmentsComponent } from './component/drivers/driver-amendments/driver-amendments/driver-amendments.component';
import { AddAmendmentModalComponent } from './component/drivers/driver-amendments/add-amendment-modal/add-amendment-modal.component';
import { TripsMapDashboardModalComponent } from './component/assigned-trips/trips-map-dashboard-modal/trips-map-dashboard-modal.component';
import { TripPurposesListComponent } from './component/trip-purposes/trip-purposes-list/trip-purposes-list.component';
import { AddTripPurposesComponent } from './component/trip-purposes/trip-purposes-add/trip-purposes-add.component';
import { AddLevelOfServiceComponent } from './component/level-of-service/add-level-of-service/add-level-of-service.component';
import { LevelOfServiceComponent } from './component/level-of-service/level-of-service-list/level-of-service-list.component';
import { AddDriverAmendmentsComponent } from './component/drivers/driver-amendments/add-driver-amendments/add-driver-amendments.component';
import { AdminEarningReportComponent } from './component/superAdmin/admin-earning-report/admin-earning-report.component';
import { CompanyDriverEarningModalComponent } from './component/superAdmin/admin-earning-report/company-driver-earning-modal/company-driver-earning-modal.component';
import { UberTripModalComponent } from './component/uber/uber-trip-modal/uber-trip-modal.component';
import { ArrayFilterbykeyPipePipe } from './pipe/array-filterbykey-pipe/array-filterbykey-pipe.pipe';
import { ServiceAreasAddComponent } from './component/service-areas/service-areas-add/service-areas-add.component';
import { ServiceAreasListingComponent } from './component/service-areas/service-areas-listing/service-areas-listing.component';
import { AddTripServiceAreaModalComponent } from './component/assigned-trips/add-trip-service-area-modal/add-trip-service-area-modal.component';
import { environment } from 'src/environments/environment';
import { TripActivityLogComponent } from './component/assigned-trips/trip-activity-log/trip-activity-log.component';
import { GoogleMapsInitializer } from './service/googlemap-key-service/google-maps-initializer.service';
import { PrimaryDiagnosisListComponent } from './component/primary-diagnosis/primary-diagnosis-list/primary-diagnosis-list.component';
import { AddPrimaryDiagnosisComponent } from './component/primary-diagnosis/add-primary-diagnosis/add-primary-diagnosis.component';
import { AddModifierCodeComponent } from './component/modifier-codes/add-modifier-code/add-modifier-code.component';
import { ModifierCodeListComponent } from './component/modifier-codes/modifier-code-list/modifier-code-list.component';
import { UberTripsComponent } from './component/assigned-trips/farmout/uber-trips/uber-trips.component';
import { FarmoutTripsComponent } from './component/superAdmin/farmout-trips/farmout-trips.component';
import { FarmoutWrapperComponent } from './component/assigned-trips/farmout/farmout-wrapper/farmout-wrapper.component';
import { FarmoutTripsV2Component } from './component/assigned-trips/farmout/farmout-trips-v2/farmout-trips-v2.component';
import { FarmoutReceiptComponent } from './component/assigned-trips/farmout/farmout-receipt/farmout-receipt.component';
import { FarmoutReceiptModalComponent } from './component/assigned-trips/farmout/farmout-receipt-modal/farmout-receipt-modal.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 0,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: '#ffffff',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  swipeEasing: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInComponent,
    ForgotPasswordComponent,
    CreateNewTripComponent,
    RangePipe,
    FilterPipe,
    FilterByKeyPipe,
    DispatchDashboardComponent,
    StaffMainComponent,
    StaffsCreateComponent,
    EditUserProfileComponent,
    AutocompleteComponent,
    // TripsHistoryComponent,
    DriverListComponent,
    RolePipe,
    AccountStatusPipe,
    CreateDriverComponent,
    DriverProfileDashboardComponent,
    ImageModalComponent,
    CamelCasePipe,
    CorporateuserComponent,
    CorporateAddUserComponent,
    FleetListComponent,
    CreateFleetComponent,
    InviteSmsComponent,
    DriverDistributionsComponent,
    DriverDistributionsCreateComponent,
    DriverAlertComponent,
    HelpingMaterialListComponent,
    UrlSafePipe,
    ParseTripsComponent,
    NewTripComponent,
    AutocompleteComponent,
    ParseTripsComponent,
    DispatchDashboardComponent,
    UnassignedComponent,
    VerifiedTripLogsComponent,
    GooglePlaceDirective,
    NemtStatisticsComponent,
    NemtReportComponent,
    DynamicFareListComponent,
    DynamicFareAddComponent,
    HourPipePipe,
    MinPipePipe,
    EarningsComponent,
    TripDetailsComponent,
    AdjustmentComponent,
    DashboardComponent,
    TripFilterPipe,
    TripAssignModalComponent,
    TimeFormatDirective,
    CompanyNoteModalComponent,
    CancelTripModalComponent,
    // ConfirmModalComponent,
    TimePipe,
    TripDetailDialogComponent,
    BillerNoteModalComponent,
    CommonAlertComponent,
    DriverBreakdownComponent,
    SweepTripsModalComponent,
    TripsInProgressModalComponent,
    DatePipe,
    TodayTripsPipe,
    DashboardTripsDetailsComponent,
    NotificationDetailsComponent,
    // AccidnetsListComponent,
    // AddAccidentComponent,
    HelpingMaterialPdfComponent,
    // AccidentDetailInTailwindFormComponent,
    LogsComponent,
    MemberListComponent,
    CreateMemberComponent,
    DriverLoadComponent,
    OutstandingComponent,
    ClickStopPropagationDirective,
    DriverInvoicesComponent,
    DirtRoadComponent,
    StandingOrdersComponent,
    DriverTimelinesComponent,
    TripDetailModelComponent,
    OutstandingTripsModalComponent,
    // TripAdjustmentComponent,
    // CompletedCancelModalComponent,
    AdditionalNoteModalComponent,
    ZDatesComponent,
    SideNavComponent,
    NotificationsSlideoutComponent,
    DispatchInprogressTripsSlideoutComponent,
    SortByDatePipe,
    FormatTitleCasePipe,
    DriverEarningModalComponent,
    AddExpenseModalComponent,
    OutsideClickDirective,
    BrokerDropdownComponent,
    TripActionsDropdownComponent,
    ClockTimePipe,
    SortByPipe,
    EditTripModalComponent,
    AdjustedTripOdomterModalComponent,
    OfferedComponent,
    BrokerSettingComponent,
    BrokerSettingListComponent,
    AuthTokenComponent,
    AdminNemtReportComponent,
    CompaniesListComponent,
    CreateCompanyComponent,
    QuarterListComponent,
    CreatQuarterComponent,
    InvoiceSettingListComponent,
    AddressPickerComponent,
    CorporateAddonListComponent,
    AddCorporateAddonComponent,
    NemtProTipsComponent,
    ImagesliderDirective,
    StatisticsDetailsComponent,
    PoisitiveNumberDirective,
    ManifestComponent,
    ConvertPhoneNumberDirective,
    AdjustmentTripComponent,
    StandingOrdersV2Component,
    NemtSuperProTipsComponent,
    CreateTipsComponent,
    StandingOrderCloneModalComponent,
    GanttChartComponent,
    VehicleInspectionComponent,
    AddVehicleInspectionComponent,
    StandingorderDetailModalComponent,
    SignatureModalComponent,
    DailyVehicleInspectionComponent,
    VehicleDetailModalComponent,
    TrfsTemplatesComponent,
    CtcClaimedTripsComponent,
    AddCmsCodeComponent,
    ListCmsCodesComponent,
    NumericSortPipe,
    TripGenerateModalComponent,
    ManualLineTripComponent,
    DriverShiftModalComponent,
    AccidentalReportListComponent,
    DriverExpensesComponent,
    VehicleExpensesComponent,
    MaintenanceDetailModalComponent,
    MaintenanceStatsComponent,
    AssociateImageModalComponent,
    DriverLoadV2Component,
    DriverScoreComponent,
    DriverGrievanceComponent,
    DriverLoadWrapperComponent,
    RoutingWrapperComponent,
    InspectionWrapperComponent,
    PartialEditComponent,
    SortByNumberPipe,
    BtModalComponent,
    BtFacilityModalComponent,
    PopperDirective,
    AerotransModalComponent,
    OperationsV2Component,
    DispatchDriverTripsModalComponent,
    UberTripModalComponent,
    DriverTimelinesV2Component,
    NumberDirective,
    DriverAmendmentsComponent,
    AddAmendmentModalComponent,
    TripsMapDashboardModalComponent,
    TripPurposesListComponent,
    AddTripPurposesComponent,
    AddLevelOfServiceComponent,
    LevelOfServiceComponent,
    AddDriverAmendmentsComponent,
    AdminEarningReportComponent,
    CompanyDriverEarningModalComponent,
    ArrayFilterbykeyPipePipe,
    ServiceAreasAddComponent,
    ServiceAreasListingComponent,
    AddTripServiceAreaModalComponent,
    TripActivityLogComponent,
    PrimaryDiagnosisListComponent,
    AddPrimaryDiagnosisComponent,
    AddModifierCodeComponent,
    ModifierCodeListComponent,
    UberTripsComponent,
    FarmoutTripsComponent,
    FarmoutWrapperComponent,
    FarmoutTripsV2Component,
    FarmoutReceiptComponent,
    FarmoutReceiptModalComponent
    ],

  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SignaturePadModule,
    ReactiveFormsModule,
    TimeagoModule.forRoot(),
    HttpClientModule, // import HttpClientModule
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({ exclude:['http://provider.qalbs.com/api/assigns/dashboard', 'http://provider.qalbs.com/api/notifications', 'http://provider.qalbs.com/api/assigns/corporate',
    'http://provider.qalbs.com/api/users/me',  'http://provider.qalbs.com/api/drivers/nearby'],
     showForeground: true }),
     CircleTimerModule,
    AgmCoreModule.forRoot({
      apiKey: '',
      libraries: ['places']
    }),
    SocketIoModule,
    TimezonePickerModule,
    AgmDirectionModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    PerfectScrollbarModule,
    AgmOverlays,
    OwlModule,
    ToastrModule.forRoot({
    timeOut: 3000,
    positionClass: 'toast-bottom-left',
    preventDuplicates: true,
    progressBar:true
    }),
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot()
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [LAZY_MAPS_API_CONFIG, GoogleMapsInitializer],
      multi: true,
      useFactory: (config: LazyMapsAPILoaderConfigLiteral, initializer: GoogleMapsInitializer) => () => initializer.initialize(config),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: UnAuthorizationGuard, useClass: UnAuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe,
    FormatTitleCasePipe,
    MySocketWrapper,
    SocketService,
    CamelCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
