import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-broker-dropdown',
  templateUrl: './broker-dropdown.component.html',
  styleUrls: ['./broker-dropdown.component.css']
})
export class BrokerDropdownComponent implements OnInit {
  @Output() selectedBroker = new EventEmitter<any>();
  showList = false;
  submitted = false;
  brokers = [
    {
      name : "Logisticare",
      imageURL : "assets/img/logisticare.png",
      value : "logisticscare",
      isShow: false
    },
    {
      name : "MTM",
      imageURL : "assets/img/mtm.png",
       isShow: false,
      value : "first"
    },
    {
      name : "CalOptima",
      imageURL : "assets/img/caloptima.png",
       isShow: false,
      value : "caloptima"
    },
    {
      name : "MED Trans",
      imageURL : "assets/img/medtrans.png",
       isShow: false,
      value : "medtrans"
    },
    {
      name : "Safe Ride",
      imageURL : "assets/img/saferide.png",
      isShow: false,
      value : "saferide"
    },
    {
      name : "Tennessee Carriers",
      imageURL : "assets/img/tncarrier.png",
      isShow: false,
      value : "tncarrier"
    },
    {
      name : "VEYO",
      imageURL : "assets/img/veyo.png",
      isShow: false,
      value : "veyo"
    },
    {
      name : "Southeast Trans",
      imageURL : "assets/img/southeastrans.png",
       isShow: false,
      value : "united"
    },
    {
      name : "LCP",
      imageURL : "assets/img/lcp.png",
       isShow: false,
      value : "lcp"
    },
    {
      name : "EPIC",
      imageURL : "assets/img/epic.png",
       isShow: false,
      value : "epic"
    },
    {
      name : "MAS",
      imageURL : "assets/img/mas.png",
       isShow: false,
      value : "mas"
    },
    {
      name : "ABC Insurance",
      imageURL : "assets/img/abcinsurance.png",
       isShow: false,
      value : "abcinsurance"
    },
    {
      name : "Call The Car",
      imageURL : "assets/img/callthecar.png",
       isShow: false,
      value : "callthecar"
    },
    {
      name : "Access2Care",
      imageURL : "assets/img/access2Ride.png",
       isShow: false,
      value : "access2Ride"
    },
    {
      name : "Welltrans",
      imageURL : "assets/img/welltrans.png",
       isShow: false,
      value : "welltrans"
    },
    {
      name : "Ride2MD",
      imageURL : "assets/img/ride2md.png",
       isShow: false,
      value : "ride2md"
    },
    {
      name : "American Logistics",
      imageURL : "assets/img/americanLogistics.png",
       isShow: false,
      value : "americanLogistics"
    },
    {
      name : "Provide A Ride",
      imageURL : "assets/img/providearide.png",
       isShow: false,
      value : "providearide"
    },
    {
      name : "One Call",
      imageURL : "assets/img/onecallcare.png",
       isShow: false,
      value : "onecall"
    },
    {
      name : "FACT SD",
      imageURL : "assets/img/factSd.png",
      isShow: false,
      value : "factSd"
    },
    {
      name : "Valley Metro",
      imageURL : "assets/img/valley-metro.png",
      isShow: false,
      value : "valleymetro"
    },

    {
      name : "CareCar",
      imageURL : "assets/img/carecar.png",
      isShow: false,
      value : "carecar"
    },
  ]
  constructor() { }
  selectedCompanyName = null;
  ngOnInit() {
  }
  selectedCompany(companyName) {
    this.submitted = true;
    this.selectedCompanyName = companyName;
    this.showList = !this.showList;
    this.selectedBroker.emit(companyName);
  }
  getSelectedBroker(){
    return this.brokers.find(data => data.value === this.selectedCompanyName)
  }

  closeDropdown(){
    this.showList = false;
  }
}
