import { Component, OnInit } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { DriversService } from 'src/app/component/drivers/drivers-service/drivers-service';
import { ServiceAreasService } from 'src/app/component/service-areas/service-areas-service/service-areas.service';
import { MenuService } from 'src/app/component/shared/layout/menu-service/menu.service';
import { CsvService } from 'src/app/service/csv-service/csv.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from 'src/app/utils/utils.common';
import { AssigTripsService } from '../../assigntrips-service/assigntrips-service';
import * as _ from 'underscore';
import { FarmoutService } from 'src/app/service/farmout-service/farmout.service';

@Component({
  selector: 'app-farmout-trips-v2',
  templateUrl: './farmout-trips-v2.component.html',
  styleUrls: ['./farmout-trips-v2.component.css']
})
export class FarmoutTripsV2Component implements OnInit {

 duration = 10 * 1000; // 10 seconds
  filterdataList = [];
  todayDate;
  isShowAllTrips = false;
  search: any;
  timeZone;
  user;
  completedTripModalObj;
  tripDetailModalObj;
  skip = 0;
  editTripModalObj;
  partialEditModalObj;
  clientId;
  vehicles;
  drivers;
  selectedDriver = [];
  driverListMain = [];
  filteredDrivers = [];
  isShowStatusDropdown = false;
  tripStatuses = [
    { label: 'Unassigned', status: 'pending', isChecked: false },
    { label: 'Assigned', status: 'hanging', isChecked: false },
    { label: 'En Route', status: 'onway', isChecked: false },
    { label: 'Arrived', status: 'arrived', isChecked: false },
    { label: 'In Progress', status: 'started', isChecked: false },
    { label: 'Completed', status: 'finished', isChecked: true },
    { label: 'Cancelled', status: 'cancelled', isChecked: false },
  ];
  selectedStatus: any = "active";
  driverList = [];
  corporateUsers = [];
  trip;
  payors = {
    medicaid: "Medicaid",
    medicare: "Medicare",
    medical: "MediCal",
    optum: "Optum",
    privatePay: "Private Pay",
    billFacility: "Bill Facility",
    tricare: "Tricare",
    champva: "CHAMPVA",
    federalemployees: "Federal Employees",
    fecaBlackLung: "FECA Black Lung",
    other: "Other"
  };

  subMenus= [];
  status = "";
  isUberModal = false;
  uberDetails;
  selectedTripId;
  preServiceAreas = {}
  dataList;
  jobStatusCounts:any;
  totalPreSpecialRate:any;
  farmoutTotalFare:any;

  kpis:any = {};

  constructor(
    private assignTripService: AssigTripsService,
    private sharedDataService: SharedDataService,
    private driverService: DriversService,
    private menuService: MenuService,
    private socketService: SocketService,
    private csvService:CsvService,
    private serviceAreasService : ServiceAreasService,
    private farmoutService: FarmoutService


  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
          this.getCorporateUserList();
          this.getServiceAreas();
          this.getTrips();

        }
      });
  }

  getDriverList() {
    this.driverService.getDrivers(this.user._id, this.selectedStatus)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.driverListMain = data;
            this.driverList = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
          }
        },
      );
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }


  getTrips(){
    const queryParams = {
      company: this.user && this.user.company ? this.user.company._id : this.user._id,
      date: moment(this.todayDate).utc().toISOString()
    }
    this.farmoutService.getTrips(queryParams).subscribe((data)=>{
      if(data){
        this.dataList = data;
        this.filterdataList = this.dataList
        this.getKPI();
      }

    })
  }


  getKPI() {
    const queryParams = {
      company: this.user && this.user.company ? this.user.company._id : this.user._id,
      date: moment(this.todayDate).utc().toISOString()
    }
    this.farmoutService.getKPIDetails(queryParams).subscribe((data) => {
      if (data) {
        this.kpis = data;
      }
    })
  }


  toggleStatusDropdown() {
    this.isShowStatusDropdown = !this.isShowStatusDropdown
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.getTrips();
  }

  clearTrips() {
    this.skip = 0;
    this.filterdataList = [];
    this.dataList = [];
    this.filteredDrivers = [];
  }

  getJobStatusCounts(filterdataList) {
    return {
      finished: filterdataList.filter(trip => trip.panel.jobStatus === "finished").length,
      canceled: filterdataList.filter(trip => trip.panel.jobStatus === "canceled").length,
      inprogress: filterdataList.filter(trip => trip.panel.jobStatus === "inprogress").length,
    };
  }
  // getTotalPreSpecialRate(filterdataList: any[]): number {
  //   return filterdataList.reduce((total, trip) => total + (parseInt(trip.preSpecialRate) || 0), 0);
  // }

  getTotalPreSpecialRate(filterdataList: any[]){
    return filterdataList.reduce((total, trip) => {
      if (!trip.panel.companyType) {
        return total + parseInt(trip.panel.customerSpecialRate || 0);
      }
      return total + parseInt(trip.panel.preSpecialRate || 0);
    }, 0);
  }

  getFarmoutTotalFare(filterdataList: any[]): number {
    return filterdataList.reduce((total, trip) => {
      if (trip && trip.uber && trip.uber.fare.value){
        return total + (trip.uber.fare.value || 0);
     }else if(trip && trip.farmout && trip.farmout.fare.value){
        return total + (trip.farmout.fare.value || 0);
     }
     else {
      return total
     }
    }, 0);
  }



  filterTrips() {
    this.filterdataList = this.dataList.filter(trip => {
      let isDriverExist = true;
      if (this.selectedDriver && this.selectedDriver.length) {
        if (trip.driver && trip.driver._id) {
          isDriverExist = this.selectedDriver.includes(trip.driver._id);
        } else {
          isDriverExist = false;
        }
      }
      if (isDriverExist) {
        return true;
      } else {
        return false;
      }
    });
    this.jobStatusCounts = this.getJobStatusCounts(this.filterdataList);
    this.totalPreSpecialRate = this.getTotalPreSpecialRate(this.filterdataList);
    this.farmoutTotalFare = this.getFarmoutTotalFare(this.filterdataList);
  }


  editTrip(assign) {
    this.editTripModalObj = assign._id;
    this.trip = assign;
  }

  onEditTrip(event) {
    if (event) {
      const idx = this.filterdataList.findIndex(t => t._id === event._id);
      // const index = this.filterdataList.findIndex(event._id);

      if (moment(event.appointmentTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].appointmentTime).format("YYYY-MM-DD") || moment(event.scheduleTime).format("YYYY-MM-DD") !== moment(this.filterdataList[idx].scheduleTime).format("YYYY-MM-DD")) {
        // this.clearTrips();
        this.filterdataList.splice(idx, 1);
      } else {
        this.filterdataList[idx] = event;
      }
      if (event.status === 'archived') {
        this.filterdataList.splice(idx, 1);
      }

      if (event.jobStatus === 'cancelled' || event.status === 'cancelled') {
        const isCancelledSelected = this.tripStatuses.find(ts => ts.status === 'cancelled' && ts.isChecked);
        if (!isCancelledSelected) {
          this.filterdataList.splice(idx, 1);
        }
      }
      this.editTripModalObj = null;
    }
  }

  viewCompletedTripDetails(trip) {
    this.clientId = trip.priorityClient.clientId;
    this.completedTripModalObj = trip;
  }

  openUberModal(uberTrip) {
    this.uberDetails = uberTrip
    this.isUberModal = true
  }

  getDriverByID(id) {
    return this.driverList[id];
  }

  getCorporateByID(id) {
    return this.corporateUsers[id];
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  trackByFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  viewTripDetails(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.driver = this.getDriverByID(trip.driver);
    this.tripDetailModalObj = obj;
  }

  updateTripData(evt) {
    if (this.filterdataList && this.filterdataList.length) {
      const selectedTrip = this.filterdataList.find((x) => x._id === evt._id);
      if (selectedTrip) {
        selectedTrip.attachment = evt.attachment;
        selectedTrip.companyNote = evt.companyNote;
        selectedTrip.dispatchNoteTwo = evt.dispatchNoteTwo;
      }
    }
  }

  setPartialEditModal(trip) {
    const obj = JSON.parse(JSON.stringify(trip));
    obj.cooperate = this.getCorporateByID(trip.cooperate);
    this.partialEditModalObj = obj;
  }

  getServiceAreas() {
    this.serviceAreasService
      .getServiceAreas({ status: 'active' })
      .subscribe(data => {
        if (data && data.length) {
          this.preServiceAreas = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }


  closeUberModal(evt) {
    this.isUberModal = evt
  }


  convertTime(time) {
    if (!time) return 'N/A';
    return moment(time).tz(this.user.timeZone).format("MM/DD/YY HHmm")
  }

  ngOnDestroy(): void {
  }

}
