import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setFormData, setFormDataV2 } from '../../../utils/network-utils';
import { ActivatedRoute } from '@angular/router';
import { extend } from 'lodash';
import * as moment from 'moment';
@Injectable({
    providedIn: 'root'
})
export class DriversService {
   BREAK_STATUS = {
    OPEN: 'open',
    REST: 'rest',
    APPROVED: 'approved'
  };

   BREAK_THRESHOLD = {
    MIN_HOURS_FOR_FIRST_REST: 3.5,
    MAX_HOURS_FOR_ONLY_REST: 5,
    MAX_HOURS_FOR_REST_AND_MEAL: 6,
    MAX_HOURS_FOR_TWO_REST_ONE_MEAL: 10,
    MAX_HOURS_FOR_THREE_REST_TWO_MEALS: 14
  };

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    }

    getDrivers(id, endPoint): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_URL + `/${id}/${endPoint}`)
            .pipe(
                catchError(errorHandl)
            );
    }
    getUberDrivers(): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.UBER_DRIVERS_URL)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriver(driverData, name?): Observable<any> {
        const driverId = driverData.driverId;
        const imageFiles = driverData.images;
        delete driverData.images;
        delete driverData.driverId;

        const startingPointAddress = driverData.startingPointAddress;
        delete driverData.startingPointAddress;

        const formData = setFormData(imageFiles, driverData,name? name: 'driver');
        if (startingPointAddress) {
            Object.keys(startingPointAddress).forEach(key => formData.append(`${name ? name: 'driver'}[startingPointAddress][${key}]`, startingPointAddress[key]));
        }
        if (driverId) {
            return this.http.put<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + '/' + driverId, formData).pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.ADD_DRIVER_URL, formData)
                .pipe(
                    catchError(errorHandl)
                );
        }

    }

    getDriverProfile(id): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + `/${id}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    getDriversByID(id): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + `/${id}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    resetDriverPassword(driverPasswordData): Observable<any> {
        const id = driverPasswordData.driverId;
        delete driverPasswordData['driverId'];
        return this.http.put<any>(urls.BASE_URL + urls.DRIVER_RESET_PASSWORD_URL + `/${id}/`, driverPasswordData)
            .pipe(
                catchError(errorHandl)
            );
    }

    getDriversSms(data): Observable<any> {
        // console.log("path",this.activatedRoute.snapshot.url[0])
        if (data.endPoint === 'queries') {
            return this.http.put<any>(urls.BASE_URL + urls.QUERIES_URL + '/' + data.driverObj._id, data.driverObj)
                .pipe(
                    catchError(errorHandl)
                );
        } else if (data.endPoint === 'driveralerts') {
            return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_SMS_URL + data.id)
                .pipe(
                    catchError(errorHandl)
                );
        }
    }

    getQueriesUserList(): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.QUERIES_URL)
            .pipe(
                catchError(errorHandl)
            );
    }

    getNearbyDrivers(requestData): Observable<any> {

        return this.http.post<any>(urls.BASE_URL + urls.GET_NEARBY_DRIVER_URL, requestData)
            .pipe(
                catchError(errorHandl)
            );
    }

    assignFleet(payload, endPoint): Observable<any> {

        if (typeof payload === 'string') {

        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${endPoint}`, { fleetId: payload })
            .pipe(
                catchError(errorHandl)
            );

        } else {
            return this.http.put<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${endPoint}`, payload)
                .pipe(
                    catchError(errorHandl)
                );

        }
    }

    getDriverInvoice(payload) {
      return this.http.post(
        urls.BASE_REPORT_SERVER_URL + urls.DRIVER_CALCULATE_INVOICE_URL, payload)
        .pipe(
            catchError(errorHandl)
        );
    }

    getDriverInvoiceReport(payload) {
        return this.http.put(
          urls.BASE_REPORT_SERVER_URL + urls.DRIVER_CALCULATE_INVOICE_URL, payload)
          .pipe(
              catchError(errorHandl)
          );
      }

    getDriverTimelines(queryString) {
        let endpoint = '/current';
        if (queryString && queryString.dates) {
            endpoint = '';
        }

        let query = '?driver=' + queryString.driver
        if (queryString.dates) {
            query = query + '&dates=' + encodeURI(JSON.stringify(queryString.dates))
        }

        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + endpoint + query)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriverBreak(payload) {
        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL, payload)
            .pipe(
                catchError(errorHandl)
            );

    }

    getDriverTimelineById(id) {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + '/' + id)
            .pipe(
                catchError(errorHandl)
            );
    }
    deleteDriverTimelineById(id) {
        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + '/' + id,{})
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriverTimeline(payload) {
        if (payload._id) {
            return this.http.put<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + '/' + payload._id, payload)
            .pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL, payload)
            .pipe(
                catchError(errorHandl)
            );
        }

    }

    getDriverTimelinesReport(queryString) {
        let query = '?dates=' + encodeURI(JSON.stringify(queryString.dates)) + '&isReport=true'
        if (queryString.driver) {
            query = query + '&driver=' + queryString.driver;
        }

        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + query)
            .pipe(
                catchError(errorHandl)
            );
    }

    generateDriverQR(driverId) {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${driverId}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    uploadSignature(payload){
        const image = {driverSignatureImage: payload.driverSignatureImage}
        const formData = setFormData(image, null, 'driver');
        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_SIGNATURE_URL + '/' +  payload._id, formData)
        .pipe(
            catchError(errorHandl)
        );
    }

    getDriverData() {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_DASHBOARD_URL)
            .pipe(
                catchError(errorHandl)
            );
    }

    getAmendments(){
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_AMENDMENTS_URL)
            .pipe(
                catchError(errorHandl)
            );
    }

    getAmendmentsDetail(id){
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_AMENDMENTS_URL + `/${id}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveAmendment(payload) {
            return this.http.post<any>(urls.BASE_URL + urls.DRIVER_AMENDMENTS_URL, payload)
            .pipe(
                catchError(errorHandl)
            );

  }

  updateAmendment(payload) {
    if (payload._id) {
        return this.http.put<any>(urls.BASE_URL + urls.DRIVER_AMENDMENTS_URL + '/' + payload._id, payload)
        .pipe(
            catchError(errorHandl)
        );
    }

}

    getDriverCurrentTimelines(queryParams) {
        // https://provider.qalbs.com/api/timelines/current?startDate=2024-01-08T12%3A39%3A48.507Z
        // , { params }
        // '?startDate=2024-01-08T13%3A51%3A12.915Z'
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_CURRENT_TIMELINES, {params})
            .pipe(
                catchError(errorHandl)
            );
  }


// break logix
  ammendmentCalculations(timeline, amendment, timeZone) {
    if (amendment.breaks.length) {
      amendment.breaks.forEach(b => {
        let lastBreakTimeEnd = moment(b.stop).tz(timeZone);
        let lastBreakTimeStart = moment(b.start).tz(timeZone);
        b.duration = lastBreakTimeEnd.diff(lastBreakTimeStart);
        b.value = lastBreakTimeEnd.diff(lastBreakTimeStart, 'minutes');
        if (b.action === 'add') {
          b.status = 'approved';
          b.source = 'dispatcher';
          b.label = 'Driver is on meal break'
        }
      });
    }
    if (timeline.breaks.length) {
      for (const b of amendment.breaks) {
        if (b.dispatcherAction === 'approved') {
          if (b.action === 'add') {
            timeline.breaks.push(b);
          } else {
            const index = timeline.breaks.findIndex(item => item._id === b.breakId);
            if (index !== -1) {
              if (b.action === 'remove') {
                timeline.breaks.splice(index, 1);
              } else if (b.action === 'update') {
                timeline.breaks[index] = extend(timeline.breaks[index], b);
              }
            }
          }
        }
      }
    }
    // Calculate break mins and works hours



    const start = moment(amendment.checkin).tz(timeZone, false);
    const now = moment(amendment.checkout).tz(timeZone, false);
    const restBreaks = this.getBreaksCount(timeline, this.BREAK_STATUS.REST);
    const mealBreaks = this.getBreaksCount(timeline,this.BREAK_STATUS.APPROVED);
    const hoursDifference = now.diff(start, 'hours', true);
    const { dueRestBreaks, dueMealBreaks } = this.calculateDueBreaks(hoursDifference, restBreaks, mealBreaks);
    let breakTimeInMillis = 0;
    let restBreakTimeInMillis = 0;
    timeline.breaks.forEach(b => {
      if (b.status === 'approved') {
        breakTimeInMillis += b.duration;
      }
      if (b.status === 'rest') {
        restBreakTimeInMillis += b.duration;
      }
    });
    const response = {
      ...timeline,
      checkin: amendment.checkin, checkout: amendment.checkout,
      breakTimeInMillis, restBreakTimeInMillis, workTimeInMillis: now.diff(start) - breakTimeInMillis,
      restTaken: restBreaks, restDue: dueRestBreaks, mealTaken: mealBreaks, mealDue: dueMealBreaks,
    };
    return response;
  }

  getBreaksCount(time, status) {
    return time.breaks.filter(b => b.status === status).length;
  }

   calculateDueBreaks(hoursDifference, restBreaks, mealBreaks) {
    let dueRestBreaks = 0;
    let dueMealBreaks = 0;

    if (hoursDifference >= this.BREAK_THRESHOLD.MIN_HOURS_FOR_FIRST_REST && hoursDifference <= this.BREAK_THRESHOLD.MAX_HOURS_FOR_ONLY_REST) {
      dueRestBreaks = 1 - restBreaks;
    } else if (hoursDifference > this.BREAK_THRESHOLD.MAX_HOURS_FOR_ONLY_REST && hoursDifference <= this.BREAK_THRESHOLD.MAX_HOURS_FOR_REST_AND_MEAL) {
      dueRestBreaks = 1 - restBreaks;
      dueMealBreaks = 1 - mealBreaks;
    } else if (hoursDifference > this.BREAK_THRESHOLD.MAX_HOURS_FOR_REST_AND_MEAL && hoursDifference <= this.BREAK_THRESHOLD.MAX_HOURS_FOR_TWO_REST_ONE_MEAL) {
      dueRestBreaks = 2 - restBreaks;
      dueMealBreaks = 1 - mealBreaks;
    } else if (hoursDifference > this.BREAK_THRESHOLD.MAX_HOURS_FOR_TWO_REST_ONE_MEAL && hoursDifference <= this.BREAK_THRESHOLD.MAX_HOURS_FOR_THREE_REST_TWO_MEALS) {
      dueRestBreaks = 3 - restBreaks;
      dueMealBreaks = 2 - mealBreaks;
    } else if (hoursDifference > this.BREAK_THRESHOLD.MAX_HOURS_FOR_THREE_REST_TWO_MEALS) {
      dueRestBreaks = 4 - restBreaks;
      dueMealBreaks = 2 - mealBreaks;
    }

    return { dueRestBreaks, dueMealBreaks };
  };
}
