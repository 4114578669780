import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';
import { SharedDataService } from '../shared-service/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class FarmoutService {

  constructor(private http : HttpClient, private sharedDataService: SharedDataService) { }

  fareEstimation(payload) {
    const url = urls.UBER_BASE_URL + "trips/estimates";
    const hmac = this.sharedDataService.generateHmac(url,'POST', payload);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });
    let options = { headers: authHeader };

    return this.http
      .post<any>(
        url,
        payload,
        options
      )
      .pipe(catchError(errorHandl));
  }

  rideRequest(payload) {
    const url = urls.UBER_BASE_URL + "trips";
    const hmac = this.sharedDataService.generateHmac(url, 'POST', payload);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });
    let options = { headers: authHeader };
    return this.http
      .post<any>(
       url,
        payload,
        options
      )
      .pipe(catchError(errorHandl));
  }

  cancelRideRequest(requestId, payload) {
    const url = urls.UBER_BASE_URL + "trips/cancel/" + requestId;
    const hmac = this.sharedDataService.generateHmac(url, 'POST', payload);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });
    let options = { headers: authHeader };
    return this.http
      .post<any>(
       url, payload,
        options
      )
      .pipe(catchError(errorHandl));
  }

  rideDetailsRequest(payload) {
    const url = urls.UBER_BASE_URL + "trips/" + payload.requestId;
    const hmac = this.sharedDataService.generateHmac(url, 'POST', {company : payload.company});

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };
    return this.http
      .post<any>(
        url, {company : payload.company},
        options
      )
      .pipe(catchError(errorHandl));
  }

  rideReceiptRequest(requestId, payload) {
    const url =  urls.UBER_BASE_URL + "trips/receipt/" + requestId;
    const hmac = this.sharedDataService.generateHmac(url, 'POST', payload);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };
    return this.http
      .post<any>(
       url, payload,
        options
      )
      .pipe(catchError(errorHandl));
  }

  tipRequest(payload) {

    const url =  urls.UBER_BASE_URL + "trips/tip/" + payload.request_id;
    const hmac = this.sharedDataService.generateHmac(url, 'POST', payload);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };

    return this.http
      .post<any>(
        url,
        payload,
        options
      )
      .pipe(catchError(errorHandl));
  }


  getReciptData(queryParams) {
    const url = urls.UBER_BASE_URL + urls.GET_RECEIPTS+"?company="+queryParams.company +"&date="+ queryParams.date ;
    const hmac = this.sharedDataService.generateHmac(url,'GET', null);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };

    return this.http.get(url, options)
      .pipe(catchError(errorHandl));
  }


  getTrips(queryParams) {
    const url = urls.UBER_BASE_URL +  "trips/company/all?company=" + queryParams.company + "&date=" + queryParams.date;
    const hmac = this.sharedDataService.generateHmac(url, 'GET', null);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };
    return this.http.get<any>(url,options)
      .pipe(catchError(errorHandl));
  }

  getKPIDetails(queryParams) {
    const url = urls.UBER_BASE_URL + "trips/dashboard2?company=" + queryParams.company + "&date=" + queryParams.date;
    const hmac = this.sharedDataService.generateHmac(url, 'GET', null);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };
    return this.http.get<any>(url, options)
      .pipe(catchError(errorHandl));
  }

  getReceiptStats(queryParams) {
    const url = urls.UBER_BASE_URL + urls.GET_RECEIPTS_STATS+"?company="+queryParams.company +"&date="+ queryParams.date ;
    const hmac = this.sharedDataService.generateHmac(url,'GET', null);

    let authHeader = new HttpHeaders({
      "content-type": "application/json",
      "x-hmac": `HMAC ${hmac}`
    });

    let options = { headers: authHeader };

    return this.http.get(url, options)
      .pipe(catchError(errorHandl));
  }
}
