import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-farmout-receipt-modal',
  templateUrl: './farmout-receipt-modal.component.html',
  styleUrls: ['./farmout-receipt-modal.component.css']
})
export class FarmoutReceiptModalComponent implements OnInit {
  
  @Input() receiptModalObj:any;
  @Output() onCloseModal = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.onCloseModal.emit();
  }

}
