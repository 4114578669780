import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { urlToArrayBuffer, getImageAsEmbedNew, removeNonAllowedCharactersFromPDF } from 'src/app/utils/utils.common';

@Injectable({
  providedIn: 'root'
})
export class CorporateReportService {

  constructor() { }

  async fillPDF(pdfBuffer: ArrayBuffer, trips, user, corporate,dateRange): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();
    let arr = [];

    const company = user.company ? user.company : user;
    if (corporate) {
      form.getTextField("corporate_name").setText(corporate.displayName);
      form.getTextField("corporate_address").setText(corporate.address);
      form.getTextField("corporate_phone").setText(corporate.contactNumber);
      form.getTextField("fundingsource_num").setText(corporate.displayName.slice(0,4).toUpperCase() + '-'+ moment().format("YYYY-MM"));
    }
    if(dateRange){
      form.getTextField("date_range").setText(moment(dateRange[0]).format("MM/DD/YYYY") + '-'+  moment(dateRange[1]).format("MM/DD/YYYY"));
    }
      for (const [index, trip] of trips.entries()) {
        if(trip){
          form.getTextField("Id" + index).setText(trip.tripId);
          form.getTextField("created_date" + index).setText(moment(trip.createdAt).format("MM/DD/YYYY : HH:mm"));
          form.getTextField("patient_name" + index).setText(trip.priorityClient.displayName);
          form.getTextField("pu_address" + index).setText(trip.jobOriginAddress);
          form.getTextField("do_address" + index).setText(trip.jobDestinationAddress);
          for(let item of trip.addonServices){
            arr.push(item.title +" $"+item.fare)
          }
          form.getTextField("desc" + index).setText(trip.tripRequirement + " " + arr.toString().replace(/,/g, ", "));
          if (trip.job && trip.job.jobFareDetail){
            form.getTextField("charge" + index).setText('$'+trip.job.jobFareDetail.jobFee);
          }
        }
    }


      const jpgImageBytes: any = await urlToArrayBuffer(company.profileImageURL);
      const pngImage = await getImageAsEmbedNew(pdfDoc, company.profileImageURL, jpgImageBytes);
      const jpgImageBytes1: any = await urlToArrayBuffer(corporate.profileImageURL);
      const corporateLogo = await getImageAsEmbedNew(pdfDoc, corporate.profileImageURL, jpgImageBytes1);
        if (corporateLogo){
          form.getTextField('corporate_logo').setImage(corporateLogo);
      }

      if (pngImage)
      form.getTextField('company_logo').setImage(pngImage);
      form.getTextField("company_address").setText(company.address);
      form.getTextField("company_phone").setText(company.contactNumber);



    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }

  async getCorporateReport(payload, company, corporate,dateRange,loader) {
    const pdfArray = [];
    let newtemp = []
    for (const [index, item] of payload.entries()) {
      newtemp.push(item)
      if (index === 14) {
        const response = await fetch("assets/documents/corporate-invoice.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, corporate,dateRange);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      } else if (index > 14 && (index - 14) % 14 === 0) {
        const response = await fetch("assets/documents/corporate-invoice-blank.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, corporate,dateRange);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      }
    }

    if (!pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/corporate-invoice.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, corporate,dateRange);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    } else if (pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/corporate-invoice-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, corporate,dateRange);
      pdfArray.push(pdfBuffer);

    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]);
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const currentDate = moment().format("MM-DD-YYYY");
      const fileName = `corporate_invoice_${currentDate}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      loader.isLoading = false
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

}
