import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { PAYOR, getBrokersList, timeCalculation } from '../../../utils/utils.common';
import { KpisService } from '../../kpis/kpis-service/kpis.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-trip-detail-model',
  templateUrl: './trip-detail-model.component.html',
  styleUrls: ['./trip-detail-model.component.css']
})
export class TripDetailModelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('tripDetailModalObj') trip: any;
  @Input() source: any;
  @Input() corporatesByKey: any
  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  @Output() onCloseModal = new EventEmitter<string>();
  payorList = PAYOR;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  eta;
  contact;
  brokers = getBrokersList;
  tripData: any;
  driverDetails;
  trackinURL; 
  user;
  isUberIntegeration = false


  constructor(
    private assignTripService: AssigTripsService,
    private kpisService: KpisService,
    private driverService: DriversService,
    private sharedDataService : SharedDataService
  ) { }

  ngOnInit() {
    this.trackinURL = environment.TRACKING_URL;
    this.getUser();
    if (this.source === "statistics") {
      this.getTripDetails();
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          if (user.hasOwnProperty('isUber')) {
            this.isUberIntegeration = user.isUber
          }
          this.getTrips();
        }
      });
  }

  getTrips() {
    this.assignTripService
      .tripDetail(this.trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (data) => {
          if (data) {
            this.tripData = data;
            if(this.tripData.driver){
              this.getDriverDetails(this.tripData.driver);
            }
            if (data.priorityClient) {
              this.contact = data.priorityClient.contactNumber;
            }
          }
        });
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  getDriverDetails(id) {
    this.driverService
      .getDriverProfile(id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.driverDetails = data;
        }
      });
  }

  ngAfterViewInit() {
    // this.agmElement.mapReady
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((map) => {
    //   this.agmMap = map;
    // setTimeout(() => { this.setBounds(); }, 500);
    // });
  }

  getBroker(key) {
    // console.log(key)
    return getBrokersList[key];
  }

  // setBounds() {
  //   if (this.agmMap) {
  //     let bounds = new google.maps.LatLngBounds();
  //     bounds.extend(
  //       new window["google"].maps.LatLng(
  //         this.trip.jobOriginLatitude,
  //         this.trip.jobOriginLongitude
  //       )
  //     );

  //     bounds.extend(
  //       new window["google"].maps.LatLng(
  //         this.trip.jobDestinationLatitude,
  //         this.trip.jobDestinationLongitude
  //       )
  //     );

  //     this.agmMap.setCenter(bounds.getCenter());
  //     this.agmMap.fitBounds(bounds);
  //     // console.log(bounds);
  //   }
  // }

  getETA(tripId) {
    this.assignTripService
      .getETA(tripId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.etaMessage) {
          this.eta = data.etaMessage;
        }
      });
  }

  sendSMS() {
    if (!this.contact) return;
    this.trip.contactNumber = this.contact;

    const payload = {
      ...this.trip,
      tripId: this.trip._id,
      latitude: this.trip.driver.latitude,
      longitude: this.trip.driver.longitude,
    }
    this.assignTripService
      .sendTrackingSMS(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          // this.contact = '';
          if (data.success) {
            sweetAlert('Success', data.message, 'success', 'OK');
          } else {
            sweetAlert('Error', data.message, 'error', 'OK');
          }
        }
      });
  }

  getTripDetails() {
    this.kpisService.getNemtStatsById(this.trip._id)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe(data => {
      if (data) {
        this.trip = data;
      }
    })
  }

  getJobStatus(status) {
    let tripStatus = status;

    if (status === 'pending') {
      tripStatus = 'Unassigned';
    } else if (status === 'arrived') {
      tripStatus = 'On Scene';
    } else if (status === 'started') {
      tripStatus = 'Member Onboard';
    }

    return tripStatus
  }

  breakString(str) {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }


  getPayorTypeTitle(corporateId, payorType){
    if(!this.corporatesByKey) return;

    let payor;
    let types = this.corporatesByKey[corporateId];
    if (types && types.length) {
      payor = types.find(t => t.value === payorType);
    }
    return payor ? payor.title: '';
}


  ngOnDestroy(): void {
  }
}
