import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { Router, ActivatedRoute } from '@angular/router';
import { PAYOR, getBrokersList, getstandingOrderDays } from '../../../utils/utils.common';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { states } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { KeyValue } from '@angular/common';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ProTipsServiceService } from '../../helping-material/nemt-pro-tips/service/pro-tips-service.service';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { ToastrService } from 'ngx-toastr';
import { TripPurposesService } from '../../trip-purposes/trip-purposes.service';
import { ModifierCodesService } from '../../modifier-codes/modifier-codes-service/modifier-codes.service';

@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.css']
})
export class NewTripComponent implements OnInit, OnChanges, OnDestroy {
  tripAddressPickerModalObj;
  payorList = PAYOR;
  legId = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  standingOrderDays = JSON.parse(JSON.stringify(getstandingOrderDays));
  noOfPassengers = 4;
  selectedFormName = 'Corporate';
  assignedTrips = [];
  corporateUserList;
  corporateAddonsList;
  getAssignTripsState: Observable<any>;
  submitted = false;
  unassignedTripDetailById = null;
  btnName = 'Create';
  unassignedTripId;
  timeZone;
  legsForm: FormArray;
  noOfLegs = 0;
  todayDate = moment().tz("America/New_York")
  googleMilage = null;
  newTripForm: FormGroup;
  addonServicesForm: FormGroup;
  icdCodesForm: FormGroup;
  search: any;
  states = states;
  estimatedFare: ''
  user;
  isStandingOrdersEnabled = false;
  isRestrictRadius = false;
  addonServicesFormSubmitted = false;
  icdFormSubmitted = false;
  tripResponseData;
  // status = "active";
  @Input('tripId') editTripId: any;
  @Input('trip') agentTrip?: any;
  @Output() tripEditResponse = new EventEmitter<string>();
  fareEstimationRes;
  drivers;
  radius;
  isSaveDisabled = false;
  private readonly searchSubject = new Subject<string | undefined>();
  private readonly inputSubject = new Subject<any>();
  selectedCorporateUser;
  // selectedDriverId;
  @Input() isManifest: boolean = false;
  @Input('driverId') selectedDriverId?: string;
  isManifestError = false;
  @Input('isSubmitForm') isSubmitForm?: boolean = false;
  nemtCodes;
  tripPurposes;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  isFileLoading = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'title',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableCheckAll: false
  };
  ngTripReason = [];
  modifierList: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private assignTripService: AssigTripsService,
    private proTipsService: ProTipsServiceService,
    private driverService: DriversService,
    private toastr: ToastrService,
    private tripPurposesService: TripPurposesService,
    private modifierCodesService: ModifierCodesService,

  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isManifest && changes && changes.selectedDriverId) {
      this.selectedDriverId = changes.selectedDriverId.currentValue;
    }
  }

  ngOnInit() {
    this.socketService.addListener(Events.GET_ESTIMATED_FARE)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.fareEstimationRes = data;
          this.estimatedFare = data.totalFare;
          if (this.selectedFormName === 'Corporate' && this.selectedCorporateUser && this.selectedCorporateUser.selfPay) {
            this.newTripForm.patchValue({
              customerSpecialRate: data.totalFare,
              bankStatus: "requested"
            });
          }
        }
        // this.socketService.removeListener(Events.GET_ESTIMATED_FARE);
      });

    this.searchSubject
      .pipe(debounceTime(1000))
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((name) => {
        if (name.length >= 3 && !this.editTripId) {
          this.getMemberTrips(name);
        }
      });



    this.newTripForm = this.fb.group({
      company: ['', Validators.required],
      companyNote: [''],
      tripReason: [''],
      dispatchNoteTwo: [''],
      billerNote: [''],
      companyType: [''],
      jobCarType: ['Standard', Validators.required],
      jobDestinationAddress: ['', Validators.required],
      jobDestinationLatitude: [0, Validators.required],
      jobDestinationLongitude: [0, Validators.required],
      jobOriginAddress: ['', Validators.required],
      jobOriginLatitude: [0, Validators.required],
      jobOriginLongitude: [0, Validators.required],
      jobPassengerNumber: [1, Validators.required],
      jobType: ['assigned', Validators.required],

      priorityClient: this.fb.group({
        displayName: ['', Validators.required],
        contactNumber: ['', Validators.required],
        cellPhone: [''],
        clientId: [''],
        email: ['']
      }),


      originAddressAdditional: this.fb.group({
        floor: [''],
        room: [''],
        aptOrSuite: [''],
        place: [''],
        phone: [''],
        modifier: ['']
      }),
      destinationAddressAdditional: this.fb.group({
        floor: [''],
        room: [''],
        aptOrSuite: [''],
        place: [''],
        phone: [''],
        modifier: ['']
      }),

      rawId: [''],
      isMedical: [false],
      iSschUrgentCare: [false],
      isUrgentCare: [false],
      isWheelChair: [false],
      isCarSeat: [false],
      isLongDistance: [false],
      memberId: [''],
      tripId: [''],
      tripRequirement: ['AMB'],
      dob: [''],
      weight: [''],
      cooperate: [''],
      payor: ['other'],

      preScheduleTime: [this.todayDate, Validators.required],
      preAppointmentTime: [moment(this.todayDate).add(1, 'hours'), Validators.required],
      appointmentTime: [moment(this.todayDate).add(1, 'hours').toISOString(), Validators.required],
      scheduleTime: [this.todayDate.toISOString(), Validators.required],

      milage: [null],
      tripLegs: this.fb.array([this.createLeg(0)]),
      state: ['', Validators.required],
      customerSpecialRate: [''],
      noOfEscorts: [''],
      discount: [''],
      ccFee: [''],
      fuelSurcharge: [''],
      payerAuthorizer: [''],
      isStandingOrder: [false],
      isRecurring: [false],
      addonServices: [[]],
      bankStatus: ['none'],
      preRecurringEndDate: [null],
      recurringEndDate: [''],
      gender: [''],
      ipa: [''],
      primaryDiagnosis: [''],
      attachment: [''],
      isVipTrip: [false],
      isConfirmedTrip: [false],
      isMethadone: [false],
      // icdCodes: [[]]
    });

    this.addonServicesForm = this.fb.group({
      addonId: ['', Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      title: [''],
      fare: [0],
      total: [0]

    });

    this.icdCodesForm = this.fb.group({
      code: ['', Validators.required]
    });

    this.formSelected(this.selectedFormName);

    // this.newTripForm.get("priorityClient.displayName")
    //   .valueChanges
    //   .pipe(debounceTime(1000))
    //   .subscribe(name => {
    //     if (name.length >= 3) {
    //       this.getMemberTrips(name);
    //     }
    //   });
    this.inputSubscriber();
    this.getNemtCodes();
    this.getUser();
  }

  getAllTripPurposes() {
    this.tripPurposesService
      .getTripPurposesList('active')
      .subscribe(data => {
        if (data) {
          this.tripPurposes = data;
          this.checkEditTrip();
        }
      });
  }

  getNemtCodes() {
    this.sharedDataService
      .getNemtCodes()
      .subscribe(data => {
        if (data && data.length) {
          this.nemtCodes = data;
        }
      });
  }

  onChangeClientName(event: Event) {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery);
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.todayDate = moment().tz(this.timeZone);

          this.newTripForm.patchValue({
            company: this.user._id,
            preScheduleTime: this.todayDate,
            preAppointmentTime: moment(this.todayDate).add(1, 'hours'),
            appointmentTime: moment(this.todayDate).add(1, 'hours').toISOString(),
            scheduleTime: this.todayDate.toISOString()
          })

          this.getDrivers();
          this.toggleSearchRadius();
          this.getAllTripPurposes();
          this.getModifierList();
        }
      });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.drivers = data;
      });
  }
  getModifierList() {
    this.modifierCodesService
      .getModifierCodes()
      .subscribe(data => {
        if (data) {
          this.modifierList = data;
        }
      }, err => {
        sweetAlert("Error", err, "error", "Ok");
      });
  }


  get form() {
    return this.newTripForm.controls;
  }

  get addonForm() {
    return this.addonServicesForm.controls;
  }

  get icdForm() {
    return this.icdCodesForm.controls;
  }

  getBrokersList() {
    return getBrokersList;
  }

  getOriginAddress(place) {
    this.newTripForm.patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });
    this.legsForm.at(0).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });
    if (place.address_components && place.address_components.length) {
      const address = place.address_components;
      let country;
      let state;
      address.forEach(component => {
        if (component.types && component.types.length) {
          if (component.types.includes('administrative_area_level_1')) {
            state = component ? component.short_name : '';
          }
          if (component.types.includes('country')) {
            country = component ? component.short_name : '';
          }
        }
      });

      if (country === 'US' && state) {
        this.newTripForm.patchValue({
          state: state
        });
      } else {
        this.newTripForm.patchValue({
          state: ''
        });
      }
    }

    if (this.newTripForm.value.jobOriginAddress && this.newTripForm.value.jobDestinationAddress) {
      this.calculateEstimatedFareOnChanges();
    }
  }

  getDestinationAddress(place) {

    this.newTripForm.patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });

    this.legsForm.at(0).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });

    if (this.newTripForm.value.jobOriginAddress && this.newTripForm.value.jobDestinationAddress) {
      this.calculateEstimatedFareOnChanges();
    }
  }

  getPickUpAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng()
    });
  }

  getDropOffAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng()
    });
    if (this.legsForm.length > (index + 1)) {
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng()
      });
    }
  }


  selectVehicle(selectedVehicleType) {

    if (selectedVehicleType === "AMB" || selectedVehicleType === "LIV") {
      this.newTripForm.patchValue({
        jobCarType: "Standard",
        tripRequirement: selectedVehicleType
      });
    } else {
      this.newTripForm.patchValue({
        jobCarType: selectedVehicleType,
        tripRequirement: selectedVehicleType
      });
    }
    this.calculateEstimatedFareOnChanges();
  }

  passengerSelected(passengers: number): void {
    this.newTripForm.patchValue({
      jobPassengerNumber: passengers
    });
  }

  getMemberTrips(name) {
    this.assignedTrips = [];
    const payload = {
      displayName: name
    };
    this.assignTripService
      .getMemberTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.assignedTrips = data;
        }
      });
  }

  setNewTripFormValidators() {

    let clearKeys = [];
    let setKeys = [];
    if (this.selectedFormName === 'Dispatch') {
      clearKeys = ['memberId', 'companyType', 'tripRequirement', 'dob', 'jobCarType', 'cooperate'];
      setKeys = ['jobCarType'];
      this.setFormValidation(setKeys, clearKeys);
    } else if (this.selectedFormName === 'Medical') {

      setKeys = ['memberId', 'companyType', 'tripRequirement'];
      clearKeys = ['jobCarType', 'cooperate'];
      this.setFormValidation(setKeys, clearKeys);

    } else if (this.selectedFormName === 'Corporate') {

      clearKeys = ['memberId', 'companyType', 'tripRequirement', 'dob'];
      setKeys = ['jobCarType', 'cooperate'];
      this.setFormValidation(setKeys, clearKeys);
    }

  }

  setFormValidation(setKeys?, clearKeys?) {
    if (clearKeys) {
      clearKeys.forEach(element => {
        this.newTripForm.get(element).clearValidators();
        this.newTripForm.get(element).updateValueAndValidity();
      });
    }

    if (setKeys) {
      setKeys.forEach(element => {
        if (element === 'dob') {
          if (this.newTripForm.value.companyType === 'lcp') {
            this.newTripForm.get('dob').setValidators([Validators.required]);
          }
        } else {
          this.newTripForm.get(element).setValidators([Validators.required]);
        }
      });
    }
  }

  checkEditTrip() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.unassignedTripId = this.activatedRoute.snapshot.url[1].path;
      this.getTripDetails();
    }

    if (this.editTripId) {
      this.unassignedTripId = this.editTripId
      this.getTripDetails();
    }

    if(this.agentTrip){
      this.corporateAddonsList = null;
      this.newTripForm.patchValue({
        jobPassengerNumber: 1,
        // add pre time picker
        preScheduleTime: moment(this.agentTrip.scheduleTime).tz(this.timeZone),
        preAppointmentTime: moment(this.agentTrip.appointmentTime).tz(this.timeZone),
      });

        this.noOfPassengers = 4;
        this.formSelected('Medical', this.agentTrip);

    }
  }

  getTripDetails() {
    this.assignTripService
      .getUnassignedTripDetailById(this.unassignedTripId)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (data.driver) {
            this.selectedDriverId = data.driver;
          }
          this.corporateAddonsList = null;
          this.unassignedTripDetailById = data;

          this.newTripForm.patchValue({
            jobPassengerNumber: 1,
            // add pre time picker
            preScheduleTime: moment(data.scheduleTime).tz(this.timeZone),
            preAppointmentTime: moment(data.appointmentTime).tz(this.timeZone),
          });

          if (data.status === 'standing' && data.recurringEndDate) {
            this.newTripForm.patchValue({
              preRecurringEndDate: moment(data.recurringEndDate).tz(this.timeZone),
            });
          }

          if (data.jobCarType === 'SUV') {
            this.noOfPassengers = 7;
          } else {
            this.noOfPassengers = 4;
          }

          if (data.companyType) {
            this.formSelected('Medical', this.unassignedTripDetailById);
          } else if (data.cooperate && data.cooperate.length > 0) {
            this.formSelected('Corporate', this.unassignedTripDetailById);
            // this.getCorporateAddonsList(data.cooperate);
          } else {
            this.formSelected('Dispatch', this.unassignedTripDetailById);
          }

          /////
          let newList = this.unassignedTripDetailById.tripReason.split(',');
          this.ngTripReason = this.tripPurposes.filter(v => {
            // v.title === item
            const itemFound = newList.find(i => i === v.title);
            if (itemFound) {
              return true;
            } else {
              return false;
            }
          });
          if (this.unassignedTripDetailById.status === "standing") {
            if (this.unassignedTripDetailById.isRecurring) {
              this.isStandingOrdersEnabled = true;
            } else {
              this.isStandingOrdersEnabled = false;
            }
            for (let item of this.unassignedTripDetailById.recurringDays) {
              for (let item2 of this.standingOrderDays) {
                if (item.day === item2.day) {
                  item2.checked = true;
                  item2.driver = item.driver;
                }
              }
            }
          }

          if (data.companyNote.includes('Add comment before this')) {
            this.newTripForm.patchValue({
              companyNote: data.companyNote.replace('Add comment before this', '')
            });
          }

          this.btnName = 'Edit';
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  formSelected(name, preData?) {
    // this.assignedTrips = [];
    this.corporateAddonsList = null;
    this.selectedCorporateUser = null;
    this.corporateUserList = [];
    this.estimatedFare = '';
    this.selectedFormName = name;
    this.clearSelfPaySettings();
    if (this.selectedFormName === 'Dispatch') {
      if (preData) {
        // this.setNewTripFormValidators();
        this.newTripForm.patchValue({
          addonServices: [],
          bankStatus: 'none',
          customerSpecialRate: '',
          ...preData,
          jobType: 'assigned',
          companyType: '',
          rawId: '',
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
          payor: '',
          cooperate: null,
          jobCarType: this.setJobCarType(preData),
          tripRequirement: this.setJobCarType(preData),
          isMedical: false,
          jobPassengerNumber: preData.jobPassengerNumber
        });
      } else {
        // set Default things for medical
        this.newTripForm.patchValue({
          bankStatus: 'none',
          customerSpecialRate: '',
          jobType: 'assigned',
          jobCarType: 'Standard',
          tripRequirement: 'Standard',
          isMedical: false,
          rawId: '',
          payor: '',
          companyType: '',
          cooperate: null,
          addonServices: [],
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
        });

      }
      this.getCorporateAddonsList(null);

    } else if (this.selectedFormName === 'Corporate') {

      if (preData) {
        this.newTripForm.patchValue({
          addonServices: [],
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
          bankStatus: 'none',
          payor: 'other',
          customerSpecialRate: '',
          ...preData,
          jobType: 'cooperate',
          rawId: '',
          cooperate: !preData.cooperate ? '' : preData.cooperate,
          companyType: '',
          jobCarType: this.setJobCarType(preData),
          tripRequirement: this.setJobCarType(preData),
          isMedical: false,
          jobPassengerNumber: preData.jobPassengerNumber
        });
        this.estimatedFare = !preData.customerSpecialRate ? '' : preData.customerSpecialRate;
      } else {
        // set Default things for medical
        this.newTripForm.patchValue({
          bankStatus: 'none',
          customerSpecialRate: '',
          jobType: 'cooperate',
          jobCarType: 'Standard',
          tripRequirement: 'Standard',
          isMedical: false,
          rawId: '',
          payor: 'other',
          companyType: '',
          cooperate: '',
          addonServices: [],
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
        });
      }

      this.assignTripService
        .getCorporateUserList()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data && data.length) {
            this.corporateUserList = data;
            if (preData && preData.cooperate && preData.cooperate.length > 0) {
              this.selectCorporateUser(preData.cooperate, true);
            }
          }
        });

    } else if (this.selectedFormName === 'Medical') {

      // set Default things for medical
      if (preData) {
        this.newTripForm.patchValue({
          bankStatus: 'none',
          customerSpecialRate: '',
          ...preData,
          jobType: 'assigned',
          cooperate: null,
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
          payor: '',
          addonServices: [],
          companyType: preData.companyType ? preData.companyType : '',
          jobCarType: this.setMedicalJobCarType(preData),
          tripRequirement: this.setMedicalTripRequirement(preData),
          isMedical: (preData.isMedical || preData.rawId === "") ? true : false
        });
      } else {
        // set Default things for medical
        this.newTripForm.patchValue({
          bankStatus: 'none',
          customerSpecialRate: '',
          discount: '',
          fuelSurcharge: '',
          ccFee: '',
          jobType: 'assigned',
          jobCarType: 'Standard',
          isMedical: true,
          tripRequirement: 'AMB',
          rawId: '',
          payor: '',
          companyType: '',
          addonServices: [],
          cooperate: null
        });
      }
    }
    this.setNewTripFormValidators();
  }

  setJobCarType(trip) {
    if (trip.tripRequirement === "AMB" || trip.tripRequirement === "LIV") {
      return "Standard";
    }
    return trip.tripRequirement;
  }

  setMedicalJobCarType(trip) {
    if (trip.tripRequirement === "Premium" || trip.tripRequirement === "SUV" || trip.tripRequirement === "AMB" || trip.tripRequirement === "LIV") {
      return "Standard"; // Return Default Vehicle
    }
    return trip.tripRequirement;
  }


  setMedicalTripRequirement(trip) {
    if (trip.tripRequirement === "Standard" || trip.tripRequirement === "Premium" || trip.tripRequirement === "SUV") {
      return "AMB"; // Return Default Vehicle
    }
    return trip.tripRequirement;
  }

  createLeg(index): FormGroup {
    return this.fb.group({
      preScheduleTime: [this.todayDate, index > 0 ? Validators.required : null],
      scheduleTime: [this.todayDate.toISOString(), index > 0 ? Validators.required : null],
      jobOriginAddress: ['', index > 0 ? Validators.required : null],
      jobOriginLatitude: [0, index > 0 ? Validators.required : null],
      jobOriginLongitude: [0, index > 0 ? Validators.required : null],
      jobDestinationAddress: ['', index > 0 ? Validators.required : null],
      jobDestinationLatitude: [0, index > 0 ? Validators.required : null],
      jobDestinationLongitude: [0, index > 0 ? Validators.required : null],
      tripId: [''],
      companyNote: [''],
      originAddressAdditional: this.fb.group({
        floor: [''],
        room: [''],
        aptOrSuite: [''],
        place: [''],
        phone: [''],
        modifier: ['']
      }),
      destinationAddressAdditional: this.fb.group({
        floor: [''],
        room: [''],
        aptOrSuite: [''],
        place: [''],
        phone: [''],
        modifier: ['']
      })
    });
  }

  addLeg(noOfLegs) {
    noOfLegs++;
    this.legsForm.push(this.createLeg(noOfLegs));
    if (noOfLegs === 1) {
      this.legsForm.at(noOfLegs).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
        originAddressAdditional: {
          floor: this.newTripForm.value.destinationAddressAdditional.floor,
          room: this.newTripForm.value.destinationAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.destinationAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.destinationAddressAdditional.place,
          phone: this.newTripForm.value.destinationAddressAdditional.phone,
          modifier: this.newTripForm.value.destinationAddressAdditional.modifier

        },
        destinationAddressAdditional: {
          floor: this.newTripForm.value.originAddressAdditional.floor,
          room: this.newTripForm.value.originAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.originAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.originAddressAdditional.place,
          phone: this.newTripForm.value.originAddressAdditional.phone,
          modifier: this.newTripForm.value.originAddressAdditional.modifier
        },
        preScheduleTime: moment(this.newTripForm.value.preAppointmentTime).add(1, 'hours'),
        scheduleTime: moment(this.newTripForm.value.preAppointmentTime).add(1, 'hours').toISOString(),

      });
      this.legsForm.at(0).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
        originAddressAdditional: {
          floor: this.newTripForm.value.destinationAddressAdditional.floor,
          room: this.newTripForm.value.destinationAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.destinationAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.destinationAddressAdditional.place,
          phone: this.newTripForm.value.destinationAddressAdditional.phone,
          modifier: this.newTripForm.value.destinationAddressAdditional.modifier
        },
        destinationAddressAdditional: {
          floor: this.newTripForm.value.originAddressAdditional.floor,
          room: this.newTripForm.value.originAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.originAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.originAddressAdditional.place,
          phone: this.newTripForm.value.originAddressAdditional.phone,
          modifier: this.newTripForm.value.originAddressAdditional.modifier
        },
      });
    } else if (noOfLegs > 1) {
      this.legsForm.at(noOfLegs - 1).patchValue({
        jobDestinationAddress: '',
        jobDestinationLatitude: 0,
        jobDestinationLongitude: 0,
        destinationAddressAdditional: {
          floor: '',
          room: '',
          aptOrSuite: '',
          place: '',
          phone: '',
          modifier:''
        },
      });
      this.legsForm.at(noOfLegs).patchValue({
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
        destinationAddressAdditional: {
          floor: this.newTripForm.value.originAddressAdditional.floor,
          room: this.newTripForm.value.originAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.originAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.originAddressAdditional.place,
          phone: this.newTripForm.value.originAddressAdditional.phone,
          modifier: this.newTripForm.value.originAddressAdditional.modifier
        },
        preScheduleTime: moment(this.legsForm.at(noOfLegs - 1).value.preScheduleTime).add(2, 'hours'),
        scheduleTime: moment(this.legsForm.at(noOfLegs - 1).value.preScheduleTime).add(2, 'hours').toISOString()

      });
    }

    if (this.newTripForm.value.tripId) {
      this.legsForm.at(noOfLegs).patchValue({
        tripId: this.newTripForm.value.tripId.split('-')[0]
        // tripId: this.newTripForm.value.tripId.split('-')[0] + '(LEG' + this.legId[noOfLegs] + ')'
      });
    }
  }

  removeLeg(index) {
    // if (index === 0) {
    //   this.legsForm.at(index + 1).patchValue({
    //     jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
    //     jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
    //     jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude
    //   })
    // } else {
    //   this.legsForm.at(index + 1).patchValue({
    //     jobOriginAddress: this.newTripForm.value.tripLegs[index].jobDestinationAddress,
    //     jobOriginLatitude: this.newTripForm.value.tripLegs[index].jobDestinationLatitude,
    //     jobOriginLongitude: this.newTripForm.value.tripLegs[index].jobDestinationLongitude
    //   })
    // }
    if (index === 0 && this.newTripForm.value.tripLegs.length > 2) {
      this.legsForm.removeAt(index + 1);
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        originAddressAdditional: {
          floor: this.newTripForm.value.destinationAddressAdditional.floor,
          room: this.newTripForm.value.destinationAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.destinationAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.destinationAddressAdditional.place,
          phone: this.newTripForm.value.destinationAddressAdditional.phone,
          modifier: this.newTripForm.value.destinationAddressAdditional.modifier
        },
      });
    } else if (this.newTripForm.value.tripLegs.length > index + 1) {
      this.legsForm.at(index).patchValue({
        jobDestinationAddress: this.newTripForm.value.tripLegs[index + 1].jobDestinationAddress,
        jobDestinationLatitude: this.newTripForm.value.tripLegs[index + 1].jobDestinationLatitude,
        jobDestinationLongitude: this.newTripForm.value.tripLegs[index + 1].jobDestinationLongitude,
        destinationAddressAdditional: {
          floor: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.floor,
          room: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.room,
          aptOrSuite: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.aptOrSuite,
          place: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.place,
          phone: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.phone,
          modifier: this.newTripForm.value.tripLegs[index + 1].destinationAddressAdditional.modifier,
        },
      });
      this.legsForm.removeAt(index + 1);

    } else {
      this.legsForm.removeAt(index + 1);
    }
    // this.bindReturnTripId();
  }

  get legsElement() {
    this.legsForm = this.newTripForm.controls.tripLegs as FormArray;
    return this.legsForm;
  }

  clearTripLegs() {
    for (let i = this.newTripForm.value.tripLegs.length; i > 0; i--) {
      this.legsForm.removeAt(i);
    }
  }

  bindTripId() {
    // if (!this.newTripForm.value.tripId.toUpperCase().includes('(LEG-A)')) {
    //   this.newTripForm.patchValue({
    //     tripId: this.newTripForm.value.tripId + '(LEG-A)'
    //   });
    // }
    this.bindReturnTripId();
  }

  bindReturnTripId() {
    if (this.legsForm.length > 0) {
      for (let i = 1; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          // tripId: this.newTripForm.value.tripId.split('-')[0] + '(LEG' + this.legId[i] + ')'
          tripId: this.newTripForm.value.tripId.split('-')[0]
        });
      }
    }
  }

  checkTripIdUnique() {
    const tempTripIdArray = [];
    tempTripIdArray.push(this.newTripForm.value.tripId);

    for (let i = 1; i < this.legsForm.length; i++) {
      tempTripIdArray.push(this.newTripForm.value.tripLegs[i].tripId);
    }
    if ([...new Set(tempTripIdArray)].length === tempTripIdArray.length) {
      return true;
    }
    return false;
  }

  onScheduleDateChange(evt) {
    this.newTripForm.patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });

    if (!this.unassignedTripDetailById) {
      this.newTripForm.patchValue({
        preAppointmentTime: moment(evt).add(1, 'hours')
      });
    }
  }

  onAppointmentDateChange(evt) {
    this.newTripForm.patchValue({
      appointmentTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  onLegScheduleDateChange(evt, index) {
    this.legsForm.at(index).patchValue({
      scheduleTime: moment(evt).tz(this.timeZone, true).toISOString(),
    });
  }

  getMiles() {
    if (!this.newTripForm.value.jobOriginAddress || !this.newTripForm.value.jobDestinationAddress) {
      sweetAlert('Alert', 'Please select Pickup & Drop Off address', 'warning', 'OK')
      return;
    }

    this.setUpSocketListener();
    let data = {
      origin: this.newTripForm.value.jobOriginLatitude + ',' + this.newTripForm.value.jobOriginLongitude,
      destination: this.newTripForm.value.jobDestinationLatitude + ',' + this.newTripForm.value.jobDestinationLongitude
    };
    this.socketService.emit(Events.GET_MILES_CALL, data);
  }

  setUpSocketListener() {
    this.socketService.addListener(Events.GET_MILES_CALL)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.googleMilage = data.message;
        this.socketService.removeListener(Events.GET_MILES_CALL);
        setTimeout(() => {
          this.googleMilage = '';
        }, 5000);
      });
  }

  calculateEstimatedFareOnChanges() {
    if (this.selectedCorporateUser && this.selectedFormName === 'Corporate') {
      this.calculateEstimatedFare();
    }
  }

  calculateEstimatedFare() {
    if (!this.newTripForm.value.jobOriginAddress || !this.newTripForm.value.jobDestinationAddress) {
      sweetAlert('Alert', 'Please select Pickup & Drop Off address', 'warning', 'Ok')
      return;
    }
    let payload;

    payload = {
      company: this.user._id,
      jobCarType: this.newTripForm.value.jobCarType,
      jobDestinationLatitude: this.newTripForm.value.jobDestinationLatitude,
      jobDestinationLongitude: this.newTripForm.value.jobDestinationLongitude,
      jobOriginLatitude: this.newTripForm.value.jobOriginLatitude,
      jobOriginLongitude: this.newTripForm.value.jobOriginLongitude,
      passengerNumber: this.newTripForm.value.jobPassengerNumber,
    };

    if (this.newTripForm.value.discount) {
      payload = { ...payload, discount: this.newTripForm.value.discount }
    }

    if (this.newTripForm.value.fuelSurcharge) {
      payload = { ...payload, fuelSurcharge: this.newTripForm.value.fuelSurcharge }
    }

    if (this.newTripForm.value.ccFee) {
      payload = { ...payload, ccFee: this.newTripForm.value.ccFee }
    }

    if (this.newTripForm.value.addonServices && this.newTripForm.value.addonServices.length) {
      payload = { ...payload, addonServices: this.newTripForm.value.addonServices }
    }

    if (this.newTripForm.value.cooperate) {
      payload = { ...payload, cooperate: this.newTripForm.value.cooperate }
    }

    if (this.newTripForm.value.jobType) {
      payload = { ...payload, jobType: this.newTripForm.value.jobType }
    }

    this.socketService.emit(Events.GET_ESTIMATED_FARE, payload);
  }



  toggleSearchRadius() {
    if (this.legsForm && this.newTripForm) {
      this.newTripForm.value.tripLegs.forEach((leg, index) => {
        this.legsForm.at(index).patchValue({
          jobOriginAddress: '',
          jobOriginLatitude: 0,
          jobOriginLongitude: 0,
          jobDestinationAddress: '',
          jobDestinationLatitude: 0,
          jobDestinationLongitude: 0,
        });
      });
    }

    this.newTripForm.patchValue({
      jobOriginAddress: '',
      jobOriginLatitude: 0,
      jobOriginLongitude: 0,

      jobDestinationAddress: '',
      jobDestinationLatitude: 0,
      jobDestinationLongitude: 0,
    });

    this.isRestrictRadius = !this.isRestrictRadius
    if (this.isRestrictRadius === true) {
      this.radius = {
        miles: 200,
        center: { lat: this.user.company ? this.user.company.latitude : this.user.latitude, lng: this.user.company ? this.user.company.longitude : this.user.longitude }
      }
    } else {
      this.radius = null;
    }

  }

  toggleStandingOrder() {
    this.isStandingOrdersEnabled = !this.isStandingOrdersEnabled;
    this.newTripForm.patchValue({
      isRecurring: this.isStandingOrdersEnabled
    });
  }

  changeIsRecurring(e) {
    if (this.unassignedTripId && !JSON.parse(e)) {
      sweetAlert('Alert', 'Are you sure you want to inactive standing orders? This action will reset the recurring date to today and remove all the future existing trips.', 'warning', 'Yes', 'No')
        .then((result: any) => {
          if (result.value) {
            this.onChangeRecurringStatus();
          } else {
            this.newTripForm.patchValue({
              isRecurring: true
            });
            this.isStandingOrdersEnabled = true;
          }
        });
    } else {
      this.isStandingOrdersEnabled = true;
    }
  }

  onChangeRecurringStatus() {
    this.isStandingOrdersEnabled = false
    const standingOrderDate = moment().tz(this.timeZone, true).startOf('day');

    if (this.newTripForm.value.recurringEndDate) {
      const previousDate = moment(this.newTripForm.value.recurringEndDate).tz(this.timeZone, true).startOf('day');
      if (previousDate.isAfter(standingOrderDate)) {
        this.newTripForm.patchValue({
          preRecurringEndDate: standingOrderDate,
          recurringEndDate: standingOrderDate.toISOString()
        });
      }
    } else {
      this.newTripForm.patchValue({
        preRecurringEndDate: standingOrderDate,
        recurringEndDate: standingOrderDate.toISOString()
      });
    }
  }

  clearStandingDriver(standingOrderDay) {
    sweetAlert('Alert', 'Are you sure you want to clear this driver?', 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          standingOrderDay.driver = null;
        }
      })

  }

  onChangeScheduler(evt) {
  }

  resetForm() {
    this.newTripForm.reset();
  }

  selectCorporateUser(id, isFromEdit?) {
    this.payorList = PAYOR;
    this.selectedCorporateUser = null;
    this.corporateAddonsList = null;
    this.clearSelfPaySettings();
    if (!isFromEdit) {
      this.newTripForm.controls['addonServices'].setValue([]);
    }

    if (id) {
      this.selectedCorporateUser = this.corporateUserList.find(user => user._id === id);
      if (this.selectedCorporateUser.payorTypes && this.selectedCorporateUser.payorTypes.length) {
        if (this.selectedCorporateUser.payorTypes[0] && this.selectedCorporateUser.payorTypes[0].title) {
          this.payorList = this.selectedCorporateUser.payorTypes.reduce((a, v) => ({ ...a, [v.value]: v.title }), {});
          const isPayorExist = Object.keys(this.payorList).find(key => key === this.newTripForm.value.payor);
          if (!isPayorExist)
            this.newTripForm.patchValue({
              payor: Object.keys(this.payorList).sort()[0]
            });
        } else {
          this.newTripForm.patchValue({
            payor: Object.keys(this.payorList).sort()[0]
          });
        }
      } else {
        this.newTripForm.patchValue({
          payor: Object.keys(this.payorList).sort()[0]
        });
      }

      if (this.selectedCorporateUser.selfPay) {
        this.initSelfPaySettings();
      } else {
        this.newTripForm.patchValue({
          bankStatus: 'none',
          customerSpecialRate: '',
          priorityClient: {
            email: ''
          }
        });
        this.estimatedFare = '';
      }

      // patch corporate address and phone
      if (!this.newTripForm.value.jobOriginAddress) {
        this.newTripForm.controls['priorityClient'].patchValue({
          cellPhone: this.selectedCorporateUser.contactNumber
        });

        this.newTripForm.patchValue({
          jobOriginAddress: this.selectedCorporateUser.address,
          jobOriginLatitude: this.selectedCorporateUser.latitude,
          jobOriginLongitude: this.selectedCorporateUser.longitude
        });

      }

    }
    this.getCorporateAddonsList(id);
  }

  initSelfPaySettings() {
    // this.newTripForm.get('priorityClient.email').setValidators([Validators.required, Validators.email]);
    // this.newTripForm.get('priorityClient.email').updateValueAndValidity();

    this.newTripForm.get('customerSpecialRate').setValidators([Validators.required]);
    this.newTripForm.get('customerSpecialRate').updateValueAndValidity();
  }

  clearSelfPaySettings() {
    // this.newTripForm.get('priorityClient.email').clearValidators();
    // this.newTripForm.get('priorityClient.email').updateValueAndValidity();

    this.newTripForm.get('customerSpecialRate').clearValidators();
    this.newTripForm.get('customerSpecialRate').updateValueAndValidity();
  }

  getCorporateAddonsList(id) {
    this.corporateAddonsList = null;
    // if (!id) {
    //   return
    // } else {

    // }

    this.assignTripService
      .getCorporateAddonsList(id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.addonServices) {
          this.corporateAddonsList = [];
          this.corporateAddonsList = data.addonServices;
        } else {
          this.newTripForm.controls['addonServices'].setValue([]);
        }
      });
  }

  askSave(payload) {
    sweetAlert('Alert', `Are you sure you want to proceed without return trip?`, 'warning', 'Yes', 'No')
      .then((result: any) => {
        if (result.value) {
          this.saveNewTripRequest(payload);
        } else {
          this.isSaveDisabled = false;
        }
      });
  }

  saveNewTripRequest(payload) {
    this.assignTripService
      .createNewTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.isSaveDisabled = false;
        if (data) {
          this.router.navigateByUrl('trips');
        }
      }, err => {
        this.isSaveDisabled = false;
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  onNewTripSubmit() {
    this.submitted = true;
    // check trip ids
    // if (this.legsForm && this.legsForm.length > 1 && this.selectedFormName === 'Medical' && this.newTripForm.value.tripId) {
    //   const result = this.checkTripIdUnique();
    //   if (!result) {
    //     sweetAlert('Alert', 'Duplicate Trip Id Found', 'error', 'OK');
    //     return;
    //   }
    // }
    console.log("Trip Data",this.newTripForm.value);
    if (this.newTripForm.invalid) {
      return;
    }

    if (this.selectedFormName === "Dispatch" || this.selectedFormName === "Corporate") {
      // if (this.newTripForm.value.customerSpecialRate === "") {
      //   delete this.newTripForm.value.customerSpecialRate;
      // }
      delete this.newTripForm.value.noOfEscorts;

    } else if (this.selectedFormName === "Medical") {
      if (this.newTripForm.value.noOfEscorts === "") {
        delete this.newTripForm.value.noOfEscorts;
      }
      // delete this.newTripForm.value.customerSpecialRate;
      this.newTripForm.patchValue({
        customerSpecialRate: ''
      });
    }

    if (!this.newTripForm.value.milage || this.newTripForm.value.milage == 0 || this.newTripForm.value.milage === 'null') {
      delete this.newTripForm.value.milage;
    }

    let payload = this.newTripForm.value;
    if (this.selectedCorporateUser && this.selectedCorporateUser.selfPay && this.selectedFormName === 'Corporate') {
      payload.fareEstimation = this.fareEstimationRes;
    }
    if (payload.destinationAddressAdditional.modifier === '') {
      payload.destinationAddressAdditional.modifier = null;
    }
    if (payload.originAddressAdditional.modifier === '') {
      payload.originAddressAdditional.modifier = null;
    }

    if (payload.billerNote === '') {
      payload.billerNote = null;
    }


    // Mange standing order
    if (this.isStandingOrdersEnabled) {
      let recurringDays = this.getStandingOrderPayloadList();
      if (recurringDays.length) {
        payload = { ...payload, recurringDays, status: 'standing', jobStatus: 'standing' }
      } else {
        return;
      }
    }

    this.isSaveDisabled = true;
    payload = { ...payload, createdBy: this.user.displayName };

    payload.tripReason = '';
    for (let i = 0; i < this.ngTripReason.length; i++) {
      if (((this.ngTripReason.length - 1) - i) === 0) {
        payload.tripReason += this.ngTripReason[i].title;
      } else {
        payload.tripReason += this.ngTripReason[i].title + ',';
      }
    }

    if (this.unassignedTripId) {

      if (this.isManifest) {
        if (this.unassignedTripDetailById.status === 'offered' && !this.selectedDriverId) {
          this.isManifestError = true;
          this.isSaveDisabled = false;
          return;
        }
        if (this.selectedDriverId) {
          payload = { ...payload, driver: this.selectedDriverId, status: 'offered', jobStatus: 'offered' };
        }

      }

      // payload = { ...payload, createdBy: this.user.displayName };

      this.assignTripService
        .createNewTrip(payload, this.unassignedTripId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          this.isSaveDisabled = false;
          if (data) {
            if (this.editTripId) {
              if (data.cooperate && data.cooperate.length > 0 && !this.isManifest) {
                const cropuser = this.corporateUserList.find(us => data.cooperate === us._id);
                data.cooperate = cropuser;
              }
              // if (this.isManifest && this.selectedDriverId && data.driver) {
              //   data.driver = this.drivers.find(d => d._id === this.selectedDriverId);
              // }
              this.tripEditResponse.emit(data);
            }
            else {
              this.router.navigateByUrl('trips');
            }
          }
        }, err => {
          this.isSaveDisabled = false;
          sweetAlert('Error', err, 'error', 'OK');
        });
    } else {
      if (this.newTripForm.value.tripLegs.length <= 1) {
        this.askSave(payload);
      } else {
        this.saveNewTripRequest(payload);
      }
    }
  }

  getStandingOrderPayloadList() {
    let recurringDays = [];
    for (let standingOrderDay of this.standingOrderDays) {
      if (standingOrderDay.checked) {
        recurringDays.push(standingOrderDay)
      }
    }
    return recurringDays;
  }

  patchTripData(selectedTrip) {

    const trip = JSON.parse(JSON.stringify(selectedTrip));

    this.newTripForm.patchValue({
      jobPassengerNumber: 1,
      // add pre time picker
      preScheduleTime: this.todayDate,
      preAppointmentTime: moment(this.todayDate).add(1, 'hours'),
      appointmentTime: moment(this.todayDate).add(1, 'hours').toISOString(),
      scheduleTime: this.todayDate.toISOString(),
    });

    delete trip.scheduleTime;
    delete trip.appointmentTime;
    this.formSelected(this.selectedFormName, trip);
  }

  setWillCallTime(evt, legForm) {
    if (evt.target.checked) {
      const time = legForm.preScheduleTime.value;
      time.set('hour', 22)
      time.set('minute', 0)
      legForm.preScheduleTime.setValue(time);
      legForm.preScheduleTime.disable();
    } else {
      legForm.preScheduleTime.enable();
    }
  }


  loadAddressPicker(title, value, address?, lat?, lng?) {
    this.tripAddressPickerModalObj = { title, value, address, lat, lng };
  }

  onAddressPicker(event) {
    this.tripAddressPickerModalObj = null;
    if (!event) return;

    // For Pickup Addresss
    if (event.reference.value === 0) {
      this.newTripForm.patchValue({
        jobOriginAddress: event.address,
        jobOriginLatitude: event.lat,
        jobOriginLongitude: event.lng,
      });
    }
    // For DropOff Addresss
    else if (event.reference.value === 1) {
      this.newTripForm.patchValue({
        jobDestinationAddress: event.address,
        jobDestinationLatitude: event.lat,
        jobDestinationLongitude: event.lng,
      });
    }
    // Leg One
    else if (event.reference.value === '01') {
      this.legsForm.at(1).patchValue({
        jobOriginAddress: event.address,
        jobOriginLatitude: event.lat,
        jobOriginLongitude: event.lng,
      });
    }
    // For DropOff Addresss
    else if (event.reference.value === '11') {
      this.legsForm.at(1).patchValue({
        jobDestinationAddress: event.address,
        jobDestinationLatitude: event.lat,
        jobDestinationLongitude: event.lng,
      });
    }

    // Leg Two
    else if (event.reference.value === '02') {
      this.legsForm.at(2).patchValue({
        jobOriginAddress: event.address,
        jobOriginLatitude: event.lat,
        jobOriginLongitude: event.lng,
      });
    }
    // For DropOff Addresss
    else if (event.reference.value === '12') {
      this.legsForm.at(2).patchValue({
        jobDestinationAddress: event.address,
        jobDestinationLatitude: event.lat,
        jobDestinationLongitude: event.lng,
      });
    }
    if (this.newTripForm.value.jobOriginAddress &&
      this.newTripForm.value.jobOriginLatitude &&
      this.newTripForm.value.jobOriginLongitude &&
      this.newTripForm.value.jobDestinationAddress &&
      this.newTripForm.value.jobDestinationLatitude &&
      this.newTripForm.value.jobDestinationLongitude) {
      this.calculateEstimatedFareOnChanges();
    }
  }

  addAddonServices() {
    this.addonServicesFormSubmitted = true;
    if (this.addonServicesForm.invalid) {
      return;
    }

    const Item = this.corporateAddonsList.find(data => data._id === this.addonServicesForm.value.addonId);

    this.addonServicesForm.patchValue({
      ...Item,
      total: Item.fare * this.addonServicesForm.value.quantity
    })

    this.newTripForm.value.addonServices.push(this.addonServicesForm.value);
    this.addonServicesForm.reset();
    this.addonServicesForm.patchValue({
      addonId: '',
      title: '',
      fare: 0,
      quantity: 1,
      total: 0,

    })
    this.addonServicesFormSubmitted = false;
    this.calculateEstimatedFareOnChanges();
  }

  removeaddonServices(index) {
    const z1 = this.newTripForm.value.addonServices;
    z1.splice(index, 1);
    this.newTripForm.patchValue({
      addonServices: z1
    });
    this.calculateEstimatedFareOnChanges();
  }



  onChange(event: Event, control: AbstractControl) {
    if (this.selectedFormName !== 'Corporate') return;
    const val = (event.target as HTMLInputElement).value;
    if (!val) {
      control.reset();
    } else {
      control.setValue(Math.abs(control.value));
    }
    this.inputSubject.next(val);
  }

  inputSubscriber() {
    this.inputSubject
      .pipe(debounceTime(1000))
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((value) => {
        this.calculateEstimatedFareOnChanges();
      });
  }




  changeStandingDriver(e, day) {
    if (e.target.value === "null") {
      day.driver = JSON.parse(e.target.value);
    } else {
      day.driver = e.target.value;
    }
  }

  onStandingOrderEndDateChange(evt) {
    if (evt) {
      this.newTripForm.patchValue({
        recurringEndDate: moment(evt).tz(this.timeZone, true).startOf('day').toISOString(),
      });
    }
  }

  processCoverImageFile(pdfInput: any) {
    imageProcessing(pdfInput)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((result) => {
        this.fileInput.nativeElement.value = '';
        if (result && result.file && result.file.type === "application/pdf") {
          this.uploadFile(result.file);
        } else {
          this.toastr.success("Invalid file uploaded", 'Alert');
        }
      });
  }

  uploadFile(selectedPDFFile) {
    this.isFileLoading = true;
    this.proTipsService
      .changeCoverImage(selectedPDFFile)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.isFileLoading = false;
          this.newTripForm.patchValue({ attachment: data.location });
        }
      });
  }
  removeAttachment() {
    this.newTripForm.patchValue({ attachment: '' });
    this.fileInput.nativeElement.value = '';
  }


  onSelectTripPurpose(v) {
  //   console.log(v, this.ngTripReason);
  }

  onUnSelectTripPurpose(v) {
  //   console.log(v, this.ngTripReason);
  }

  ngOnDestroy(): void {
  }
}
